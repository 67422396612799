import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Clue from "../../entities/clue";
import "./clue-view.scss";
import {Markdown} from "../components";

const ClueView = ({clue, className}) => {
  if (clue)
    return <div>
      <Markdown
        className={classNames([
          "clue-view",
          ...clue.contentFormatting,
          className,
        ])}
        allowDangerousHtml
      >
        {clue.content}
      </Markdown>
    </div>;
  else
    return <div />;
};

ClueView.propTypes = {
  clue: PropTypes.instanceOf(Clue),
};

export default ClueView;
