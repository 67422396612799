import React, {useContext} from "react";
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import appContent from "../../markdown/app-content";
import {Markdown} from "../components";
import GameContext from "../../context/game-context";
import CountdownButton from "../countdown-button/CountdownButton";
import {SHARE_CODE_MODAL_DISABLE_FOR_SECONDS} from "../../config/config-options";
import TeamPlayerList from "../../app/game/shared/team-player-list/TeamPlayerList";

const InviteInstructionsModal = ({inviteCode, onFinish, show, ...rest}) => {
  const {team} = useContext(GameContext);
  const countdown = show ? SHARE_CODE_MODAL_DISABLE_FOR_SECONDS : 0;

  return <Modal className="z-on-top modal--below-alerts" scrollable show={show} onHide={() => {}} {...rest}>
    <Modal.Header>
      <Modal.Title>{appContent.modals.inviteInstructions.title}</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <Markdown>
        {appContent.modals.inviteInstructions.markdown(inviteCode)}
      </Markdown>
      <TeamPlayerList
        className="pt-3"
        team={team}
        label="Teammates Joined"
      />
    </Modal.Body>

    <Modal.Footer>
      <CountdownButton countdown={countdown} onClick={onFinish} variant="info">{appContent.modals.inviteInstructions.buttonText}</CountdownButton>
    </Modal.Footer>
  </Modal>;
};

InviteInstructionsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onFinish: PropTypes.func.isRequired,
  inviteCode: PropTypes.string,
};

export default InviteInstructionsModal;
