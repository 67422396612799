import firebaseApp from "../../firebase-app";
import {getCurrentUserID} from "../../firebase";

export const applyCoinBonus = async ({team, phrase}) => {
  const {data} = await firebaseApp.functions().httpsCallable('applyCoinBonus')({
    gameId: team.gameId,
    teamId: team.id,
    phrase: phrase,
    userId: getCurrentUserID(),
  });
  return data;
}
