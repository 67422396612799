import plur from "plur";
import {prettySecondsAsMinutes, prettyTimezone} from "../utilities/dates";
import cluemasterAlertTabMarkdown from "./cluemaster-alert-tab-markdown";
import contactClueMasterMarkdown from "./contact-cluemaster";
import finishedGameMessageMarkdown from "./finished-game-message";
import homeActiveGameMarkdown from "./home-active-game";
import homeNoActiveGameMarkdown from "./home-no-active-game";
import joinGameMarkdown from "./join-game";
import loginMarkdown from "./login";
import phoneRequiredMarkdown from "./phone-required";
import SignUpMarkdown from "./sign-up";
import termsAndConditionsMarkdown from "./terms-and-conditions";
import currentLinkRequiresTeamMarkdown from "./current-link-requires-team-markdown";

const appContent = {
  alerts: {
    defaultCopySuccess: "Copied to clipboard.",
    correctAnswer: "Correct Answer",
    correctAnswerByPlayer: (playerName, answerText) => `${playerName} answered correctly with <b>${answerText}</b>.`,
    incorrectAnswer: "Answer is not correct.",
    contentRevealed: "Your answer revealed additional clues.",
    contentRevealedUsed: "This answer has already revealed additional clues.",
    contentRevealedByPlayer: (playerName) => `${playerName} has revealed additional clue details.`,
    leaveTeamSuccess: "You left the team successfully.",
    loggedOutSuccess: "You logged out successfully.",
    redeemCoinSuccess: (game) => `You've found a ${game.bonusMinutesPerCode} minute credit for your team. 🎉`,
    redeemCoinSuccessCrossTabMarkdown: "# Bonus Redeemed\nYou've found a credit for your team. 🎉",
    redeemCoinAlreadyTaken: "This bonus has already been redeemed by a teammate.",
    redeemCoinAlreadyTakenCrossTabMarkdown: "# Bonus Already Redeemed\nThis bonus has already been redeemed by a teammate.",
    redeemCoinTeamHasFinished: "# Your team has finished\nThis bonus can only be redeemed prior to finishing.",
    redeemCoinError: "Something went wrong and we could not process the bonus.",
    redeemCoinErrorCrossTabMarkdown: "# An Error Occurred\nSomething went wrong and we could not process the bonus.",
  },
  cluemaster: {
    cluePreview: {
      title: "Clue Preview",
      refreshButton: "Refresh",
      labels: {
        filterByChallenge: "Filter By Challenge",
        clue: "Show Clue",
      },
      subText: {
        filterByChallenge: "",
        clue: "",
      },
      answersSectionTitle: "Answers",
      hintsSectionTitle: "Hints",
    },
    createGame: {
      title: "Create Game",
      refreshButton: "Refresh",
      labels: {
        name: "Game Name",
        challenge: "Select Challenge",
        isHosted: "Is a Hosted Game",
        collectPhone: "Collect Phone Number",
      },
      subtext: {
        name: "YYYY.MM.DD // Client Name Challenge Name",
        challenge: (importTracks) => importTracks && `This will import track ${importTracks}.`,
        isHosted: "Hosted games requires a cluemaster to start a team",
        collectPhone: "",
      },
      buttonText: "Create Game",
    },
    game: {
      alertTab: {
        tabName: "Alert",
        markdown: cluemasterAlertTabMarkdown, // content is in an imported file
        confirmMessage: (numberOfPeople, gameName) => `Blasting ${numberOfPeople} players in ${gameName} game. Do you want to continue?`,
        titleLabel: "Alert Title",
        titleSubtext: "",
        messageLabel: "Message",
        messageSubtext: "Urls written as www.example.com or https://www.example.com will appear in app alerts",
        clueLabel: "Clue",
        sendToLabel: "Teams",
        sendAsSMSLabel: "Send SMS As Well",
        sendToOptions: {
          all: (teamSize) => `All (${teamSize})`,
          unfinished: (teamSize) => `Unfinished Teams (${teamSize})`,
          finished: (teamSize) => `Finished Teams (${teamSize})`,
          clue: (teamSize) => `On Clue (${teamSize})`,
        },
        buttons: {
          send: "Send Alert",
          previewInApp: "Preview In App Alert",
          previewSMS: "Preview SMS Alert",
        },
        smsHistoryTitle: "SMS History",
        smsHistoryMessageLabel: "Message",
        smsHistoryDeliveredLabel: "Delivered",
        smsHistorySentToLabel: "Sent To",
        smsHistorySentLabel: "Sent",
        smsLabelForInAppAlert: "In app",
      },
      clueTab: {
        tabName: "Clues",
        answerPopup: {
          addTitle: "Add Correct Answer to Clue",
          editTitle: "Edit Answer",
          label: "Answer Text",
          placeholder: "Enter Correct Answer Text",
          buttonText: "Submit",
        },
        contentRevealAnswerPopup: {
          addTitle: "Add Content Reveal Answer",
          editTitle: "Edit Content Reveal Answer",
          answerLabel: "Edit Answer Text",
          answerPlaceholder: "Enter Answer Text",
          answerSubtext: "Separate multiple answers with a comma",
          contentLabel: "Content Revealed",
          contentPlaceholder: "Enter markdown to be displayed upon content reveal",
          buttonText: "Submit",
        },
        knownWrongAnswerPopup: {
          addTitle: "Add Known Wrong Answer to Clue",
          editTitle: "Edit Known Wrong Answer",
          answerLabel: "Wrong Answer Text",
          answerPlaceholder: "Enter Wrong Answer Text",
          contentLabel: "Message",
          contentPlaceholder: "Enter Text for the Message Displayed to Users",
          buttonText: "Submit",
        },
        hintPopup: {
          addTitle: "Add Hint to Clue",
          editTitle: "Edit Hint",
          questionLabel: "Question",
          questionPlaceholder: "Enter a question for the clue",
          hintLabel: "Hint",
          hintPlaceholder: "Enter hint to be displayed when taken...",
          buttonText: "Submit",
          ignorePenaltyLabel: "Ignore Hint's Penalty",
        },
        walkthroughPopup: {
          addTitle: "Add Walkthrough to Clue",
          editTitle: "Edit Walkthrough",
          walkthroughLabel: "Walkthrough",
          walkthroughPlaceholder: "Enter walkthrough to be displayed when taken...",
          buttonText: "Submit",
        },
        contentFormatPopup: {
          addTitle: "Add A Content Format",
          editTitle: "Edit Content Format",
          inputLabel: "Content Format",
          placeholder: "ex: left-align, no-container, wide-img...",
          buttonText: "Submit",
        },
        renamePopup: {
          title: "Rename Clue",
          label: "Name",
          placeholder: "Enter clue name",
          buttonText: "Save",
        },
        editedPopup: {
          text: "You have edited this clue.",
          resetButtonText: "Reset",
          saveButtonText: "Save",
        },
        createCluePopup: {
          title: "Create Clue",
          label: "Name",
          placeholder: "",
          buttonText: "Submit",
          successMessage: (newName) => `Created blank clue named ${newName} successfully.`,
        },
        nameLabel: "Name",
        namePlaceholder: "Enter a name for the clue",
        correctAnswersLabel: "Correct Answers",
        correctAnswersAddText: "Add Answer",
        contentRevealAnswersLabel: "Content Reveal Answers",
        contentRevealAddText: "Add Answer",
        wrongAnswersLabel: "Wrong Answers",
        wrongAnswersAddText: "Add Wrong Answer",
        hintsLabel: "Hints",
        hintsAddText: "Add Hint",
        hintReorderText: "Reorder",
        walkthroughLabel: "Walkthrough",
        walkthroughAddText: "Add Walkthrough",
        walkthroughEditText: "Edit Walkthrough",
        contentFormatLabel: "Content Formatting",
        contentFormatAddText: "Add Content Format",
        contentLabel: "Content",
        contentPreviewLabel: "Content Preview",
        renameButtonText: "Rename",
        disableButtonText: "Disable",
        reEnableButtonText: "Enable",
        pushTeamsOnDisabledClueText: "Advance Teams",
        reEnableConfirmMessage: "Are you want to re-enable this clue? Players who've progressed beyond this clue will not be navigated back.",
        pushTeamsOnDisabledClueConfirmMessage: "Are you want to advanced teams on this clue?",
        copyAdvancedLink: "Copy Advance Link",
        copyAdvancedLinkSuccessMessage: (clueAnswer) => `Copied advance link with ${clueAnswer}.`,
        teamsOnClueLabel: "Teams on Clue",
        clueSavedMessage: (clue) => `Updated ${clue.name} successfully.`,
        clueMessageWhenEnabled: "Clue is Enabled",
        clueMessageWhenDisabled: "Clue is Disabled",
        timeLimitPopup: {
          label: "Time Limit",
          value: (value) => {
            if (value) {
              return `${value} ${plur("Minute", Number(value))}`;
            } else {
              return "Disabled";
            }
          },
          subText: "Leave blank or set to 0 to disable time limit.",
        },
      },
      leaderboard: {
        tabName: "Leaderboard",
        subTabs: {
          leaderboard: "Results",
          recentWrongAnswers: "Recent Answers",
        },
        columnLabels: {
          rank: "#",
          name: "Team Name",
          clue: "Clue",
          penaltyMinutes: "Adjustments",
          lastAdvancedTime: "Advanced",
          hintsTaken: "Hints Taken",
          hintsTakenTime: "Hint Adjustment",
          bonusTaken: "Bonuses Found",
          bonusTakenTime: "Bonus Adjustment",
          pauseTime: "Pause Adjustment",
          excludedClueTime: "Excluded Clues",
          totalAdjustment: "Total Adjustments",
          gameTime: "Final Time",
        },
        clueLabel: {
          noTrack: "Not Started",
          isOnInterstitial: "On Interstitial",
          finished: (time) => `Finished ${time}`,
        },
        formatPenalties: ({adjustmentTime, penaltyTime, penaltyCount, bonusCodesCount, pausedSeconds, team}) => {
          let output = "";

          if (penaltyCount !== 0) {
            output += `${penaltyCount} ${plur("hint", penaltyCount)} +${prettySecondsAsMinutes(penaltyTime)}`;
          }

          if (team.isPaused) {
            output += `${output !== "" ? "\n" : ""}paused -${prettySecondsAsMinutes(pausedSeconds)}`;
          }

          return output || "-";
        },
      },
      optionsTab: {
        tabName: "Options",
        markdown: `
# Game Options
Editing these options will affect games in realtime.
`,
        optionLabelForPrimaryGame: "Is Accepting New Teams",
        optionLabelForActiveGame: "Is Active Game",
        optionLabelForCollectEmails: "Should Collect Emails",
        optionLabelForCollectPhoneNumbers: "Should Collect Phone Numbers",
        optionLabelForRecordFinishOnLastQuestion: "Record Finish On Last Question",
        optionLabelForContentReveal: "Enable Content Reveal",
        optionLabelForAdditionalRequiredField: "Additional Required Field",
        optionLabelForCustomIntroText: "Intro Text",
        optionCustomIntroTextUnset: "Default",
        optionLabelForWrapItUpLabel: "Wrap It Up",
        optionLabelForWrapItUpEnabled: "On, players will be forwarded.",
        optionLabelForWrapItUpDisabled: "Off",
        optionCustomIntroTextSet: "Has Custom Intro Text",
        optionLabelForGameCloseTime: "Game Close Time",
        optionLabelForGameCloseTimeHintText: "It may take up to 10 additional minutes to deactivate the game.",
        confirmMessageMarkdownForPrimaryGame: "Are you sure you want to enable this? Turning this option on may deactivate accepting new team functionality for existing games.",
        confirmMessageForDeactivatingActiveGames: "Are you sure you want to deactivate this game?",
        confirmMessageForArchiving: "Deleting this game will make it unavailable from the list of closed games. If you would simply like to close the game, please deactivate it using the toggle.",
        successMessageForArchiving: "This game has been successfully deleted.",
        successMessageForUnarchiving: "This game has been unarchived successfully.",
        successForCopyPlayerDataFromGame: (playerCount) => `Successfully copied ${playerCount} ${plur("player", playerCount)} to clipboard`,
        penaltyTitle: "Penalties",
        optionGroups: {
          main: {
            title: "Main Options",
            description: "",
          },
          penalties: {
            title: "Game Time Adjustments",
            description: "",
          },
          secondaryOptions: {
            title: "Secondary Options",
            description: "These were created for Compass",
            inputs: {
              disableTeamNameChange: {
                label: "Disable Team Name Change",
              },
              disableCluemasterContact: {
                label: "Disable Cluemaster Contact",
              },
              enableTimeLimit: {
                label: "Enable Time Limit",
              },
            },
            hintLocking: {
              fields: {
                feature: {
                  label: "Hint Locking (Mins)",
                  subText: "Leave blank or set to 0 to disable feature.",
                },
                hintLockingTakenHint: {
                  label: "Lock After Taking Hint (Mins)",
                  subText: "If not set it will default to hint lock time limit.",
                },
                liveHintLock: {
                  label: "Lock From Live Hint (Mins)",
                  subText: "If not set it will default to hint lock time limit.",
                },
              },
              label: "Hint Locking",
              subText: "Will disable hint penalties when enabled.",
              value: (featureValue, lockHintValue, liveHintLock) => {
                if (featureValue && featureValue !== "0") {
                  let output = `${featureValue} ${plur("Min", Number(featureValue))}`;
                  if (lockHintValue && lockHintValue !== "0") {
                    output += `, ${lockHintValue} ${plur("Min", Number(lockHintValue))}`;
                  }
                  if (liveHintLock && liveHintLock !== "0") {
                    output += `, ${liveHintLock} ${plur("Min", Number(liveHintLock))}`;
                  }

                  return output;
                } else {
                  return "Disabled";
                }
              },
            },
            liveHints: {
              fields: {
                enabled: {
                  label: "Live Hints Enabled",
                  subText: "When enabled any team who does not have a webhook url defined for them will have a disabled live hint button.",
                },
                teamToSlackURL: {
                  label: "Teams To Slack Webhook URL",
                  subText: "",
                },
              },
              label: "Live Hints",
              subText: "",
              value: (enabled, teamToSlackUrlValue) => {
                return enabled ? "Enabled" : "Disabled";
              },
            },
          },
          closeOptions: {
            title: "Close Options",
            description: "",
          },
        },
        additionalOptions: {
          hintPenaltyBaseLabel: "Hint Penalty Base",
          hintPenaltyBaseLabelWhileEditing: "Minutes",
          hintPenaltyMinimumLabel: "Hint Penalty Minimum",
          hintPenaltyMinimumLabelWhileEditing: "Minutes",
          walkthroughPenaltyBaseLabel: "Walkthrough Penalty Base",
          walkthroughPenaltyBaseLabelWhileEditing: "Minutes",
          walkthroughPenaltyMinimumLabel: "Walkthrough Penalty Minimum",
          walkthroughPenaltyMinimumLabelWhileEditing: "Minutes",
          hintNudgeAfterMinutesLabel: "Hint Nudge After Time",
          hintNudgeAfterMinutesLabelWhileEditing: "Minutes",
          bonusTimeLabel: "Coin Bonus Time",
          bonusTimeAfterMinutesLabel: "Minutes Per Code",
        },
        actionsTitle: "Game Actions",
        analyticsTitle: "Game Analytics",
        actionsBulkAddText: "Bulk Add Teams",
        actionsCopyPlayerDataForGame: "View Player Data",
        archiveButtonText: "Delete Game",
        unarchiveButtonText: "Un-Delete Game",
        actionsLeaderboardCSVButtonText: "Copy Leaderboard Data",
        actionsLeaderboardCSVSuccessMessage: "CSV copied clipboard.",
        actionsPublicLinkButtonText: "Copy Public Link",
        actionsCopyClueTimes: "Copy Team Clue Times",
        actionsCopyHintAnalytics: "Copy Hint Analytics",
        actionsPublicLinkSuccessMessage: "URL copied to clipboard.",
        actionsEditInterstitialContent: "Edit Interstitial Content",
        actionsEditGameThemeButtonText: "Edit Game Theme",
      },
      recentWrongAnswersTab: {
        tabName: "Recent Wrong Answers",
        table: {
          filters: {
            clueLabel: "Clue",
            teamLabel: "Team",
            nonSelected: "All Shown",
          },
          columnLabels: {
            team: "Team",
            clue: "Clue",
            answer: "Attempted Answer",
            time: "Recorded",
          },
        },
        limitMessage: (limitReached, limit) => limitReached && `Showing the most recent ${limit} answers`,
      },
      startTab: {
        tabName: "Start",
        unstartedTitle: "Unstarted Teams",
        unstartedNoTeamsText: "All teams have been started",
        viewTeamsButtonText: "View Invite Codes",
        unstartedStartAllButtonText: "Start all Unstarted",
        startedTitle: "Started Teams",
        startedNoTeamsText: "No teams have started",
      },
      trackEditorTab: {
        tabName: "Track Editor",
        addPopup: {
          title: "Add Track",
          label: "Name",
          placeholder: "Enter track name",
          buttonText: "Save",
        },
        renamePopup: {
          title: "Rename Track",
          label: "Name",
          placeholder: "Enter track name",
          buttonText: "Save",
        },
        editedPopup: {
          text: "You have edited this track.",
          resetButtonText: "Reset",
          saveButtonText: "Save",
        },
        resetButtonText: "Reset",
        saveButtonText: "Save",
        renameButtonText: "Rename",
        addTrackButtonText: "Add",
        duplicateTrackButton: "Duplicate",
        deleteTrackButtonText: "Delete",
        deleteConfirmMessage: (trackName) => `Are you sure you want to delete ${trackName}?`,
      },
    },
    games: {
      gameListActiveLabel: "Active Games",
      gameListActiveSubLabel: "These are games that allow players to join",
      gameListNeverActiveLabel: "Upcoming Games",
      gameListNeverActiveSubLabel: "",
      gameListClosedLabel: "Closed Games",
      gameListClosedSubLabel: "",
      gamesListNoGamesText: "No Games",
      gamesListPrimaryGameLabel: "Accepting New Teams",
      gamesListPrimaryGamePrefix: "",
      gamesListPrimaryGameSuffix: "",
      gamesListLoadButtonText: (listName) => `Load ${listName}`,
    },
    login: {
      markdown: loginMarkdown,
      labels: {
        email: "Email address",
        password: "Password",
      },
      subText: {
        email: "Someone should have created this for you.",
        password: "",
      },
      buttonText: "Submit",
    },
    messageQueue: {
      pageTitle: `Message Queue`,
      navBarTitle: `Message Queue`,
      callerCount: (count) => `in queue: ${count}`,
      refreshButtonText: `Refresh`,
    },
  },
  inGame: {
    alerts: {
      teamAdvancedFromDisabledClue: "The Cluemaster has advanced your team to the next clue.",
      teamAdvancedFromHittingTimeLimit: "Your time allotment on this puzzle has expired and you are being moved onto the next puzzle.",
    },
    clueAdvance: {
      correctAnswerMessage: "Correct! You are on the next clue",
      correctAnswerCrossTabMarkdown: "# Correct!\nYour team has progressed forward. You can find your team's current clue in another tab.",
      contentRevealed: "Your answer revealed additional clues",
      contentRevealedUsed: "Your answer has already revealed additional clues",
      wrongAnswerMessage: "Incorrect. Your team has not progressed forward.",
      wrongAnswerCrossTabMarkdown: "# Incorrect Link.\nYour team has not progressed forward. You can find your team's current clue in another tab.",
    },
    contactClueMaster: {
      markdown: contactClueMasterMarkdown, // content is in an imported file
      successfullySentMessage: `Message sent successfully. This tab will close shortly.`,
      phoneNumberLabel: `Your Phone Number`,
      messageLabel: `Message`,
      messagePlaceholder: `Enter your message`,
      submitText: `Send Message to Cluemasters`,
      textToCluemasterTwilio: ({
        name,
        teamName,
        clueName,
        hints,
        message,
        phone,
      }) => `${name} on Team "${teamName}" on Clue "${clueName}" with ${plur("hint", hints)} Says "${message}". They can be contacted on +${phone}.`,
      textToCluemasterZapier: ({
        name,
        teamName,
        clueName,
        hints,
        message,
        phone,
      }) => `${name} on Team "${teamName}", who are currently on ${clueName} and have taken ${hints} ${plur("hint", hints)} are having trouble. They say: "${message}"\n\nPlease wait and a cluemaster will be with you shortly. If you have anything else to add you may add it now!`,
    },
    currentLinkRequiresTeam: {
      markdown: currentLinkRequiresTeamMarkdown,
      goHomeButtonText: "I Need to Create or Join a Team",
      copyLink: "Copy Current Link",
      goToLearnMore: "Learn More About Joining or Hosting a Game.",
      goToLearnMoreLink: "https://bit.ggc.nyc/ggc",
    },
    finishedGame: {
      unhostedMessage: finishedGameMessageMarkdown,
      hostedMessage: finishedGameMessageMarkdown,
    },
    footer: {
      team: {
        title: "Team",
        teamName: {
          prefixBeforeName: "You're on team ",
          middleBetweenNameAndCode: " with secret access phrase ",
          suffixAfterCode: ". ",
          suffixWhenEditable: " Click your team name to change it. ",
        },
        contactCM: {
          prefix: "To contact the cluemasters to report a problem, ",
          linkText: "click here",
          suffix: ".",
        },
        teammatesLabel: "Teammates",
      },
      leaveGame: {
        title: "Leave Team",
        leave: {
          prefix: "To leave team, ",
          linkText: "click here",
          suffix: ".",
        },
      },
    },
    hints: {
      hintsSectionTitle: "Hints Taken",
      hintsListItemLabel: (playerNameWhoTookHint) => `Taken by ${playerNameWhoTookHint}`,
      hintButtonText: ({penaltyTime, isLiveHintButton, liveHintsEnabled}) => {
        let output = "";
        if (isLiveHintButton) {
          output = "Get Live Hint";
        } else if (liveHintsEnabled) {
          output = "Get Preset Hint";
        } else {
          output = "Get Hint";
        }

        return `${output} - Currently Costs ${penaltyTime?.minutes}:${penaltyTime?.formattedSeconds}`;
      },
      hintButtonTextWithHintLocking: ({isLiveHintButton, liveHintsEnabled}) => {
        let output = "";
        if (isLiveHintButton) {
          output = "Get Live Hint";
        } else if (liveHintsEnabled) {
          output = "Get Preset Hint";
        } else {
          output = "Get Hint";
        }

        return output;
      },
      hintButtonTextWhileDisabledWhilePlayerInHintFlow: (playerNameTakingHint) => `${playerNameTakingHint} is taking a hint`,
      hintButtonTextAfterTakingAllHints: ({liveHintsEnabled}) => `All ${liveHintsEnabled ? "Preset Hints" : "Hints"} Taken`,
      hintButtonTextWhenNotStarted: ({liveHintsEnabled}) => `${liveHintsEnabled ? "Preset hints" : "Hints"} are available after your team is started.`,
      hintButtonTextDisabledFromStartingHintLock: ({lockTime, isLiveHint, liveHintsEnabled}) => `${isLiveHint ? "Live hints" : liveHintsEnabled ? "Preset Hints" : "Hints"} are available after ${lockTime?.minutes}:${lockTime?.formattedSeconds}`,
      liveHintsEnabledButNotForTeam: `Live hints have not been configured for your team yet.`,
      hintHelpText: (walkthroughAvailable) => `Select what is currently stumping you. You can step backwards if you go too far, but only one time.${walkthroughAvailable ? ` In addition, there is a walkthrough available at the end of the questionnaire for a higher time penalty.`: ``}`,
      startHintFlowConfirmation: ({hintPenalty, walkthroughPenalty, walkthroughAvailable, isLiveHint}) => `Are you sure you want to take a hint? ${hintPenalty?.minutes} minutes ${hintPenalty?.seconds} seconds will be added to your final time.${walkthroughAvailable ? ` Walkthroughs are also available for ${walkthroughPenalty.minutes} minutes.` : ""}\n\nIf you confirm, you will have the chance to select what is currently stumping you and receive a hint for that specific place.`,
      startHintFlowConfirmationWithOnlyWalkthrough: (hintPenalty, walkthroughPenalty, isLiveHint) => `The only hint available is the walkthrough, which will result in a penalty of ${walkthroughPenalty.minutes} minutes and ${walkthroughPenalty.seconds} seconds. Do you want to take it?`,
      startHintFlowConfirmationHintLock: ({lockTimeMin, walkthroughAvailable, isLiveHint}) => `Are you sure you want to take a ${isLiveHint ? "live " : ""}hint? After you take this hint, you will be prevented from taking future hints for another ${lockTimeMin} minutes.`,
      startHintFlowConfirmationWithOnlyWalkthroughHintLock: ({lockTimeMin, walkthroughAvailable, isLiveHint}) => `Are you sure you want to take a ${isLiveHint ? "live " : ""}hint? After you take this hint, you will be prevented from taking future hints for another ${lockTimeMin} minutes.`,
      startHintFlowConfirmationCompass: ({lockTimeMin}) => `Are you sure you want to take a preset hint? After you take this hint, you will be prevented from taking future hints for another ${lockTimeMin} minutes. Preset hints will help you in the right direction, but rarely will confirm intermediary answers. If you need someone to check you work please take a ‘live hint’.`,
      hintQuestionsModalTitle: "Where are you having trouble?",
      hintQuestionsModalButtons: {
        yes: "Take\nHint",
        yesForWalkthrough: "Take\nWalkthrough",
        no: "Next\nHint",
        noBeforeWalkthrough: "See Walkthrough",
        back: "Previous\nHint",
      },
      walkthroughPopup: {
        title: "Would You Like a Walkthrough?",
        content: ({
          walkthroughPenalty,
          hintPenalty,
        }) => `This is the end of the hint flow, if you proceed you will get the walkthrough of the entire clue and be charged the walkthrough penalty of ${walkthroughPenalty?.minutes} minutes.  You can still go back and take a hint for ${hintPenalty?.minutes} minutes ${hintPenalty?.seconds} seconds.`,
      },
      hintDisplayTitle: (playerName) => `${playerName} Took a Hint.`,
      walkthroughDisplayTitle: (playerName) => `${playerName} Took a Walkthrough.`,
      liveHintDisplayTitle: (playerName) => `${playerName} Took a Live Hint.`,
      hintNudgeTitle: "Would You Like a Hint?",
      hintNudgeContentWithPenalty: (penaltyMinutes) => `It seems you’ve been here for a while. Would you like to take a hint?  It will only cost you ${penaltyMinutes?.minutes} minutes ${penaltyMinutes?.seconds} seconds.`,
      hintNudgeContentWithoutPenalty: `It seems you’ve been here for a while. Would you like to take a hint? No penalty will be added to your time.`,
      noMoreHintsError: "Can not enter hint flow since there are no available hints.",
      failedToSendLiveHintError: "Failed to send a live hint message.",
    },
    inGame: {
      inputs: {
        answer: {
          label: "Answer",
          placeholder: "",
          subText: "",
        },
      },
      answerSubmitText: "Submit",
    },
    interstitial: {
      buttonText: "Begin",
    },
    joinGame: {
      markdown: joinGameMarkdown,
      createTeamButtonText: "Create a Team",
      joinTeamButtonText: "Join a Team",
      loginMessage: (playerName) => {
        return {
          prefix: `You're currently logged in as ${playerName}. If you'd like to log out, `,
          linkText: `click here`,
          suffix: `.`,
        };
      },
    },
    phoneRequired: {
      markdown: phoneRequiredMarkdown,
      emailLabel: `Email`,
      emailPlaceholder: `email@example.com`,
      emailHelpText: ``,
      phoneLabel: `Phone Number`,
      phoneHelperText: `This is used in the event the cluemaster needs to contact you.`,
      submitText: `Submit`,
    },
    signUp: {
      markdown: SignUpMarkdown,
      nameLabel: `Enter Your First and Last Name`,
      namePlaceholder: `Jane Doe`,
      termsAndConditions: {
        prefix: `By checking here, you are agreeing to our complete `,
        linkText: `terms and conditions`,
        suffix: ". These include (but are not limited to) being safe, respecting yourselves, other teams and the great city of Gotham, and not sharing the contents of the \"clues\" in the challenge with anyone else. Don't spoil the fun for anyone.\n",
      },
      submitText: `Submit`,
    },
    termsAndConditions: {
      title: "Terms and Conditions",
      markdown: termsAndConditionsMarkdown,
    },
  },
  home: {
    startButton: "Begin",
    activeGameMarkdown: homeActiveGameMarkdown,
    noActiveGameMarkdown: homeNoActiveGameMarkdown,
  },
  modals: {
    allPlayerData: {
      title: "All Player Data",
      closeButton: "Close",
      buttonText: "Copy to Clipboard",
    },
    bulkAddSummary: {
      afterCreateTitle: (numberOfTeam) => `Created ${numberOfTeam} ${plur("Team", numberOfTeam)}`,
      defaultTitle: (numberOfTeam) => `${numberOfTeam} ${plur("Team", numberOfTeam)}`,
      tableTeamNameLabel: "Team Name",
      tableInviteCodeLabel: "Invite Code",
      copyCSVText: "Copy Teams",
      doneButtonText: "Done",
    },
    bulkAddTeams: {
      title: "Bulk Create Teams",
      label: "Number of Teams",
      placeholder: "Enter a number ex: 2",
      buttonText: "Create Teams",
      triggerPopupButtonText: "Bulk Add Teams",
    },
    createGameModal: {
      title: "Created Successfully",
      labels: {
        name: "Name",
        options: "Options",
        tracks: "Tracks",
        clues: "Clues",
      },
    },
    disabledClueModal: {
      title: (clueName) => `Disable ${clueName}`,
      markdown: `
There are two options for disabling a clue.
- __Advancing all teams__ will force all teams, even those on the clue your disabling to move to the next clue on their track. The players will be notified about the change via an in app alert.
- __Skip for those not on clue__ will allow teams on the clue you're disabling to remain on their current clue.
`,
      advanceAllButtonText: "Advance All Teams",
      advanceAllConfirmMessage: "Are you sure you want to disable this clue and advance all teams?",
      disabledForTeamsNotOnClueButtonText: "Skip For Those Not On Clue",
      disabledForTeamsNotOnClueConfirmMessage: "Are you sure you want to disable this clue and leave teams on this clue where they are?",
    },
    editAdditionalRequiredField: {
      title: "Edit Additional Required Field",
      label: "Required Field Label",
      placeholder: "ex: Wallet Address",
      subText: "Leave blank to remove field",
      buttonText: "Submit",
    },
    editGameThemeModal: {
      title: "Edit Game Theme",
      inputLabel: "HTML For Document Head",
      inputs: {
        bigLogoSrc: {
          label: "Big Logo Src",
          placeholder: "",
          subText: "",
        },
        smLogoScr: {
          label: "Small Logo Src",
          placeholder: "",
          subText: "",
        },
      },
      buttonText: "Save",
      successMessage: "Update Game Theme Successfully",
    },
    editInterstitialContent: {
      title: "Edit Interstitial Content",
      label: "Content",
      placeholder: "",
      buttonText: "Submit",
      successMessage: "Update Interstitial Content Successfully",
    },
    editIntroText: {
      title: "Set Intro Text",
      label: "Intro Text",
      placeholder: "",
      subText: "Markdown is enabled. To use default intro text, leave blank.",
      buttonText: "Submit",
    },
    editStartTime: {
      title: `Set Team Start Time`,
      startDateLabel: `Start Date`,
      startTimeLabel: `Start Time`,
      timeHint: `Enter date and time in your local timezone which is ${prettyTimezone()}.`,
      submitText: `Submit`,
    },
    editWrapItUp: {
      title: "Wrap it Up",
      labelMarkdown: "Page Content",
      placeholderMarkdown: "",
      subTextMarkdown: "The content that players will see after they finish the clue they are on. Markdown is enabled.",
      toggleLabel: "Wrap Up",
      toggleText: "Enabled",
      toggleSubtext: "Enabling this feature will allow players to finish complementing their last clue followed by forwarding them to the content provided.",
      confirmEnablingText: "Are you sure you want to enable this functionality?",
      confirmDisableText: "Are you sure you want to disable this functionality? Teams who have arrived at the wrap it up content will be sent to their next clue.",
      buttonText: "Save",
    },
    gameClose: {
      title: `Set Game Close Time`,
      startDateLabel: `Close Date`,
      startTimeLabel: `Close Time`,
      timeHint: `Enter date and time in your local timezone which is ${prettyTimezone()}.`,
      submitText: `Submit`,
    },
    inviteInstructions: {
      title: `Now share your code with your team`,
      markdown: (inviteCode) => `You should now have your team members join your team by sharing your secret phrase __${inviteCode}__.`,
      buttonText: `Continue`,
    },
    leaderboardTeam: {
      inviteCodeLabel: "Invite Code",
      trackLabel: "Track",
      clueslabel: "Clues",
      teamMembersLabel: "Team Members",
      finishTeamButton: "Finish Team",
      undoFinishTeamButton: "Undo Finish Team",
      pauseTeamButton: "Pause Team",
      unpauseTeamButton: "Unpause Team",
      displayPhoneNumbersButton: "Copy Phone Numbers",
      adjustments: {
        label: "Adjustments",
        noAdjustments: "None",
        hints: (time, count) => `${time} from ${count} ${plur("hint", count)}`,
        bonus: (time, count) => `${time} from ${count} ${plur("bonus", count)}`,
        pause: (time) => `${time} paused`,
        excluded: (time) => `${time} excluded clue time`,
        total: (time) => `Total: ${time}`,
      },
      teamID: "Team ID",
    },
    teamIdling: {
      title: `Are you still playing?`,
      markdown: `We haven't seen anyone on your team in a while. Could you select one of the options below?`,
      actionButtons: {
        stillPlayingText: `Still Playing`,
        exitGameText: `Exiting the Game`,
        contactClueMaster: `Need to Contact the Cluemasters`,
      },
      messageOnStillPlaying: `If your team is getting stuck consider contacting the cluemaster`,
    },
    teamQuit: {
      title: `Your team has quit.`,
      markdown: `If you would still like to play you can reactivate your team. Otherwise, you may now close this window.`,
      buttonText: `Rejoin the Game`,
      messageWhenCancelingQuitting: `You're team has rejoined the game.`,
    },
    teamPhoneNumbers: {
      title: "Player Phone Numbers",
      closeButton: "Close",
      buttonText: "Copy All Phone Numbers",
      noPhoneNumbersMessage: "No phone numbers copied. Players have not entered phone numbers yet or all players with phone numbers have left the team.",
    },
    unhostedBeginGame: "Beginning this game starts the game for all players. Are you sure you wish to continue?",
  },
};

export default appContent;
