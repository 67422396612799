import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Game from "../../../entities/game";
import {LeaderboardTeamModal} from "../../../components/components";
import {idleTeams} from "../../../services/firestore/presence";
import LeaderboardComponent from "../../../app/clue-master/leaderboard/Leaderboard";
import useLeaderboardTableData from "../../../hooks/clue-master/use-leaderboard-table-data";
import {Tab, Tabs} from "react-bootstrap";
import RecentAnswersTab from "./RecentAnswersTab";
import {ENABLE_RECENT_WRONG_ANSWERS_LEADERBOARD_TAB} from "../../../config/config-options";
import appContent from "../../../markdown/app-content";
import Team from "../../../entities/team";

export const Leaderboard = ({game, readonly, teams = []}) => {
  const leaderboardDataForTeams = useLeaderboardTableData(game, teams);
  const unfinishedTeams = leaderboardDataForTeams.filter(leaderboardData => !leaderboardData.team.isFinished);
  const finishedTeams = leaderboardDataForTeams.filter(leaderboardData => leaderboardData.team.isFinished);
  const [shownTeamId, setShownTeamId] = useState(null);
  const [shownTeamRank, setShownTeamRank] = useState(null);
  const [idlingTeams, setIdlingTeams] = useState([]);
  const shownTeamInModal = teams.find(team => team.id === shownTeamId);

  useEffect(() => {
    const subscription = idleTeams(game).subscribe(idleTeamIds => {
      setIdlingTeams(idleTeamIds);
    });

    return () => subscription.unsubscribe();
  }, [game]);

  const getTeamRank = (team) => {
    return leaderboardDataForTeams.findIndex(t => t.team.id === team.id);
  };

  const handleSwitchShowingTeam = (team) => {
    if (team) {
      setShownTeamId(team.id);
      setShownTeamRank(getTeamRank(team));
    } else {
      setShownTeamId(null);
      setShownTeamRank(null);
    }
  };

  const handleShowNextTeam = () => {
    const nextTeamIndex = shownTeamRank + 1;
    if (nextTeamIndex < leaderboardDataForTeams.length) {
      handleSwitchShowingTeam(leaderboardDataForTeams[nextTeamIndex].team);
    }
  };

  const handleShowPreviousTeam = () => {
    const previousIndex = shownTeamRank - 1;
    if (previousIndex >= 0) {
      handleSwitchShowingTeam(leaderboardDataForTeams[previousIndex].team);
    }
  };

  const LeaderboardSection = () => {
    return <>
      <LeaderboardComponent
        game={game}
        leaderboardDataForTeams={unfinishedTeams}
        idlingTeams={idlingTeams}
        onNameClicked={(team) => handleSwitchShowingTeam(team)}
        rankStartAt={finishedTeams.length + 1}
      />
      <LeaderboardComponent
        game={game}
        leaderboardDataForTeams={finishedTeams}
        onNameClicked={(team) => handleSwitchShowingTeam(team)}
        rankStartAt={1}
      />

      <LeaderboardTeamModal
        game={game}
        team={shownTeamInModal}
        teamRank={shownTeamRank + 1}
        show={!!shownTeamInModal}
        onHide={() => handleSwitchShowingTeam(null)}
        readonly={readonly}
        onNext={handleShowNextTeam}
        onPrevious={handleShowPreviousTeam}
      />
    </>;
  };

  return ENABLE_RECENT_WRONG_ANSWERS_LEADERBOARD_TAB ?
    <>
      <Tabs
        defaultActiveKey="results"
        className="nav-tabs--dark mb-3"
        fill
      >
        <Tab eventKey="results" title={appContent.cluemaster.game.leaderboard.subTabs.leaderboard}>
          <LeaderboardSection/>
        </Tab>
        {!readonly && <Tab eventKey="wrong-answers" title={appContent.cluemaster.game.leaderboard.subTabs.recentWrongAnswers}>
          <RecentAnswersTab game={game} teams={teams} />
        </Tab>}
      </Tabs>
    </>
    : <LeaderboardSection/>;
};

const LeaderboardTab = ({game, teams}) => {
  return <Leaderboard game={game} teams={teams}/>;
};

LeaderboardTab.propTypes = {
  game: PropTypes.instanceOf(Game),
  teams: PropTypes.arrayOf(PropTypes.instanceOf(Team)),
  readonly: PropTypes.bool,
};


export default LeaderboardTab;
