import React from "react";
import propTypes from "prop-types";
import { Button, Table } from "react-bootstrap";
import { ChevronDown, ChevronUp, Lock, XCircle } from "react-bootstrap-icons";
import { ClueName } from "../../../components/components";
import Clue from "../../../entities/clue";
import DuplicateClueButton from "./DuplicateClueButton";
import PropTypes from "prop-types";
import Game from "../../../entities/game";

const ClueListReorder = ({ game, clues, editableAfterIndex, onChange }) => {
  const clueIds = clues.map(c => c.id);

  const handleReorder = (clueId, direction) => {
    const clueIndex = clueIds.indexOf(clueId);

    if (direction === "up" && clueIndex > 0) {
      const clueIdsCopy = [...clueIds];
      clueIdsCopy.splice(clueIndex, 1);
      clueIdsCopy.splice(clueIndex - 1, 0, clueId);
      onChange(clueIdsCopy);
    }
    else if (direction === "down" && clueIndex < clueIds.length -1) {
      const clueIdsCopy = [...clueIds];
      clueIdsCopy.splice(clueIndex, 1);
      clueIdsCopy.splice(clueIndex + 1, 0, clueId);
      onChange(clueIdsCopy);
    }
  }

  const handleRemove = (clueId) => {
    const clueIndex = clueIds.indexOf(clueId);
    const clueIdsCopy = [...clueIds];
    clueIdsCopy.splice(clueIndex, 1);
    onChange(clueIdsCopy);
  }

  return <Table bordered variant="dark">
    <tbody>
      {clues.map((clue, index) => {
        const disableAllControls = index <= editableAfterIndex;
        const disableUpArrow = disableAllControls || index <= editableAfterIndex + 1;
        return <tr key={clue.id}>
          <td>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                {`${index}. `}
                <ClueName clue={clue} />
                {disableAllControls && <Lock className="ml-1" /> }
              </div>
              <div>
                <Button variant="dark" onClick={() => handleReorder(clue.id, "up")} disabled={disableUpArrow}>
                  <ChevronUp/>
                </Button>
                <Button variant="dark" onClick={() => handleReorder(clue.id, "down")} disabled={disableAllControls}>
                  <ChevronDown/>
                </Button>
                <DuplicateClueButton game={game} clue={clue} />
                <Button variant="dark" onClick={() => handleRemove(clue.id)} disabled={disableAllControls}>
                  <XCircle/>
                </Button>
              </div>
            </div>
          </td>
        </tr>
      })}
    </tbody>
  </Table>
}

ClueListReorder.defaultProps = {
  editableAfterIndex: 0,
}

ClueListReorder.prototype = {
  game: PropTypes.instanceOf(Game),
  clues: propTypes.arrayOf(propTypes.arrayOf(propTypes.instanceOf(Clue))),
  editableAfterIndex: propTypes.number,
  onChange: propTypes.func.isRequired,
}

export default ClueListReorder;
