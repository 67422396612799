import React from "react";
import PropTypes from "prop-types"
import { Button, Modal, Table } from "react-bootstrap";
import { convertToCSV } from "../../utilities/helper-functions";
import appContent from "../../markdown/app-content";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";

const BulkAddSummaryModal = ({teamNamesAndCodes = [], shownAfterCreation, onHide, ...rest}) => {
  const {copyText} = useCopyToClipboard();

  const modalTitle = shownAfterCreation ?
    appContent.modals.bulkAddSummary.afterCreateTitle(teamNamesAndCodes.length) :
    appContent.modals.bulkAddSummary.defaultTitle(teamNamesAndCodes.length);

  const handleCopyTeams = () => {
    copyText(
      convertToCSV(teamNamesAndCodes.map(d => [d.name, d.phrase])),
    );
  }

  return <Modal scrollable onHide={onHide} {...rest}>
    <Modal.Header closeButton>
      <Modal.Title>
        {modalTitle}
      </Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <Table borderless size="sm">
        <thead>
        <tr>
          <th>{appContent.modals.bulkAddSummary.tableTeamNameLabel}</th>
          <th>{appContent.modals.bulkAddSummary.tableInviteCodeLabel}</th>
        </tr>
        </thead>
        <tbody>
        {teamNamesAndCodes.map(({id, name, phrase}) => {
          return <tr key={id}>
            <td>{name}</td>
            <td>{phrase}</td>
          </tr>
        })}
        </tbody>
      </Table>
    </Modal.Body>

    <Modal.Footer>
      <Button variant="light" onClick={handleCopyTeams}>{appContent.modals.bulkAddSummary.copyCSVText}</Button>
      <Button variant="info" onClick={onHide}>{appContent.modals.bulkAddSummary.doneButtonText}</Button>
    </Modal.Footer>
  </Modal>
}

BulkAddSummaryModal.propTypes = {
  shownAfterCreation: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  teamNamesAndCodes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    phrase: PropTypes.string.isRequired,
  })).isRequired,
}

export default BulkAddSummaryModal;
