import React from "react";
import PropTypes from "prop-types";
import Team from "../../../entities/team";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import {convertToCSV} from "../../../utilities/helper-functions";
import {Button} from "react-bootstrap";

const LiveHintsTemplateButton = ({teams, ...rest}) => {
  const {copyText} = useCopyToClipboard();

  const liveHintsTemplate = async (e) => {
    e.preventDefault();
    if (teams && teams.length > 0) {
      await copyText(convertToCSV(teams.map(t => [
        t.name.replaceAll(",",""),
        t.id,
        "slack_webhook_url",
      ])));
    } else {
      await copyText(convertToCSV([[
        "t.teamName",
        "t.teamId",
        "slack_webhook_url",
      ]]));
    }

    e.target.blur();
  }

  return <Button
    type="button"
    variant="outline-info"
    size="sm"
    onClick={liveHintsTemplate}
    {...rest}
  />
}

LiveHintsTemplateButton.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.instanceOf(Team)),
  children: PropTypes.node,
}

export default LiveHintsTemplateButton;
