import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import appContent from "../../../markdown/app-content";
import Game, {GameOptions} from "../../../entities/game";
import {InputModal, Link} from "../../../components/components";
import {setGameOption, updateGameData} from "../../../services/firebase";
import firebase from "firebase";
import {AppAlertsContext} from "../../../context/app-alerts-context";
import {Form} from "react-bootstrap";
import {newFirestoreTimeStamp} from "../../../utilities/dates";

const WrapItUpOption = ({game}) => {
  const {popConfirm, popError} = useContext(AppAlertsContext);
  const [isEditing, setIsEditing] = useState(false);
  const [wrapItUpToggleValue, setWrapItUpToggleValue] = useState(game?.wrapItUpEnabled);

  const handleFinished = (inputValues) => {
    const save = async () => {
      try {
        const value = inputValues[appContent.modals.editWrapItUp.labelMarkdown] || firebase.firestore.FieldValue.delete();
        const enableFieldChanged = wrapItUpToggleValue !== game.wrapItUpEnabled;

        await Promise.all([
          setGameOption(game, GameOptions.WRAP_IT_UP_CONTENT, value),
          enableFieldChanged ?
            updateGameData(game, {
              wrapItUpEnabledAt: wrapItUpToggleValue ? newFirestoreTimeStamp() : firebase.firestore.FieldValue.delete(),
            }) :
            Promise.resolve(),
        ]);
        console.warn("TODO: ENABLE FEATURE", wrapItUpToggleValue);
        setIsEditing(false);
      } catch (e) {
        popError(e.message);
      }
    };

    if (!game.wrapItUpEnabled && wrapItUpToggleValue) {
      popConfirm(appContent.modals.editWrapItUp.confirmEnablingText, save);
    } else if (game.wrapItUpEnabled && !wrapItUpToggleValue) {
      popConfirm(appContent.modals.editWrapItUp.confirmDisableText, save);
    } else {
      save();
    }
  };

  return <>
    <li>
      <span className="mr-2">{appContent.cluemaster.game.optionsTab.optionLabelForWrapItUpLabel}:</span>
      <Link onClick={() => setIsEditing(true)}>
        {game?.wrapItUpEnabled ?
          appContent.cluemaster.game.optionsTab.optionLabelForWrapItUpEnabled :
          appContent.cluemaster.game.optionsTab.optionLabelForWrapItUpDisabled
        }
      </Link>
    </li>
    <InputModal
      show={isEditing}
      onHide={() => setIsEditing(false)}
      onFinish={handleFinished}
      title={appContent.modals.editWrapItUp.title}
      inputs={[
        {
          label: appContent.modals.editWrapItUp.labelMarkdown,
          defaultValue: game?.getOption(GameOptions.WRAP_IT_UP_CONTENT) || "",
          formControlProps: {
            as: "textarea",
            rows: 8,
            required: true,
            placeholder: appContent.modals.editWrapItUp.placeholderMarkdown,
          },
          subText: appContent.modals.editWrapItUp.subTextMarkdown,
        },
        {
          label: appContent.modals.editWrapItUp.toggleLabel,
          defaultValue: game?.getOption(GameOptions.WRAP_IT_UP_CONTENT) || "",
          custom: <Form.Check
            type="switch"
            id="wrap-it-up-switch"
            label={appContent.modals.editWrapItUp.toggleText}
            checked={wrapItUpToggleValue}
            onChange={(event) => {
              setWrapItUpToggleValue(event.target.checked);
            }}
          />,
          subText: appContent.modals.editWrapItUp.toggleSubtext,
        }]}
      buttonText={appContent.modals.editWrapItUp.buttonText}
    />
  </>;
};

WrapItUpOption.propTypes = {
  game: PropTypes.instanceOf(Game),
};

export default WrapItUpOption;
