import {gameCollection} from "../firebase";
import {collection} from "rxfire/firestore";
import {map} from "rxjs/operators";
import AnswerLog from "../../entities/answerLog";
import {RECENT_WRONG_ANSWER_LIMIT} from "../../config/config-options";

export const allAnswersCollection = (gameId) => gameCollection().doc(gameId).collection("allAnswers");

export const logAnswer = (data) => {
  const {
    gameId,
    clueId,
    teamId,
    hasRevealedContent,
    isCorrect,
    /* The fields below should match answer data stored in teams */
    answer,
    answeredBy,
    addedAt,
    usedLink,
  } = data;

  return allAnswersCollection(gameId).add({
    clueId,
    teamId,
    hasRevealedContent,
    isCorrect,
    answer,
    answeredBy,
    addedAt,
    usedLink,
  });
};

export const getRecentAnswers = (gameId, filters = {}) => {
  let query = allAnswersCollection(gameId).orderBy("addedAt", "desc")
    .limit(RECENT_WRONG_ANSWER_LIMIT);

  if (filters.isCorrect !== undefined) {
    query = query.where("isCorrect", "==", filters.isCorrect)
  }

  if (filters.clueId) {
    query = query.where("clueId", "==", filters.clueId);
  }

  if (filters.teamId) {
    query = query.where("teamId", "==", filters.teamId);
  }

  return collection(query).pipe(
    map(docs => docs.map(d => {
      return new AnswerLog(d.id, d.data());
    })),
  );
};
