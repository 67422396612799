import React, { useEffect, useState } from "react";
import PropTypes from "prop-types"
import { Button, Form, Modal } from "react-bootstrap";
import {FormGroup} from "../components";

const InputModal = (props) => {
  const {onFinish, inputs, title, buttonText, show, secondaryFooterSlot, secondaryFooterAsNodeSlot, children, ...rest} = props;
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    if (show) {
      const values = {};
      inputs.forEach(input => {
        values[input.label] = input.defaultValue || '';
      });
      setInputValues(values)
    }
  }, [show])

  const handleSubmit = event => {
    event.preventDefault();

    if (onFinish) {
      onFinish(inputValues);
    }
  }

  return <Modal className="input-modal" show={show} {...rest}>
    <Form onSubmit={handleSubmit}>
      <Modal.Header closeButton>
        <Modal.Title className="input-modal__title">{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {inputs.map(input => {
          return (
            <FormGroup
              key={input.label}
              label={input.label}
              subText={input.subText}
            >
              {input.custom || (<Form.Control
                value={inputValues[input.label] || ''}
                checked={input.formControlProps?.type === "checkbox" ? inputValues[input.label] : false}
                onChange={(event) => setInputValues({
                  ...inputValues,
                  [input.label]: input.formControlProps?.type === "checkbox" ? event.target.checked : event.target.value,
                })}
                {...input.formControlProps}
              />)}
            </FormGroup>
          )
        })}
      </Modal.Body>

      <Modal.Footer>
        {secondaryFooterSlot && secondaryFooterSlot(inputValues)}
        {secondaryFooterAsNodeSlot}
        <Button className="input-modal__submit" type="submit" variant="info">{buttonText}</Button>
      </Modal.Footer>
    </Form>
  </Modal>
}

InputModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  inputs: PropTypes.arrayOf(
    PropTypes.oneOfType([
      // Input
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        formControlProps: PropTypes.object.isRequired,
        defaultValue: PropTypes.string,
        subText: PropTypes.string,
      }),
      // Custom Node.
      // Note: Value will not be provided in onFinish()
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        custom: PropTypes.node,
        defaultValue: PropTypes.string,
        subText: PropTypes.string,
      }),
    ]),
  ).isRequired,
  buttonText: PropTypes.string.isRequired,
  secondaryFooterAsNodeSlot: PropTypes.node,
  secondaryFooterSlot: PropTypes.func, // passes inputValues prop
}

export default InputModal;
