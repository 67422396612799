import dayjs from "dayjs";
import {compareValues} from "./helper-functions";
import firebase from "firebase/app";
import "firebase/firestore";

export const parseFirstoreTimestamp = (date) => {
  if (date) {
    return newFirestoreTimeStamp(date);
  }
}

export const newFirestoreTimeStamp = (date) => {
  // Firebase isn't loaded on serverside due to the 3rd party library relying on window.
  // Existing here to prevent build errors on server side rendering.
  if (!firebase || !firebase.firestore) {
    return;
  }

  if (!date) {
    return new firebase.firestore.Timestamp.now();
  }
  else if (date.hasOwnProperty('seconds') && date.hasOwnProperty('nanoseconds')) {
    return new firebase.firestore.Timestamp(date.seconds, date.nanoseconds);
  }
  else {
    return new firebase.firestore.Timestamp.fromDate(date);
  }
}

export const firestoreTimestampToJSON = (timestamp) => {
  return {
    seconds: timestamp.seconds,
    nanoseconds: timestamp.nanoseconds,
  }
}

export const getLocalTimeFromTimestamp = (timestamp) => {
  if (timestamp && timestamp.toDate) {
    return timestamp.toDate().toLocaleString();
  }
}

export const convertToDayJs = (timestamp) => {
  if (timestamp && timestamp.toDate) {
    return dayjs(timestamp.toDate());
  }
}

export const secondsSinceTimestamp = (timestamp) => {
  return dayjs().diff(dayjs(timestamp.toDate()), 'seconds');
}

export const subtractSecondsFromTimestamp = (timestamp, seconds) => {
  return new firebase.firestore.Timestamp(timestamp.seconds - seconds, timestamp.nanoseconds);
}

export const minutesSinceTimestamp = (timestamp, precision = 2) => {
  return Number(secondsSinceTimestamp(timestamp) / 60).toFixed(precision);
}

export const prettySecondsAsMinutes = (seconds) => {
  let output = "";
  const isNegative = seconds < 0;
  const secondsAbs = Math.abs(Math.floor(seconds));


  const modSeconds = secondsAbs % 60;

  // Handle Minutes
  output += `${(secondsAbs - modSeconds) / 60}:`;

  // Handle Seconds
  if (modSeconds === 0) {
    output += "00";
  } else if (modSeconds < 10) {
    output += `0${modSeconds}`;
  } else {
    output += modSeconds;
  }

  output += "s";

  if (isNegative) {
    output = "-" + output;
  }

  return output
}

export const prettyTimeSinceTimestamp = (timestamp) => {
  if (timestamp) {
    let differenceInSeconds = dayjs().diff(dayjs(timestamp.toDate()), 'seconds');
    const percision = 2;
    const timeAmountsAndSymbols = [
      {symbol: 'd', seconds: 86400}, // days
      {symbol: 'h', seconds: 3600}, // hours
      {symbol: 'm', seconds: 60},   // minutes
      {symbol: 's', seconds: 1},    // seconds
    ];

    let prettyTime = '';
    let loopCount = 0;
    timeAmountsAndSymbols.forEach(({ symbol, seconds }) => {
      if (
        loopCount < percision &&
        differenceInSeconds >= seconds
      ) {
        const value = Math.floor(differenceInSeconds / seconds);
        prettyTime += `${value}${symbol} `
        differenceInSeconds = differenceInSeconds % seconds
        loopCount++;
      }
    });
    return `${prettyTime} ago`;
  }
}

export const splitMinutes = (minutes) => {
  const minute = Math.floor(minutes);
  const seconds = Math.round((minutes - minute) * 60);
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return {
    minutes: minute,
    seconds,
    formattedSeconds,
  }
}

export const getMilitaryTime = (timestamp) => {
  if (timestamp) {
    const day = dayjs(timestamp.toDate())
    return day.format('H:mm');
  }
}

export const compareTimestamps = (timestampA, timestampB) => {
  const secondsCompare = compareValues(timestampA?.seconds, timestampB?.seconds);

  if (secondsCompare === 0) {
    return compareValues(timestampA?.nanoseconds, timestampB?.nanoseconds);
  }

  return secondsCompare;
}

export const prettyTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const getShiftedTimestampFromNow = (shiftByMs) => {
  return newFirestoreTimeStamp(new Date(Date.now() + shiftByMs));
}

export const timeCompact = (seconds) => {
  let isNegative = seconds < 0;
  const timeInSeconds = Math.abs(seconds);


  const AN_HOUR = 3600;
  const h = Math.floor(timeInSeconds / AN_HOUR);
  let m = Math.floor((timeInSeconds % AN_HOUR) / 60);
  let s = Math.floor(timeInSeconds % 60);

  if (m < 10) {
    m = `0${m}`;
  }
  if (s < 10) {
    s = `0${s}`;
  }

  return `${isNegative ? "-" : ""}${h}:${m}:${s}`;
}

export const getMostRecentTimestamp = (timestamps = []) => {
  let mostRecent = null;
  timestamps.forEach(t => {
    if (!mostRecent || compareTimestamps(mostRecent, t) > 0) {
      mostRecent = t;
    }
  });

  return mostRecent;
}
