import {useContext, useEffect, useState} from "react";
import GameContext from "../context/game-context";
import {GameOptions} from "../entities/game";

export default function useContentReveal(clue) {
  const {team, game} = useContext(GameContext);
  const [contentRevealItems, setContentRevealItems] = useState([]);

  useEffect(() => {
    if (!game || !team) {
      return;
    }

    if (game.getOption(GameOptions.CONTENT_REVEAL_ENABLED)) {
      const reveals = team.contentRevealedForClue(clue);
      const uniqueReveals = reveals.reduce(
        (uniqueReveals, item) => {
          // Filter out ones that reveal the same piece of content.
          return uniqueReveals.find(reveal => reveal.contentRevealId === item.contentRevealId) ?
            uniqueReveals :
            [...uniqueReveals, item];
        }, [],
      );
      setContentRevealItems(
        uniqueReveals.map(contentReveal => ({
          id: contentReveal.contentRevealId,
          content: game.getContentRevealFromId(contentReveal.contentRevealId).content,
        })),
      );
    } else {
      setContentRevealItems([]);
    }


  }, [team, game, clue]);

  return contentRevealItems;
}
