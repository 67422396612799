import React, {useState} from "react";
import PauseContext from "../../../context/pause-context";
import useInterval from "../../../hooks/useInterval";

const PauseProvider = ({children}) => {
  const [flashForPausedTeams, setFlashForPausedTeams] = useState(false)
  useInterval(() => setFlashForPausedTeams(value => !value), 800);
  return <PauseContext.Provider value={{
    pauseFlashOn: flashForPausedTeams,
  }}>
    {children}
  </PauseContext.Provider>;
};

export default PauseProvider;
