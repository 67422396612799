import React, {useContext, useState} from "react";
import {Button} from "react-bootstrap";
import {FooterActionsModal} from "../../../../components/components";
import useHintQuestionsFlow, {HINT_DISABLED_REASONS} from "../../../../hooks/useHintQuestionsFlow";
import appContent from "../../../../markdown/app-content";
import useInterval from "../../../../hooks/useInterval";
import {splitMinutes} from "../../../../utilities/dates";
import {InfoSquare} from "react-bootstrap-icons";
import "../hint-button/hint-button.scss";
import GameContext from "../../../../context/game-context";

const hintNavigationButtonClass = "hint-nav-button";

const HintButton = () => {
  const {
    askTakeHint,
    currentHint,
    showingHintQuestion,
    hasHints,
    moreHintsRemain,
    answerHint,
    canGoBack,
    goToPreviousHint,
    disableNoAnswer,
    disableYesAnswer,
    getHintPenalty,
    getWalkthroughPenalty,
    getHintWillUnlockTimeInMin,
    disableHintButton,
    disabledHintButtonReason,
    playerNameTakingHint,
    progressText,
    onLastHintBeforeAWalkthrough,
    liveHintButton,
    walkthroughAvailable,
  } = useHintQuestionsFlow();
  const {game} = useContext(GameContext);
  const [realtimeHintPenalty, setRealtimeHintPenalty] = useState(splitMinutes(getHintPenalty()));
  const [realtimeHintLockedTime, setRealtimeHintLockedTime] = useState(splitMinutes(getHintWillUnlockTimeInMin()));

  const getHintButtonText = (options) => {
    const {isLiveHintButton, liveHintsEnabled} = options;
    const disabledReason = isLiveHintButton ? liveHintButton.disabledReason : disabledHintButtonReason;

    if (disabledReason === HINT_DISABLED_REASONS.NOT_STARTED) {
      return appContent.inGame.hints.hintButtonTextWhenNotStarted({liveHintsEnabled: liveHintsEnabled});
    } else if (disabledReason === HINT_DISABLED_REASONS.PLAYER_TAKING_HINT) {
      return appContent.inGame.hints.hintButtonTextWhileDisabledWhilePlayerInHintFlow(playerNameTakingHint);
    } else if (disabledReason === HINT_DISABLED_REASONS.NO_MORE_HINTS) {
      return appContent.inGame.hints.hintButtonTextAfterTakingAllHints({liveHintsEnabled: liveHintsEnabled});
    } else if ([
      HINT_DISABLED_REASONS.HINT_LOCK_STARTING,
      HINT_DISABLED_REASONS.HINT_LOCK_TOOK_HINT,
      HINT_DISABLED_REASONS.HINT_LOCK_TOOK_LIVE_HINT,
    ].includes(disabledReason)) {
      return appContent.inGame.hints.hintButtonTextDisabledFromStartingHintLock({
        lockTime: realtimeHintLockedTime,
        isLiveHint: isLiveHintButton,
        liveHintsEnabled: liveHintsEnabled,
      });
    } else if (disabledReason === HINT_DISABLED_REASONS.LIVE_HINTS_NO_URL_FOR_TEAM) {
      return appContent.inGame.hints.liveHintsEnabledButNotForTeam;
    }
    // Hint Button is enabled
    else if (game.hintLocking.enabled) {
      return appContent.inGame.hints.hintButtonTextWithHintLocking({isLiveHintButton, liveHintsEnabled});
    } else {
      return appContent.inGame.hints.hintButtonText({penaltyTime: realtimeHintPenalty, isLiveHintButton, liveHintsEnabled});
    }
  };

  useInterval(() => {
    setRealtimeHintPenalty(splitMinutes(getHintPenalty()));
    setRealtimeHintLockedTime(splitMinutes(getHintWillUnlockTimeInMin()));
  }, 1000);

  const popupContent = {
    title: currentHint && (
      currentHint.isWalkthrough ? appContent.inGame.hints.walkthroughPopup.title : appContent.inGame.hints.hintQuestionsModalTitle
    ),
    content: currentHint && (
      currentHint.isWalkthrough ? appContent.inGame.hints.walkthroughPopup.content({
        walkthroughPenalty: splitMinutes(getWalkthroughPenalty()),
        hintPenalty: splitMinutes(getHintPenalty()),
      }) : currentHint.question
    ),
  };

  return <>
    {hasHints &&
      <Button
        onClick={() => askTakeHint({isLiveHint: false})}
        block
        disabled={disableHintButton}
        variant="dark"
      >
        {getHintButtonText({isLiveHintButton: false, liveHintsEnabled: liveHintButton.featureEnabled})}
      </Button>
    }

    {liveHintButton.featureEnabled && (
      <Button
        onClick={() => askTakeHint({isLiveHint: true})}
        block
        disabled={liveHintButton.disabled}
        variant="dark"
      >
        {getHintButtonText({isLiveHintButton: true, liveHintsEnabled: liveHintButton.featureEnabled})}
      </Button>
    )}

    {/*Hint Questions*/}
    <FooterActionsModal
      show={showingHintQuestion}
      onHide={() => {
      }} // can not be closed
      title={popupContent.title}
      titleRightSlot={<span className="text-no-wrap">{progressText}</span>}
      content={popupContent.content}
      hideCloseButton={true}
      buttons={[
        {
          id: "back",
          className: hintNavigationButtonClass,
          text: appContent.inGame.hints.hintQuestionsModalButtons.back,
          onClick: goToPreviousHint,
          disabled: !canGoBack,
          variant: "outline-info",
        },
        {
          id: "take-hint",
          className: hintNavigationButtonClass,
          text: currentHint?.isWalkthrough ?
            appContent.inGame.hints.hintQuestionsModalButtons.yesForWalkthrough :
            appContent.inGame.hints.hintQuestionsModalButtons.yes,
          onClick: () => answerHint(true),
          disabled: disableYesAnswer,
          variant: "outline-info",
        },
        {
          id: "next",
          className: hintNavigationButtonClass + (onLastHintBeforeAWalkthrough ? " walkthrough-button" : ""),
          text: onLastHintBeforeAWalkthrough ?
            appContent.inGame.hints.hintQuestionsModalButtons.noBeforeWalkthrough
            : appContent.inGame.hints.hintQuestionsModalButtons.no,
          onClick: () => answerHint(false),
          disabled: disableNoAnswer,
          removeIfDisabled: true,
          variant: "outline-info",
        },
      ]}
      additionalFooter={(
        <div className="d-flex">
          <div className="text-muted text-normal-line-height">
            <InfoSquare className="mr-2 mt-1"/>
          </div>
          <p className="text-muted">
            {appContent.inGame.hints.hintHelpText(walkthroughAvailable)}
          </p>
        </div>
      )}
    />
  </>;
};

HintButton.propTypes = {};

export default HintButton;
