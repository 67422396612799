import { useEffect, useState } from "react";
import useIsLoading from "../hooks/useIsLoading";
import { getTeam } from "../services/firebase";

export default function useFirebaseTeam(gameId, teamId) {
  const {isLoading, setIsLoading} = useIsLoading(true);
  const [team, setTeam] = useState();

  useEffect(() => {
    if(!gameId || !teamId) {
      return;
    }

    const subscription = getTeam(gameId, teamId).subscribe(team => {
      setTeam(team);
      setIsLoading(false);
    });
    return () => subscription.unsubscribe();
  }, [gameId, teamId, setIsLoading])

  return {
    isLoading,
    team,
  };
}
