const currentLinkRequiresTeamMarkdown = `
# YOU FOUND SPECIAL GREAT GOTHAM CHALLENGE CONTENT
However, we can't find your team to apply it to! This could be because:

1. If you're playing the game in another browser or tab, please copy and paste this page's link into the tab you are playing the game with. 
1. You are playing the game, but don't have a team setup yet in our application. Click below to do so!
1. You aren't in a game! Head to our website to find out how you can join or host a game.
`;

export default currentLinkRequiresTeamMarkdown;
