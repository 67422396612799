import { useEffect, useState } from "react";
import useIsLoading from "../hooks/useIsLoading";
import { getGame, } from "../services/firebase";

export default function useFirebaseGame(gameId) {
  const {isLoading, setIsLoading} = useIsLoading(true);
  const [game, setGame] = useState();

  useEffect(() => {
    if (!gameId) {
      return;
    }

    const subscription = getGame(gameId).subscribe(game => {
      setGame(game);
      setIsLoading(false);
    });
    return () => subscription.unsubscribe();
  }, [gameId, setIsLoading])

  return {
    isLoading,
    game,
  };
}
