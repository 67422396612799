import React, { useContext, useEffect, useState } from "react";
import UserProfileContext from "../../../context/user-profile-context";
import UserProfile from "../../../entities/user-profile";
import { anonymousSignIn, getAuth, getCurrentUserID } from "../../../services/firebase";
import { AppAlertsContext } from "../../../context/app-alerts-context";
import useFirebasePlayerData from "../../../hooks/useFirebasePlayerData";
import GameContext from "../../../context/game-context";

const UserProfileProvider = ({children}) => {
  const {game, team} = useContext(GameContext);
  const {playerData, isLoading} = useFirebasePlayerData(game?.id, team?.id);
  const {popError} = useContext(AppAlertsContext);
  const [hasAuth, setHasAuth] = useState(undefined);
  const [userProfile, setUserProfile] = useState(null);
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (playerData) {
      setUserProfile(new UserProfile({
        id: playerData.id,
        name: playerData.name,
        email: playerData.email,
        phoneNumber: playerData.phoneNumber,
        additionalFields: playerData.additionalFields || {},
      }));
    }

    setIsLoadingProfile(false);
  }, [playerData, isLoading])

  useEffect(() => {
    const subscription = getAuth().subscribe(user => {
      if (user) {
        setHasAuth(true);
      } else {
        setHasAuth(false);
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signUpOrUpdateLocalProfile = async ({name, email, phoneNumber, additionalFields = {}}) => {
    try {
      let newProfile;
      if (hasAuth) {
        newProfile = new UserProfile({
          id: getCurrentUserID(),
          name,
          email,
          phoneNumber,
          additionalFields,
        });
      } else {
        newProfile = await anonymousSignIn({
          name,
          phoneNumber,
          email,
          additionalFields,
        });
      }
      setUserProfile(newProfile);
      return newProfile;
    } catch (error) {
      popError(error.message);
    }
  }

  return <UserProfileContext.Provider value={{
    isLoading: isLoadingProfile,
    hasAuth,
    userProfile,
    signUpOrUpdateLocalProfile,
  }}>
    {children}
  </UserProfileContext.Provider>
}

export default UserProfileProvider;
