import React, {useState} from "react";
import ReactHelmetContext from "../../../context/react-helmet-context";
import parse from "html-react-parser";

export const HEAD_CONTENT_ID = {
  GAME_THEME_HEAD_CONTENT: "game-theme",
};

const ReactHelmetProvider = ({children}) => {
  const [headContent, setHeadContent] = useState({});
  const headContentKeys = Object.keys(headContent);

  const updateHeadContent = (headContentId, headContent) => {
    if (!Object.values(HEAD_CONTENT_ID).includes(headContentId)) {
      throw new Error(`Unknown key ${headContentId}. Calling addToHead was unsuccessful.`);
    }

    setHeadContent((value) =>({
      ...value,
      [headContentId]: headContent,
    }));
  };

  return <ReactHelmetContext.Provider value={{
    updateHeadContent,
  }}>
    {children}
    {headContentKeys.map(headContentId => {
      return parse(headContent[headContentId] || "", {
        replace: (domNode) => {
          // Script tags only support src attribute
          if (domNode.type === 'script') {
            return <script src={domNode.attribs?.src} type="text/javascript" />
          }
        }
      })
    })}
  </ReactHelmetContext.Provider>;
};

export default ReactHelmetProvider;
