import firebase from "firebase/app";
import {getCurrentUserID, teamCollection} from "../firebase";
import {newFirestoreTimeStamp, secondsSinceTimestamp} from "../../utilities/dates";
import firebaseApp from "../firebase-app";
import UserProfile from "../../entities/user-profile";
import {TEAM_PENALTIES} from "../../entities/penalty";
import Team from "../../entities/team";
import {markPlayerDataAsLeft} from "./player-data";
import {removePlayerPresence} from "./presence";
import {logAnswer} from "./all-answers";


function teamDataCollection(gameId, docId) {
  if (!gameId) {
    throw new Error("Missing required args on teamDataCollection");
  }
  const collectionRef = teamCollection(gameId);

  if (docId)
    return collectionRef.doc(docId);
  else
    return collectionRef;
}

export const createTeam = async (args) => {
  const {gameId, userProfile, teamName} = args;

  if (!userProfile || !userProfile instanceof UserProfile) {
    return Promise.reject(new Error("UserProfile entity expected"));
  }

  const {data} = await firebaseApp.functions().httpsCallable("createTeam")({
    gameId,
    teamName: teamName,
    userId: userProfile.id,
    userDisplayName: userProfile.name,
    userPhoneNumber: userProfile.phoneNumber,
    userEmail: userProfile.email,
    userAdditionalFields: userProfile.additionalFields,
  });
  return data; // invite code
};

export async function bulkCreateTeams(game, teamNames = []) {
  const {data} = await firebaseApp.functions().httpsCallable("createEmptyTeams")({
    gameId: game.id,
    teamNames: teamNames,
  });
  return data; // [{id, phrase}]
}

export const joinTeam = (userProfile, phrase) => {
  if (!userProfile instanceof UserProfile) {
    return Promise.reject(new Error("UserProfile entity expected"));
  }

  return firebaseApp.functions().httpsCallable("joinTeamWithPassphrase")({
    userId: userProfile.id,
    userDisplayName: userProfile.name,
    userPhoneNumber: userProfile.phoneNumber,
    userAdditionalFields: userProfile.additionalFields,
    userEmail: userProfile.email,
    phrase: phrase,
  }).then(r => ({
    gameId: r.data.gameId,
    teamId: r.data.teamId,
  }));
};

export const leaveTeam = (team) => {
  if (!team instanceof Team) {
    return Promise.reject(new Error("Team entity expected in order to leave team."));
  }

  const manipulations = [];

  // Remove player data from team
  // Only should happen if team isn't finished
  if (!team.isFinished) {
    const userId = getCurrentUserID();
    manipulations.push(teamCollection(team.gameId).doc(team.id).update({
      [`users.${userId}.leftTeamAt`]: newFirestoreTimeStamp(),
    }));
  }
  // Update presence system to make sure player has left
  manipulations.push(removePlayerPresence(team));
  // Update player's individual data as left so they won't be directed back into the game
  manipulations.push(markPlayerDataAsLeft(team));

  return Promise.all(manipulations);
};

export const renameTeam = (team, name) => {
  if (!team instanceof Team) {
    return Promise.reject(new Error("Team entity expected"));
  }

  return teamCollection(team.gameId).doc(team.id).update({
    name: name,
  });
};

export async function sendAlertToTeams(teams = [], {title, message, additionalOptions}) {
  const batch = firebaseApp.firestore().batch();

  if (teams.length === 0)
    throw new Error("No teams set to receive an alert.");
  if (!message && !title)
    throw new Error("Either message or title is required for sendAlertToTeams.");

  teams.forEach(team => {
    batch.update(teamDataCollection(team.gameId, team.id), {
      alerts: firebase.firestore.FieldValue.arrayUnion({
        id: Date.now(),
        title: title || null,
        message: message || null,
        createdBy: getCurrentUserID(),
        createdAt: newFirestoreTimeStamp(),
        additionalOptions: additionalOptions || null,
      }),
    });
  });

  await batch.commit();
}

export async function resetTeamsIgnoringADisabledClue(game, clueId) {
  const querySnap = await teamCollection(game.id)
    .where("ignoreDisabledClues", "array-contains", clueId)
    .get();

  if (querySnap.docs.length > 0) {
    const batch = firebaseApp.firestore().batch();
    querySnap.forEach(teamSnap => {
      batch.update(teamDataCollection(game.id, teamSnap.id), {
        ignoreDisabledClues: firebase.firestore.FieldValue.arrayRemove(
          clueId,
        ),
      });
    });
    await batch.commit();
  }
}


export async function keepTeamsOnDisabledClue(clueId, teams = []) {
  const batch = firebaseApp.firestore().batch();

  teams.forEach(team => {
    batch.update(teamDataCollection(team.gameId, team.id), {
      ignoreDisabledClues: firebase.firestore.FieldValue.arrayUnion(
        clueId,
      ),
    });
  });

  await batch.commit();
}

export function setTeamQuit(team, hasQuit) {
  return teamCollection(team.gameId).doc(team.id).update({
    quit: !!hasQuit,
    quitAt: hasQuit ? newFirestoreTimeStamp() : null,
  });
}


export function takePenalty({team, penaltyAmountInSeconds, penaltyType, clue, hintId = null, hintServed = false, lockHintsForMins = null}) {
  if (!Object.values(TEAM_PENALTIES).includes(penaltyType)) {
    throw new Error("Unknown penaltyType");
  }

  let penaltyData = {
    id: Date.now(),
    penaltyAmount: penaltyAmountInSeconds,
    type: penaltyType,
    createdBy: getCurrentUserID(),
    createdAt: newFirestoreTimeStamp(),
    clueId: clue?.id || null,
    hintId,
    hintServed,
    lockHintsForMins,
  };

  return teamDataCollection(team.gameId, team.id).update({
    penalties: firebase.firestore.FieldValue.arrayUnion(penaltyData),
  });
}

export function updateMostRecentPenaltyFromPlayer({team, penaltyAmountInSeconds, penaltyType, hintId, hintServed}) {
  const userId = getCurrentUserID();
  const lastPenaltyFromPlayer = team.penalties.slice().reverse().find(p => p.createdBy === userId);

  return teamDataCollection(team.gameId, team.id).update({
    penalties: team.penalties.map(p => {
      if (p.id === lastPenaltyFromPlayer.id)
        return {
          ...p._rawPenaltyData,
          penaltyAmount: penaltyAmountInSeconds || p._rawPenaltyData.penaltyAmount,
          type: penaltyType || p._rawPenaltyData.type,
          hintServed: hintServed,
          hintId: hintId || null,
        };
      else
        return p._rawPenaltyData;
    }),
  });
}

export const answerQuestion = (args) => {
  const {game, team, clue, answer, usedLink} = args;

  const isCorrect = clue.checkAnswer(answer);
  const contentRevealIds = game.checkAnswerForContentReveal(clue, answer);
  const answerMatchesContentReveal = contentRevealIds.length > 0;
  const answerHasRevealedContent = answerMatchesContentReveal && !!contentRevealIds.find(id => !team.hasAlreadyRevealedContent(clue, id));
  const promises = [];

  if (answerHasRevealedContent) {
    promises.push(
      answeredContentReveals({team, clue, answer, contentRevealIds}),
    );
  }

  if (isCorrect) {
    promises.push(answeredQuestionCorrectly({team, clue, answer, usedLink, hasRevealedContent: answerMatchesContentReveal}));
  } else {
    promises.push(answeredQuestionIncorrectly({team, clue, answer, usedLink, hasRevealedContent: answerMatchesContentReveal}));
  }

  return Promise.all(promises).then(() => {
    return {
      isCorrect,
      answerMatchesContentReveal,
      answerHasRevealedContent,
    };
  });
};

export const answeredQuestionCorrectly = (args) => {
  const {team, clue, answer, usedLink, hasRevealedContent} = args;

  const correctAnswerData = {
    clueId: clue.id,
    answer: answer || "",
    answeredBy: getCurrentUserID(),
    addedAt: newFirestoreTimeStamp(),
    usedLink: usedLink || false,
  };

  return Promise.all([
    teamCollection(team.gameId).doc(team.id).update({
      clueAnswers: firebase.firestore.FieldValue.arrayUnion({
        ...correctAnswerData,
      }),
    }),
    logAnswer({
      gameId: team.gameId,
      clueId: clue.id,
      teamId: team.id,
      hasRevealedContent: !!hasRevealedContent,
      isCorrect: true,
      ...correctAnswerData,
    }),
  ]);
};

export const answeredQuestionIncorrectly = async (args) => {
  const {team, clue, answer, usedLink, hasRevealedContent} = args;

  const incorrectAnswerData = {
    answer: answer,
    answeredBy: getCurrentUserID(),
    addedAt: newFirestoreTimeStamp(),
    usedLink: usedLink || false,
  };

  return Promise.all([
    teamCollection(team.gameId).doc(team.id).update({
      [`clueIncorrectAnswers.${clue.id}`]: firebase.firestore.FieldValue.arrayUnion({
        ...incorrectAnswerData,
      }),
    }),
    logAnswer({
      gameId: team.gameId,
      clueId: clue.id,
      teamId: team.id,
      hasRevealedContent: !!hasRevealedContent,
      isCorrect: false,
      ...incorrectAnswerData,
    }),
  ]);
};


const answeredContentReveals = async (args) => {
  const {team, clue, answer, usedLink, contentRevealIds = []} = args;

  return Promise.all(contentRevealIds.map(contentRevealId => {
    const data = {
      contentRevealId: contentRevealId,
      answer: answer,
      answeredBy: getCurrentUserID(),
      addedAt: newFirestoreTimeStamp(),
      usedLink: usedLink || false,
    };

    return teamCollection(team.gameId).doc(team.id).update({
      [`clueContentReveal.${clue.id}`]: firebase.firestore.FieldValue.arrayUnion({
        ...data,
      }),
    });
  }));
};

export const pauseTeam = async (args) => {
  const {team} = args;

  return await firebaseApp.firestore().runTransaction(async transaction => {
    const teamRef = teamCollection(team.gameId).doc(team.id);
    const teamDocFromTransaction = await transaction.get(teamRef);
    if (!teamDocFromTransaction.exists) {
      throw "Team does not exist!";
    }

    const teamFromTransaction = new Team(team.gameId, {
      id: teamDocFromTransaction.id,
      ...teamDocFromTransaction.data(),
    });

    if (teamFromTransaction.isPaused) {
      throw new Error("Can not pause a team that is currently paused.");
    }

    await transaction.update(teamRef, {
      pausedAt: newFirestoreTimeStamp(),
      lastPauseBy: getCurrentUserID(),
      pausedHistoryStart: firebase.firestore.FieldValue.arrayUnion({
        at: newFirestoreTimeStamp(),
        by: getCurrentUserID(),
      }),
    });
  });
};

export const unpauseTeam = async (args) => {
  const {team} = args;

  return await firebaseApp.firestore().runTransaction(async transaction => {
    const teamRef = teamCollection(team.gameId).doc(team.id);
    const teamDocFromTransaction = await transaction.get(teamRef);
    if (!teamDocFromTransaction.exists) {
      throw "Team does not exist!";
    }

    const teamFromTransaction = new Team(team.gameId, {
      id: teamDocFromTransaction.id,
      ...teamDocFromTransaction.data(),
    });

    if (!teamFromTransaction.isPaused) {
      throw new Error("Can not unpause a team that is not currently paused.");
    }

    await transaction.update(teamRef, {
      pausedForSecondsLogged: firebase.firestore.FieldValue.increment(secondsSinceTimestamp(team.pausedAt)),
      pausedAt: firebase.firestore.FieldValue.delete(),
      lastUnpauseBy: getCurrentUserID(),
      pausedHistoryEnd: firebase.firestore.FieldValue.arrayUnion({
        at: newFirestoreTimeStamp(),
        by: getCurrentUserID(),
      }),
    });
  });
};

export const recordClueStart = ({team, clueId}) => {
  if (!team || !clueId) {
    console.warn("recordClueStart has incorrect info");
    return;
  }
  const teamRef = teamCollection(team.gameId).doc(team.id);
  return teamRef.update({
    [`clueStartTimes.${clueId}`]: {
      at: newFirestoreTimeStamp(),
      by: getCurrentUserID(),
    },
    clueStartIds: firebase.firestore.FieldValue.arrayUnion(clueId),
  });
}

export const recordPastDisabledClue = ({team, clueId}) => {
  if (!team || !clueId) {
    console.warn("recordPastDisabledClue has incorrect info");
    return;
  }
  const teamRef = teamCollection(team.gameId).doc(team.id);
  return teamRef.update({
    [`cluePastDisabled.${clueId}`]: {
      at: newFirestoreTimeStamp(),
      by: getCurrentUserID(),
    },
    cluePastDisabledIds: firebase.firestore.FieldValue.arrayUnion(clueId),
  });
}

export const recordPastTimeLimitOnClue = ({team, timeLimitMin, clueId}) => {
  // if (!team || !clueId) {
  //   console.warn("recordPastTimeLimitOnClue has incorrect info");
  //   return;
  // }
  // console.log("recordPastTimeLimitOnClue", clueId);
  // const teamRef = teamCollection(team.gameId).doc(team.id);
  // return teamRef.update({
  //   [`cluePastTimeLimit.${clueId}`]: {
  //     at: newFirestoreTimeStamp(),
  //     by: getCurrentUserID(),
  //     timeLimitMin,
  //   },
  //   cluePastTimeLimitIds: firebase.firestore.FieldValue.arrayUnion(clueId),
  // });
}
