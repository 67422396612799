import React from "react";
import PropTypes from "prop-types";
import Clue from "../../../../entities/clue";
import useContentReveal from "../../../../hooks/useContentReveal";
import ContentRevealItem from "./ContentRevealItem";
import {ListGroup, ListGroupItem, Markdown} from "../../../../components/components";
import appContent from "../../../../markdown/app-content";

const ClueContentRevealView = ({clue}) => {
  const contentRevealItems = useContentReveal(clue)

  return <ListGroup>
    {contentRevealItems.map(({id, content}) => (
      <ListGroupItem key={id}>
        <Markdown>
          {content}
        </Markdown>
      </ListGroupItem>
    ))}
  </ListGroup>;
};

ClueContentRevealView.propTypes = {
  clue: PropTypes.instanceOf(Clue),
};

export default ClueContentRevealView;
