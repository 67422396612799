import React from 'react';
import { Logo, NavigationBar, PageLayout, PrivateClueMasterGuard } from "../../components";
import PropTypes from "prop-types";
import appContent from "../../../markdown/app-content";
import useGameLogo from "../../../hooks/useGameLogo";

export const CLUE_MASTER_NAVIGATION_ACTIVE_KEYS = {
  dashboard: 'dashboard',
  createGame: 'create-game',
  games: 'games',
  cluePreview: 'clue-preview',
  messageQueue: 'message-queue',
}

const clueMasterNavigation = [
  {
    id: CLUE_MASTER_NAVIGATION_ACTIVE_KEYS.games,
    link: '/clue-master/games',
    text: 'Games',
  },
  {
    id: CLUE_MASTER_NAVIGATION_ACTIVE_KEYS.createGame,
    link: '/clue-master/create-game',
    text: 'Create Game',
  },
  {
    id: CLUE_MASTER_NAVIGATION_ACTIVE_KEYS.cluePreview,
    link: '/clue-master/clue-preview',
    text: 'Clue Preview',
  },
  {
    id: CLUE_MASTER_NAVIGATION_ACTIVE_KEYS.messageQueue,
    link: '/clue-master/message-queue',
    text: appContent.cluemaster.messageQueue.navBarTitle,
  },
];

const ClueMasterPageLayout = (props) => {
  const {children} = props;
  const {smallLogo} = useGameLogo({useDefault: true})

  return <PrivateClueMasterGuard>
    <PageLayout>
      <Logo className="mb-5" src={smallLogo} style={{width: '100%'}}/>
      <NavigationBar
        activeID={props.activeID}
        className="mb-4"
        links={clueMasterNavigation}/>
      {children}
    </PageLayout>
  </PrivateClueMasterGuard>
}

ClueMasterPageLayout.propTypes = {
  activeID: PropTypes.oneOf(Object.values(CLUE_MASTER_NAVIGATION_ACTIVE_KEYS)),
}

export default ClueMasterPageLayout;
