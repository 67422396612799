import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {InputModal, LoadingSpinner} from "../../../components/components";
import appContent from "../../../markdown/app-content";
import {Button} from "react-bootstrap";
import Game, {GameImageOverrides} from "../../../entities/game";
import {updateGameTheme, updateGameThemeImages} from "../../../services/firebase";
import {AppAlertsContext} from "../../../context/app-alerts-context";
import {AlertType} from "../../../components/providers/app-alerts/AppAlerts";

const EditGameThemeButton = ({game, ...rest}) => {
  const {popAlert, popError} = useContext(AppAlertsContext);
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const updateTheme = async (inputs) => {
    const gameThemeContent = inputs[appContent.modals.editGameThemeModal.inputLabel];
    const bigLogoSrc = inputs[appContent.modals.editGameThemeModal.inputs.bigLogoSrc.label];
    const smLogoSrc = inputs[appContent.modals.editGameThemeModal.inputs.smLogoScr.label];

    setIsLoading(true);

    try {
      await Promise.all([
        updateGameTheme(game, gameThemeContent),
        updateGameThemeImages(game, {
          [GameImageOverrides.LARGE_LOGO]: bigLogoSrc,
          [GameImageOverrides.SMALL_LOGO]: smLogoSrc,
        })
      ]);
      popAlert(
        appContent.modals.editGameThemeModal.successMessage,
        AlertType.SUCCESS,
      );
      setShowPopup(false);
    } catch (e) {
      popError(e.message);
    }

    setIsLoading(false);
  };

  return <LoadingSpinner isLoading={isLoading}>
    <Button onClick={() => setShowPopup(true)} {...rest}>
      {appContent.cluemaster.game.optionsTab.actionsEditGameThemeButtonText}
    </Button>
    <InputModal
      show={showPopup}
      onHide={() => setShowPopup(false)}
      onFinish={updateTheme}
      title={appContent.modals.editGameThemeModal.title}
      inputs={[
        {
          label: appContent.modals.editGameThemeModal.inputs.bigLogoSrc.label,
          subText: appContent.modals.editGameThemeModal.inputs.bigLogoSrc.subText,
          defaultValue: game?.customImages?.[GameImageOverrides.LARGE_LOGO]?.src,
          formControlProps: {
            placeholder: appContent.modals.editGameThemeModal.inputs.bigLogoSrc.placeholder,
            required: false,
          },
        },
        {
          label: appContent.modals.editGameThemeModal.inputs.smLogoScr.label,
          subText: appContent.modals.editGameThemeModal.inputs.smLogoScr.subText,
          defaultValue: game?.customImages?.[GameImageOverrides.SMALL_LOGO]?.src,
          formControlProps: {
            placeholder: appContent.modals.editGameThemeModal.inputs.smLogoScr.placeholder,
            required: false,
          },
        },
        {
          label: appContent.modals.editGameThemeModal.inputLabel,
          defaultValue: game?.customHeadContent,
          formControlProps: {
            as: "textarea",
            rows: 12,
            required: false,
          },
        }]}
      buttonText={appContent.modals.editGameThemeModal.buttonText}
      secondaryFooterSlot={() => <a
        className="btn btn-outline-info"
        role="button"
        href="/theme-example.txt"
        download
      >
        Download Example
      </a>}
    />
  </LoadingSpinner>;
};

EditGameThemeButton.propTypes = {
  game: PropTypes.instanceOf(Game),
};

export default EditGameThemeButton;
