import React from "react";
import PropTypes from "prop-types";
import {Button} from "react-bootstrap";
import "./link.scss";

const Link = ({onClick, disabled, children, ...rest}) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  if (disabled) {
    return children;
  } else {
    return <Button
      className="link-component"
      variant="link"
      onClick={handleOnClick}
      {...rest}
    >
      {children}
    </Button>;
  }
};

Link.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default Link;
