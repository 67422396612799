import {gameCollection, getCurrentUserID} from "../firebase";
import Team from "../../entities/team";
import {
  compareTimestamps,
  newFirestoreTimeStamp,
  parseFirstoreTimestamp,
  secondsSinceTimestamp,
} from "../../utilities/dates";
import { collection, } from 'rxfire/firestore';
import { map, } from "rxjs/operators";
import { SHOW_IDLE_AFTER_SECONDS } from "../../config/config-options";
import firebaseApp from "../firebase-app";

const collectionName = "teamPresence";

function teamPresenceCollection(gameId) {
  return gameCollection().doc(gameId).collection(collectionName);
}

function reduceTeamPresenceToLastActivityTimestamp (teamPresenceData) {
  if (teamPresenceData) {
    const userIds = Object.keys(teamPresenceData);

    // Reduce data to last timestamp with activity
    return userIds.reduce((recentOfflineTimestamp, userId) => {
      const {state, last_changed, hasLeftTeam} = teamPresenceData[userId];
      const lastChangeTimestamp = parseFirstoreTimestamp(last_changed);

      // Skip players who have left the team
      if (hasLeftTeam) {
        return recentOfflineTimestamp;
      }

      if (state === "online") {
        return newFirestoreTimeStamp();
      } else if (!recentOfflineTimestamp) {
        return lastChangeTimestamp;
      } else {
        return compareTimestamps(lastChangeTimestamp, recentOfflineTimestamp) < 0 ?
          lastChangeTimestamp : recentOfflineTimestamp
      }
    }, undefined);
  } else {
    return null;
  }
}

export function idleTeams(game) {
  return collection(teamPresenceCollection(game.id)).pipe(
    map(docs => {
      const teamsPresenceData = docs.map(doc => ({
        teamId: doc.id,
        lastActivity: reduceTeamPresenceToLastActivityTimestamp(doc.data())
      }));

      // Return only teams who have idled
      return teamsPresenceData.filter(
        ({lastActivity, teamId}) => {
          return !lastActivity || secondsSinceTimestamp(lastActivity) >= SHOW_IDLE_AFTER_SECONDS
        }
      ).map(d => d.teamId);
    })
  );
}

export async function getTeamIdleTime(team) {
  if (!team instanceof Team) {
    throw new Error("Team args is required for getTeamIdleTime()");
  }

  const d = await teamPresenceCollection(team.gameId).doc(team.id).get();
  return reduceTeamPresenceToLastActivityTimestamp(d.data());
}

export function removePlayerPresence(team) {
  return firebaseApp.functions().httpsCallable("markPlayerPresenceAsLeftTeam")({
    gameId: team.gameId,
    teamId: team.id,
    userId: getCurrentUserID(),
  });
}
