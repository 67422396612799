import React  from "react";
import PropTypes from "prop-types";
import Game from "../../entities/game";
import { navigate } from "gatsby";

const RedirectIfGameIsHosted = ({ game, url, children }) => {
  if (game?.options.isHostedByClueMaster) {
    navigate(url)
    return <></>
  }
  else if (game) {
    return <>{children}</>
  }
  else {
    return <></>;
  }
}

RedirectIfGameIsHosted.propTypes = {
  game: PropTypes.instanceOf(Game),
  url: PropTypes.string.isRequired,
  children: PropTypes.node,
}


export default RedirectIfGameIsHosted;