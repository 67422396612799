import {decodeClueAnswer} from "../services/firestore/clues";
import {answeredQuestionCorrectly, answeredQuestionIncorrectly} from "../services/firestore/teams";
import useCurrentClueLogic from "./useCurrentClueLogic";
import {useContext} from "react";
import {AppAlertsContext} from "../context/app-alerts-context";
import GameContext from "../context/game-context";

export const USE_CLUE_ADVANCE_CHECK_RESULT = {
  CORRECT: "correct",
  INCORRECT: "wrong",
}

const useClueAdvanceCheck = () => {
  const {game, team} = useContext(GameContext);
  const {clue} = useCurrentClueLogic(game, team);
  const {popError} = useContext(AppAlertsContext);

  return async (code) => {
    try {
      const urlAnswer = decodeClueAnswer(code);
      if (clue.checkAnswer(urlAnswer)) {
        await answeredQuestionCorrectly({
          team,
          clue,
          answer: urlAnswer,
          usedLink: true,
        });
        return USE_CLUE_ADVANCE_CHECK_RESULT.CORRECT;
      } else {
        await answeredQuestionIncorrectly({
          team,
          clue,
          answer: urlAnswer,
          usedLink: true,
        });
        return USE_CLUE_ADVANCE_CHECK_RESULT.INCORRECT;
      }
    } catch (e) {
      popError(e.message);
    }
  };
};

export default useClueAdvanceCheck;
