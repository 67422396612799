import React, {useState} from "react";
import PropTypes from "prop-types";
import Game from "../../../entities/game";
import Team from "../../../entities/team";
import RecentAnswersControls from "../../../app/clue-master/recent-answers/RecentAnswersControls";
import useRecentAnswers from "../../../app/clue-master/recent-answers/useRecentAnswers";
import appContent from "../../../markdown/app-content";
import {RECENT_WRONG_ANSWER_LIMIT} from "../../../config/config-options";

import RecentAnswersTable from "../../../app/clue-master/recent-answers/RecentAnswersTable";

const RecentAnswersTab = ({game, teams}) => {
  const [selectedClue, setSelectedClue] = useState(""); // id of clue
  const [selectedTeam, setSelectedTeam] = useState(""); // id of team
  const recentWrongAnswer = useRecentAnswers({
    game,
    filters: {
      clueId: selectedClue,
      teamId: selectedTeam,
    },
  });

  return <>
    <RecentAnswersControls
      clues={game?.getAllClues()}
      onClueChange={setSelectedClue}
      selectedClue={selectedClue}
      teams={teams}
      onTeamChange={setSelectedTeam}
      selectedTeam={selectedTeam}
    />

    <RecentAnswersTable
      game={game}
      teams={teams}
      recentAnswers={recentWrongAnswer}
    />
    <p className="text-center text-muted">
      {appContent.cluemaster.game.recentWrongAnswersTab.limitMessage(
        recentWrongAnswer.length === RECENT_WRONG_ANSWER_LIMIT,
        RECENT_WRONG_ANSWER_LIMIT,
      )}
    </p>
  </>;
};

RecentAnswersTab.propTypes = {
  game: PropTypes.instanceOf(Game),
  teams: PropTypes.arrayOf(PropTypes.instanceOf(Team)),
};


export default RecentAnswersTab;
