import React, {useState} from "react";
import PropTypes from "prop-types";
import {Button} from "react-bootstrap";
import {AllPlayerDataModal} from "../../../components/components";
import Game from "../../../entities/game";
import appContent from "../../../markdown/app-content";

const CopyPlayerInfo = ({game, ...rest}) => {
  const [showPlayerInfoModal, setShowPlayerInfoModal] = useState(false);
  
  return <>
    <Button onClick={() => setShowPlayerInfoModal(true)} {...rest}>
      {appContent.cluemaster.game.optionsTab.actionsCopyPlayerDataForGame}
    </Button>
    <AllPlayerDataModal game={game} show={showPlayerInfoModal} onHide={() => setShowPlayerInfoModal(false)} />
  </>
};

CopyPlayerInfo.prototype = {
  game: PropTypes.instanceOf(Game),
};

export default CopyPlayerInfo;
