import React from "react";
import PropTypes from "prop-types";
import {LinkNewTab} from "../components";

const LinksAllowedText = ({ text }) => {
  const urlRegexPattern = /(\bwww\.[^\s]+|https?:\/\/[^\s]+)/;
  const textParts = text?.split(urlRegexPattern || []);

  return textParts.map(part => {
    if (urlRegexPattern.test(part)) {
      const url = part.includes("http") ? part : `http://${part}`;

      return <LinkNewTab to={url}>{part}</LinkNewTab>
    } else {
      return <span>{part}</span>
    }
  });
};

LinksAllowedText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default LinksAllowedText;
