import React from "react";
import PropTypes from "prop-types";
import {EditableBadgeList} from "../components";
import Clue from "../../entities/clue";
import appContent from "../../markdown/app-content";
import CopyClueAdvancedButton from "../../app/clue-master/clues/components/CopyClueAdvancedButton";

const EditClueAnswers = ({clue, onChange}) => {
  if (!clue) {
    return null;
  }

  const onRemoveAnswer = (removeAnswer) => {
    const answers = clue._rawClueData.answer || [];
    onChange(
      new Clue(clue.id, {
        ...clue._rawClueData,
        answer: answers.filter(answer => answer !== removeAnswer),
      }),
    );
  };

  const handleEditOrAdd = (badgeName, editedValues) => {
    const newAnswer = editedValues[appContent.cluemaster.game.clueTab.answerPopup.label];
    let newAnswers = clue._rawClueData.answer ? [...clue._rawClueData.answer] : [];

    // Adding
    if (!badgeName) {
      newAnswers.push(newAnswer);
    }
    // Editing
    newAnswers = newAnswers.map(answer => {
      if (answer === badgeName)
        return newAnswer;
      else
        return answer;
    });

    onChange(
      new Clue(clue.id, {
        ...clue._rawClueData,
        answer: newAnswers,
      }),
    );
  };

  return <div className="form-group">
    <div className="form-label">{appContent.cluemaster.game.clueTab.correctAnswersLabel}</div>
    <EditableBadgeList
      badges={clue.answers}
      variant="success"
      addButtonText={appContent.cluemaster.game.clueTab.correctAnswersAddText}
      inputModal={{
        title: (isEditing) => isEditing ? appContent.cluemaster.game.clueTab.answerPopup.editTitle : appContent.cluemaster.game.clueTab.answerPopup.addTitle,
        inputs: (editingBadge) => [{
          label: appContent.cluemaster.game.clueTab.answerPopup.label,
          defaultValue: editingBadge || "",
          formControlProps: {
            placeholder: appContent.cluemaster.game.clueTab.answerPopup.placeholder,
            type: "text",
            required: true,
          },
        }],
        onFinish: handleEditOrAdd,
        buttonText: appContent.cluemaster.game.clueTab.answerPopup.buttonText,
        secondaryFooterSlot: (inputValues) => {
          if (inputValues[appContent.cluemaster.game.clueTab.answerPopup.label]) {
            return <CopyClueAdvancedButton answerToEncode={inputValues[appContent.cluemaster.game.clueTab.answerPopup.label]}>
              {appContent.cluemaster.game.clueTab.copyAdvancedLink}
            </CopyClueAdvancedButton>;
          }
        },
      }}
      onRemove={onRemoveAnswer}
    />
  </div>;
};

EditClueAnswers.propTypes = {
  clue: PropTypes.instanceOf(Clue),
  onChange: PropTypes.func.isRequired,
};

export default EditClueAnswers;
