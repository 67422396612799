import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Button, Form, Modal} from "react-bootstrap";
import {getMilitaryTime, convertToDayJs, newFirestoreTimeStamp} from "../../utilities/dates";
import firebase from "firebase/app";
import "firebase/firestore";

const DateTimeInputModal = ({
  title,
  dateLabel,
  timeLabel,
  timeHint,
  submitText,
  initialValue,
  onSetStartTime,
  show,
  ...rest
}) => {
  const [editedStartDate, setEditedStartDate] = useState("");
  const [editedStartTime, setEditedStartTime] = useState("");

  useEffect(() => {
    if (show) {
      const value = initialValue instanceof firebase.firestore.Timestamp ? initialValue : newFirestoreTimeStamp();
      setEditedStartDate(convertToDayJs(value).format("YYYY-MM-DD"));
      setEditedStartTime(getMilitaryTime(value));
    }
  }, [show, initialValue]);

  const handleSubmit = event => {
    event.preventDefault();

    if (onSetStartTime) {
      onSetStartTime(newFirestoreTimeStamp(new Date(`${editedStartDate} ${editedStartTime}`)));
    }
  };

  return <Modal show={show} {...rest}>
    <Form onSubmit={handleSubmit}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group>
          <Form.Label>{dateLabel}</Form.Label>
          <Form.Control
            type="date"
            required
            onChange={(event) => setEditedStartDate(event.target.value)}
            value={editedStartDate}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{timeLabel}</Form.Label>
          <Form.Control
            type="time"
            required
            onChange={(event) => setEditedStartTime(event.target.value)}
            value={editedStartTime}
          />
          <Form.Text className="text-muted">{timeHint}</Form.Text>
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <Button type="submit" variant="info">{submitText}</Button>
      </Modal.Footer>
    </Form>
  </Modal>;
};

DateTimeInputModal.propTypes = {
  title: PropTypes.string.isRequired,
  dateLabel: PropTypes.string.isRequired,
  timeLabel: PropTypes.string.isRequired,
  submitText: PropTypes.string.isRequired,
  initialValue: PropTypes.object, // firebase timestamp
  onSetStartTime: PropTypes.func,
  timeHint: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default DateTimeInputModal;
