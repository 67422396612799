import React from "react";
import PropTypes from "prop-types";


const AppLikeHeader = ({ title, leftSlot, rightSlot }) => {

  return <div className="d-flex align-items-center mb-2">
    <div className="w-25">
      {leftSlot}
    </div>
    <div className="w-50 text-center">
      <h1 className="mb-0 h3">
        {title}
      </h1>
    </div>
    <div className="w-25" >
      {rightSlot}
    </div>
  </div>
}

AppLikeHeader.propTypes = {
  title: PropTypes.string.isRequired,
  leftSlot: PropTypes.node,
  rightSlot: PropTypes.node,
}

export default AppLikeHeader;