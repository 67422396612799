import { useEffect, useRef } from 'react';

export default function useInterval(callback, delay, startAfterDelay = 0) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let intervalId;
      const timeoutId = setTimeout(() => {
        intervalId = setInterval(tick, delay);
      }, startAfterDelay);
      return () => {
        clearTimeout(timeoutId);
        if (intervalId)
          clearInterval(intervalId);
      }
    }
  }, [delay]);
}
