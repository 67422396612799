import React from "react";
import PropTypes from "prop-types";
import Team from "../../../entities/team";
import {Table} from "react-bootstrap";
import "./leaderboard.scss";
import appContent from "../../../markdown/app-content";
import LeaderboardRow from "./LeaderboardRow";
import Game from "../../../entities/game";

//
// The Leaderboard Component is responsible for gathering and presenting the data required for each team.
// It is not responsible for sorting teams due to performance.
//
const Leaderboard = ({game, leaderboardDataForTeams, idlingTeams = [], onNameClicked, rankStartAt = 1}) => {
  const tableRows = leaderboardDataForTeams.map((data, index) => {
    return <LeaderboardRow
      game={game}
      key={data.team.id}
      onNameClicked={() => onNameClicked(data.team)}
      rank={rankStartAt + index}
      leaderboardDataForTeam={data}
      isIdle={idlingTeams.indexOf(data.team.id) !== -1}
    />;
  });

  return <Table className="leaderboard" variant="dark" size="sm">
    <thead>
    <tr>
      <th className="leaderboard__rank">{appContent.cluemaster.game.leaderboard.columnLabels.rank}</th>
      <th className="leaderboard__name">{appContent.cluemaster.game.leaderboard.columnLabels.name}</th>
      <th className="leaderboard__clue">{appContent.cluemaster.game.leaderboard.columnLabels.clue}</th>
      <th className="leaderboard__penalty">{appContent.cluemaster.game.leaderboard.columnLabels.penaltyMinutes}</th>
      <th className="leaderboard__advanced">{appContent.cluemaster.game.leaderboard.columnLabels.lastAdvancedTime}</th>
    </tr>
    </thead>
    <tbody>
    {tableRows}
    </tbody>
  </Table>;
};

Leaderboard.propTypes = {
  game: PropTypes.instanceOf(Game),
  teams: PropTypes.arrayOf(PropTypes.instanceOf(Team)),
  idlingTeams: PropTypes.arrayOf(PropTypes.string),
  onNameClicked: PropTypes.func.isRequired,
  rankStartAt: PropTypes.number,
};

export default Leaderboard;
