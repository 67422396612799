import { useState } from "react";
import {compareValues, deepEqual} from "../../utilities/helper-functions";
import {TeamStatus} from "../../entities/team";

function hasTrackBeenEdited(game, track) {
  if (!game || !track) {
    return false;
  }

  // Compare tracks for differences
  return !deepEqual(track, game.getTrack(track.id));
}

export default function useTrackEditorClues(game, teams = []) {
  const [track, setTrack] = useState(null);

  const clues = track?.clues.map(id => game.getClue(id)) || [];
  const allClues = game?.getAllClues() || [];
  const unusedClues = !track ? [] : allClues.filter(clue => {
    return !clues.find(c => c.id === clue.id)
  }).sort((a,b) => compareValues(b.name, a.name));

  // Check the furthest unfinished team to lock the track editor
  const teamsOnCurrentTrack = teams
    .filter(team => team.isAssignedTrack && team.assignedTrackId === track?.id)
    .filter(team => team.findTeamStatus(game) !== TeamStatus.FINISHED);
  const lockPoint = teamsOnCurrentTrack.reduce((maxIndex, team) => {
    const clue = team.findCurrentClueForTeamFromGame(game);
    const clueIndex = clues.findIndex(c => c.id === clue?.id);
    if (clueIndex > maxIndex)
      return clueIndex
    else
      return maxIndex;
  }, -1);

  return {
    track,
    trackIsEdited: hasTrackBeenEdited(game, track),
    setTrack,
    clues,
    unusedClues,
    lockPoint,
  }
}
