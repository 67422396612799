import { collectionData } from 'rxfire/firestore';
import { map, } from "rxjs/operators";
import {gameCollection, getCurrentUserID} from "../firebase";
import Game from "../../entities/game";
import SmsAlert from "../../entities/smsAlert";
import {newFirestoreTimeStamp} from "../../utilities/dates";

//
// Only authenticated cluemasters are able to access this collection
//
const collectionName = "smsAlerts";

function smsAlertsCollection(gameId) {
  return gameCollection().doc(gameId).collection(collectionName);
}

export function smsHistory(game) {
  if (!game instanceof Game) {
    throw new Error("Game required to get sms history.")
  }

  return collectionData(
    smsAlertsCollection(game.id).orderBy("createdAt", 'desc'),
    'id'
  ).pipe(
    map(docsData => {
      return docsData.map(d => {
        return new SmsAlert(d);
      });
    }),
  );
}

export function logInAppOnlyAlertInSmsHistory(game, data) {
  const {title,message,blastOption} = data;

  if (!game instanceof Game) {
    throw new Error("Game required to logAlertSentInSmsHistory.")
  }

  return smsAlertsCollection(game.id).doc().set({
    title,
    message,
    blastOption,
    createdBy: getCurrentUserID(),
    createdAt: newFirestoreTimeStamp(),
    sentInAppOnly: true,
  });
}
