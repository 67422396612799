import {useState} from "react";

const useAlertTeamGroups = (game, teams = []) => {
  const clues = game?.getAllClues() || [];
  const [selectedClue, setSelectedClue] = useState(null);
  const unfinishedTeams = teams.filter((team) => !team.isFinished);
  const finishedTeams = teams.filter((team) => team.isFinished);
  const clueTeams = teams.filter((team) => selectedClue && selectedClue?.id === team.findCurrentClueForTeamFromGame(game)?.id);


  return {
    unfinishedTeams,
    finishedTeams,
    clueTeams,
    clues,
    selectedClue,
    setSelectedClue,
  };
};

export default useAlertTeamGroups;
