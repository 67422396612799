import React from "react";
import PropTypes from "prop-types";
import Clue from "../../entities/clue";
import appContent from "../../markdown/app-content";
import { EditableBadgeList } from "../components";

const EditClueFormatting = ({ clue, onChange } ) => {
  if (!clue) {
    return null;
  }

  const handleEditOrAdd = (badgeName, editedValues) => {
    let contentFormatting = clue.contentFormatting;
    const newFormatValue = editedValues[appContent.cluemaster.game.clueTab.contentFormatPopup.inputLabel];

    // Adding
    if (!badgeName) {
      // Add if unique
      if (clue.contentFormatting.indexOf(newFormatValue) === -1) {
        contentFormatting = [
          ...clue.contentFormatting,
          newFormatValue,
        ];
      }
    }
    // Editing
    else {
      contentFormatting = clue.contentFormatting.map(f => {
        if (f === badgeName) {
          return newFormatValue;
        } else {
          return f;
        }
      });
    }

    onChange(new Clue(clue.id,{
      ...clue._rawClueData,
      contentFormatting,
    }));
  }

  const handleRemove = (badgeName) => {
    onChange(new Clue(clue.id,{
      ...clue._rawClueData,
      contentFormatting: clue.contentFormatting.filter(f => f !== badgeName),
    }));
  }

  return <div className="form-group">
    <div className="form-label">{appContent.cluemaster.game.clueTab.contentFormatLabel}</div>
    <EditableBadgeList
      badges={clue?.contentFormatting}
      variant="primary"
      inputModal={{
        title: (isEditing) => isEditing ? appContent.cluemaster.game.clueTab.contentFormatPopup.editTitle : appContent.cluemaster.game.clueTab.contentFormatPopup.addTitle,
        inputs: (editingBadge) => [{
          label: appContent.cluemaster.game.clueTab.contentFormatPopup.inputLabel,
          defaultValue: editingBadge || '',
          formControlProps: {
            placeholder: appContent.cluemaster.game.clueTab.contentFormatPopup.placeholder,
            type: "text",
            required: true,
          }
        }],
        onFinish: handleEditOrAdd,
        buttonText: appContent.cluemaster.game.clueTab.contentFormatPopup.buttonText
      }}
      addButtonText={appContent.cluemaster.game.clueTab.contentFormatAddText}
      onRemove={handleRemove}
    />
  </div>
};

EditClueFormatting.propTypes = {
  clue: PropTypes.instanceOf(Clue),
  onChange: PropTypes.func.isRequired,
}

export default EditClueFormatting;
