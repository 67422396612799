export const SHOW_IDLE_AFTER_SECONDS = 20 * 60; // seconds
export const DELAY_HINT_CONFIRM_FOR_SECONDS = 3; // seconds
export const DEFAULT_HINT_PENALTY_MINUTES_MINIMUM = 0; // minutes
export const DEFAULT_HINT_PENALTY_MINUTES_BASE = 15; // minutes
export const DEFAULT_WALKTHROUGH_PENALTY_MINUTES_MINIMUM = 5; // minutes
export const DEFAULT_WALKTHROUGH_PENALTY_MINUTES_BASE = 25; // minutes
export const DEFAULT_HINT_NUDGE_TIME_MINUTES = 17; // minutes
export const DELAY_HINT_NUDGE_FOR_SECONDS_AFTER_ENTERING_GAME = 30; // seconds
export const ALERTS_DISAPPEAR_AFTER_MS = 5000; // milliseconds
export const SHARE_CODE_MODAL_DISABLE_FOR_SECONDS = 10;
export const INCLUDE_ANSWERED_DISABLED_CLUES_IN_LEADERBOARD_TOTAL = true;
export const TEAM_NAME_CHARACTER_LIMIT = 60;
export const USER_NAME_CHARACTER_LIMIT = 40;
export const THROTTLE_DOUBLE_CLICK_TIME = 200; // milliseconds
export const SHOW_EDIT_INTERSTITIAL_ON_HOSTED_GAMES = false;
export const ENABLE_RECENT_WRONG_ANSWERS_LEADERBOARD_TAB = true;
export const RECENT_WRONG_ANSWER_LIMIT = 20;
export const ANSWER_THROTTLE_TIME = 3000; // milliseconds
export const CLUE_CONTENT_REVEAL_DELIMINATOR = ",";
export const DEFAULT_BONUS_TIME_MINUTES = 1;
export const CROSS_TAB_DELAY_CHECK_TEAM_MS = 250;
export const CROSS_TAB_DELAY_CHECK_CODE_MS = 4500; // long due to firebase function warm up
export const CROSS_TAB_DELAY_DEFAULT_MS = 2500;
