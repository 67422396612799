import { useEffect, useState } from "react";
import useIsLoading from "../hooks/useIsLoading";
import { getTeams } from "../services/firebase";

export default function useFirebaseTeams(gameId) {
  const {isLoading, setIsLoading} = useIsLoading(true);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const subscription = getTeams(gameId).subscribe(teams => {
      setTeams(teams);
      setIsLoading(false);
    });
    return () => subscription.unsubscribe();
  }, [gameId, setIsLoading])

  return {
    isLoading,
    teams,
  };
}
