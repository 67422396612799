import React from "react";
import {ListGroup as BoostrapListGroup} from "react-bootstrap";
import "./list-group.scss";

const ListGroup = ({children}) => {
  return <BoostrapListGroup className="list-group" variant="flush">
    {children}
  </BoostrapListGroup>
};

export default ListGroup;
