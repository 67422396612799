const localStorageDelimiter = "&";

export const CROSS_TAB_EVENTS = {
  RECEIVED_EVENT_ON_TAB_WITH_TEAM: "CROSS_TAB:received-event-on-team",
  CHECK_TEAM: "CROSS_TAB:check-team-exists",
  CLUE_ADVANCE: "CROSS_TAB:clue-advance",
  CLUE_ADVANCE_RESPONSE: "CROSS_TAB:clue-advance-response",
  CHECK_COIN: "CROSS_TAB:check-coin",
  CHECK_COIN_RESPONSE: "CROSS_TAB:check-coin-response",
};

export const sendCrossTabEvent = (event, data) => {
  window.localStorage.setItem(event, Date.now() + localStorageDelimiter + data);
};

export const listenForCrossTabEvents = (onEvent) => {
  const onStorageEvent = (event) => {
    if (event.storageArea != localStorage) return;

    if (Object.values(CROSS_TAB_EVENTS).indexOf(event.key) !== -1) {
      onEvent(event.key, event.newValue.substring(event.newValue.indexOf(localStorageDelimiter) + 1));
    }
  };

  window.addEventListener("storage", onStorageEvent);

  return () => window.removeEventListener("storage", onStorageEvent);
};
