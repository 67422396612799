import React, {useState} from "react";
import PropTypes from "prop-types";
import Game from "../../../../entities/game";
import Team from "../../../../entities/team";
import useInterval from "../../../../hooks/useInterval";
import {getPenaltyLeaderboardDataForTeam} from "../../../../services/leaderboard/leaderboard";

const TeamAdjustmentField = ({game, team}) => {
  const [adjustmentText, setAdjustmentText] = useState(getPenaltyLeaderboardDataForTeam({game, team}));
  useInterval(() => setAdjustmentText(getPenaltyLeaderboardDataForTeam({game, team})), 500);
  return adjustmentText;
};

TeamAdjustmentField.propTypes = {
  game: PropTypes.instanceOf(Game),
  team: PropTypes.instanceOf(Team),
};

export default TeamAdjustmentField;
