import equal from "deep-equal";
import sillyName from "../utilities/silly-name"
import plur from "plur";

export function isLocalNetwork(hostname = window.location.hostname) {
  return (
    (['localhost', '127.0.0.1', '', '::1'].includes(hostname))
    || (hostname.startsWith('192.168.'))
    || (hostname.startsWith('10.0.'))
    || (hostname.endsWith('.local'))
  )
}

export function mapDictionaryValues(dictionary = {}, mapValue = (v) => v) {
  const newMap = {};
  Object.keys(dictionary).forEach(key => {
    newMap[key] = mapValue(dictionary[key], key);
  });
  return newMap;
}

export function getValuesFromDictionary(dictionary, mapValue = (v) => v) {
  const keys = Object.keys(dictionary);
  return keys.map(key => mapValue(dictionary[key]));
}

export function getPhoneNumberRegex() {
  return "^\\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\\W*\\d){0,13}\\d$";
}

export function convertToCSV(dataRows = []) {
  let output = '';
  dataRows.forEach((dataRow = [], index) => {
    if (index !== 0) {
      output += "\n";
    }

    dataRow.forEach((field, index) => {
      if (index !== 0) {
        output += ',';
      }
      const nextValue = typeof field === 'string' ? field.replaceAll('"', '\\"').replaceAll('\n', '\\n') : field;
      output += `"${nextValue}"`
    });
  });

  return output;
}

export function copyToClipboard(text) {
  return navigator.clipboard.writeText(text);
}

export function compareValues(a, b) {
  if (a > b)
    return -1;
  if (a < b)
    return 1;
  return 0;
}

export function isEmptyObject(obj) {
  return obj &&
    Object.keys(obj).length === 0 &&
    obj.constructor === Object;
}

export function allLowerCaseAndNoWhitespace(s = '') {
  return s.toLowerCase().replace(/[^\w]/g,'');
}

export function getSiteOrigin () {
  return window?.location.origin;
}

export function deepEqual(a, b) {
  return equal(a,b);
}

export function randomPluralName() {
  const nameParts = sillyName().split(' ');

  nameParts[nameParts.length - 1] = plur(nameParts[nameParts.length - 1], 2);
  return nameParts.reduce((fullName, namePart) => {
    if (!fullName) {
      return namePart;
    } else {
      return fullName + " " + namePart;
    }
  });
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function nullishStringsShouldBeNull(string) {
  if (
    string === "undefined" ||
    string === "null" ||
    string === ""
  )
    return null;
  else
    return string;
}

export function roundToDigits(number, fractionDigits = 0) {
  const originalNumber = number.toString()
  const toFixedNumber = Number(number).toFixed(fractionDigits)

  if (originalNumber.length > toFixedNumber.length) {
    return Number(toFixedNumber);
  } else {
    return Number(originalNumber);
  }
}

export function getOffset( el ) {
  var _x = 0;
  var _y = 0;
  while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return { top: _y, left: _x };
}
