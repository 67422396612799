import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import "./attentionContainer.scss";
import useInterval from "../../hooks/useInterval";
import {compareTimestamps, newFirestoreTimeStamp, parseFirstoreTimestamp} from "../../utilities/dates";
import {DELAY_HINT_NUDGE_FOR_SECONDS_AFTER_ENTERING_GAME} from "../../config/config-options";
import classNames from "classnames";

const attentionValues = {
  light: "2000",
  heavy: "1500",
}

const AttentionContainer = ({attention, children}) => {
  const [giveAttention, setGiveAttention] = useState(false);
  const c = classNames(["attentionContainer", {"attentionContainer--attention": giveAttention}]);

  useInterval(() => {
    setGiveAttention(!giveAttention);
  }, attentionValues[attention]);

  return <div className={c}>
    <div className="line"/>
    <div className="line"/>
    <div className="line"/>
    <div className="line"/>
    <div className="line"/>
    {children}
  </div>;
};

AttentionContainer.propTypes = {
  attention: PropTypes.oneOf(["light"]).isRequired,
};

export default AttentionContainer;
