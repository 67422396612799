import React from 'react';
import PropTypes from 'prop-types';

const Logo = (props) => {
  const {src, alt, style, ...rest} = props;

  return <img
    src={src}
    alt={alt || ''}
    style={style}
    {...rest}
  />
}

Logo.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  style: PropTypes.shape({
    width: PropTypes.string.isRequired,
  }),
}

export default Logo;