import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {InputModal, RemovableBadge, ReorderModal} from "../components";
import {Button} from "react-bootstrap";
import Hint from "../../entities/hint";

const EditableBadgeList = ({
  badges,
  variant,
  inputModal,
  onRemove,
  addButtonText,
  enableReorder,
  reorderText,
  onReorder,
  onStartEditingBadge,
  onStartAddingBadge,
}) => {
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showReorderModal, setShowReorderModal] = useState(false);
  const [editingBadge, setEditingBadge] = useState(null);

  const inputModalProps = {
    ...inputModal,
    title: inputModal.title(!!editingBadge),
    inputs: inputModal.inputs(editingBadge),
    show: showAddEditModal,
    onHide: () => {
      setEditingBadge(null);
      setShowAddEditModal(false);
    },
    onFinish: (answers) => {
      setEditingBadge(null);
      setShowAddEditModal(false);
      inputModal.onFinish(editingBadge, answers);
    },
    secondaryFooterSlot: (inputValues) => {
      if (inputModal?.secondaryFooterSlot) {
        return inputModal?.secondaryFooterSlot(inputValues, editingBadge);
      }
    },
  };

  const handleReorder = (items) => {
    onReorder(items.map(i => i.text));
    setShowReorderModal(false);
  };

  return <div className="editable-badge-list">
    {badges && badges.map(badgeName => <RemovableBadge
      onRemove={() => onRemove(badgeName)}
      variant={variant}
      key={badgeName}
      onClick={() => {
        setEditingBadge(badgeName);
        setShowAddEditModal(true);
        if (onStartEditingBadge)
          onStartEditingBadge(badgeName);
      }}
    >{badgeName}</RemovableBadge>)}
    <Button className="editable-badge-list__add" size="sm" onClick={() => {
      setEditingBadge(null);
      setShowAddEditModal(true);
      if (onStartAddingBadge)
        onStartAddingBadge();
    }}>
      {addButtonText}
    </Button>
    {enableReorder && (
      <Button
        className="editable-badge-list__reorder ml-1"
        size="sm"
        onClick={() => setShowReorderModal(true)}
        disabled={badges.length < 2}
      >
        {reorderText}
      </Button>
    )}
    <InputModal {...inputModalProps} />
    <ReorderModal
      show={showReorderModal}
      onHide={() => setShowReorderModal(false)}
      onFinish={handleReorder}
      title="Reorder"
      buttonText="Done"
      items={badges.map(b => ({id: b, text: b}))}
    />
  </div>;
};

EditableBadgeList.propTypes = {
  inputModal: PropTypes.shape({
    title: PropTypes.func.isRequired,
    inputs: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired,
    onFinish: PropTypes.func.isRequired,
    secondaryFooterSlot: PropTypes.func, // (inputValues, editingBadge) => React.Node
  }),
  onRemove: PropTypes.func.isRequired,
  addButtonText: PropTypes.string.isRequired,
  badges: PropTypes.arrayOf(PropTypes.string),
  variant: PropTypes.string,
  enableReorder: PropTypes.bool,
  reorderText: PropTypes.string,
  onReorder: PropTypes.func,
  onStartEditingBadge: PropTypes.func,
  onStartAddingBadge: PropTypes.func,
};

export default EditableBadgeList;
