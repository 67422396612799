import React from "react";
import PropTypes from "prop-types";
import User from "../../entities/user";
import classNames from "classnames";
import PlayerData from "../../entities/playerData";

const UserDecoration = ({userOrPlayerData, children, className, ...rest}) => {
  const hasLeft = !!userOrPlayerData?.leftTeamAt || userOrPlayerData?.leftTeam;

  const c = classNames([
    className,
    {
      "text-strike-through": hasLeft,
    }
  ]);

  return <span className={c} {...rest}>
    {children}
  </span>;
};

UserDecoration.propTypes = {
  userOrPlayerData: PropTypes.oneOfType([
    PropTypes.instanceOf(User).isRequired,
    PropTypes.instanceOf(PlayerData).isRequired,
  ]),
  children: PropTypes.node.isRequired,
};

export default UserDecoration;
