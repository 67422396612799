import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import Game, {GameOptions} from "../../../entities/game";
import {InputModal, Link} from "../../../components/components";
import {setGameOption} from "../../../services/firebase";
import {AppAlertsContext} from "../../../context/app-alerts-context";
import appContent from "../../../markdown/app-content";

const AdditionalOptions = ({game}) => {
  const {popError} = useContext(AppAlertsContext);
  const [editingPenalty, setEditingPenalty] = useState(null);
  const isEditingPenalty = !!editingPenalty;

  if (!game) {
    return null;
  }

  const additionalOptions = [
    {
      option: GameOptions.HINT_PENALTY_BASE,
      text: appContent.cluemaster.game.optionsTab.additionalOptions.hintPenaltyBaseLabel,
      value: game.hintPenaltyBase,
      labelWhenEditing: appContent.cluemaster.game.optionsTab.additionalOptions.hintPenaltyBaseLabelWhileEditing,
    },
    {
      option: GameOptions.HINT_PENALTY_MINIMUM,
      text: appContent.cluemaster.game.optionsTab.additionalOptions.hintPenaltyMinimumLabel,
      value: game.hintPenaltyMinimum,
      labelWhenEditing: appContent.cluemaster.game.optionsTab.additionalOptions.hintPenaltyMinimumLabelWhileEditing,
    },
    {
      option: GameOptions.WALKTHROUGH_PENALTY_BASE,
      text: appContent.cluemaster.game.optionsTab.additionalOptions.walkthroughPenaltyBaseLabel,
      value: game.walkthroughPenaltyBase,
      labelWhenEditing: appContent.cluemaster.game.optionsTab.additionalOptions.walkthroughPenaltyBaseLabelWhileEditing,
    },
    {
      option: GameOptions.WALKTHROUGH_PENALTY_MIN,
      text: appContent.cluemaster.game.optionsTab.additionalOptions.walkthroughPenaltyMinimumLabel,
      value: game.walkthroughPenaltyMinimum,
      labelWhenEditing: appContent.cluemaster.game.optionsTab.additionalOptions.walkthroughPenaltyMinimumLabelWhileEditing,
    },
    {
      option: GameOptions.HINT_NUDGE_AFTER_MINUTES,
      text: appContent.cluemaster.game.optionsTab.additionalOptions.hintNudgeAfterMinutesLabel,
      value: game.hintNudgeAfterMinutes,
      labelWhenEditing: appContent.cluemaster.game.optionsTab.additionalOptions.hintNudgeAfterMinutesLabelWhileEditing,
    },
    {
      option: GameOptions.BONUS_TIME_PER_CODE,
      text: appContent.cluemaster.game.optionsTab.additionalOptions.bonusTimeLabel,
      value: game.bonusMinutesPerCode,
      labelWhenEditing: appContent.cluemaster.game.optionsTab.additionalOptions.bonusTimeAfterMinutesLabel,
    },
  ];

  const onEditPenalty = async (inputValues) => {
    try {
      const value = inputValues[editingPenalty?.labelWhenEditing];
      setEditingPenalty(null);
      await setGameOption(game, editingPenalty.option, value);
    } catch (e) {
      popError(e.message);
    }
  }

  return <>
    <ul>
      {additionalOptions.map(penalty => (
        <li key={penalty.option}>
          <span className="mr-2">{penalty.text}:</span>
          <Link onClick={() => setEditingPenalty(penalty)}>
            {penalty.value} {penalty.labelWhenEditing}
          </Link>
        </li>
      ))}
    </ul>
    <InputModal
      show={isEditingPenalty}
      onHide={() => setEditingPenalty(null)}
      onFinish={onEditPenalty}
      title={`Edit ${editingPenalty?.text}`}
      buttonText="Save"
      inputs={[
        {
          label: editingPenalty?.labelWhenEditing || "",
          formControlProps: {
            type: "number",
            required: true,
          },
          defaultValue: String(editingPenalty?.value),
        }
      ]}
    />
  </>
}

AdditionalOptions.propTypes = {
  game: PropTypes.instanceOf(Game),
}

export default AdditionalOptions;
