import React, {useState} from "react";
import PropTypes from "prop-types";
import {InputModal} from "../components";
import appContent from "../../markdown/app-content";
import Clue from "../../entities/clue";
import Hint from "../../entities/hint";
import {Button} from "react-bootstrap";

const EditClueHints = ({ walkthrough, clue, onWalkthroughChange, onClueChange } ) => {
  const [showEditPopup, setShowEditPopup] = useState(false);
  const hasWalkthrough = !!walkthrough;

  if (!clue) {
    return  null;
  }

  const handleEditOrAdd = (editedValues) => {
    const walkthroughContent = editedValues[appContent.cluemaster.game.clueTab.walkthroughPopup.walkthroughLabel];
    const newWalkthrough = new Hint({
      id: !!walkthrough ? walkthrough.id : String(Date.now()),
      hint: walkthroughContent,
      isWalkthrough: true,
    });

    // Is adding, need to add this to clue
    if (!hasWalkthrough) {
      const newMasterHints = [...clue._rawClueData.masterCluesHints || []]
      newMasterHints.push(newWalkthrough.id);
      onClueChange(new Clue(clue.id,{
        ...clue._rawClueData,
        masterCluesHints: newMasterHints,
      }));
    }

    onWalkthroughChange(newWalkthrough);
    setShowEditPopup(false);
  }

  const handleDelete = () => {
    onWalkthroughChange(null);
    onClueChange(new Clue(clue.id,{
      ...clue._rawClueData,
      masterCluesHints: clue._rawClueData.masterCluesHints.filter(id => id !== walkthrough.id),
    }));
    setShowEditPopup(false);
  }

  return <div className="form-group">
    <div className="form-label">{appContent.cluemaster.game.clueTab.walkthroughLabel}</div>
    <Button onClick={() => setShowEditPopup(true)}>
      {hasWalkthrough ? appContent.cluemaster.game.clueTab.walkthroughEditText : appContent.cluemaster.game.clueTab.walkthroughAddText}
    </Button>
    <InputModal
      show={showEditPopup}
      onHide={() => setShowEditPopup(false)}
      onFinish={handleEditOrAdd}
      title={hasWalkthrough ? appContent.cluemaster.game.clueTab.walkthroughPopup.editTitle : appContent.cluemaster.game.clueTab.walkthroughPopup.addTitle}
      inputs={[
        {
          label: appContent.cluemaster.game.clueTab.walkthroughPopup.walkthroughLabel,
          defaultValue: walkthrough?.hint,
          formControlProps: {
            as: "textarea",
            placeholder: appContent.cluemaster.game.clueTab.walkthroughPopup.walkthroughPlaceholder,
            rows: 5,
            required: true,
          }
        }
      ]}
      buttonText={appContent.cluemaster.game.clueTab.walkthroughPopup.buttonText}
      secondaryFooterSlot={(inputValues) => {
        if (!hasWalkthrough)
          return null
        else
          return <Button variant="outline-danger" onClick={handleDelete}>
            Delete
          </Button>;
      }}
    />
  </div>
};

EditClueHints.defaultProps = {
  hints: [],
}

EditClueHints.propTypes = {
  walkthrough: PropTypes.instanceOf(Hint),
  onWalkthroughChange: PropTypes.func.isRequired,
  onClueChange: PropTypes.func.isRequired,
}

export default EditClueHints;
