import React from "react";
import PropTypes from "prop-types";
import {ButtonWithOverlay} from "../components";
import useCountdown from "./use-countdown";

const CountdownButton = ({countdown, disabled, ...rest}) => {
  const {secondsLeft} = useCountdown(countdown);
  const overlayText = secondsLeft > 0 ? String(secondsLeft) : null;
  const isDisabled = disabled || !!overlayText;

  return <ButtonWithOverlay
    disabled={isDisabled}
    overlayText={overlayText}
    {...rest}
  />;
};

CountdownButton.propTypes = {
  countdown: PropTypes.number,
  disabled: PropTypes.bool,
};

export default CountdownButton;
