import JSONData from "../firebase-config.json";

if (!JSONData?.result?.sdkConfig) {
  throw new Error("Configuration is not set up correctly.")
}

export const firebaseConfig = {
  ...JSONData?.result?.sdkConfig,
};

export const firebaseFunctionsDevelopmentConfig = {
  shouldUseEmulatorLocally: true,
  host: "localhost",
  port: 5001,
}
