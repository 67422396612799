import appContent from "../../markdown/app-content";
import {compareTimestamps, timeCompact} from "../../utilities/dates";
import {compareValues, convertToCSV} from "../../utilities/helper-functions";
import {INCLUDE_ANSWERED_DISABLED_CLUES_IN_LEADERBOARD_TOTAL} from "../../config/config-options";

function getLeaderboardClueFieldOverride(team, game, progress) {
  if (team.isFinished) {
    if (team.getStartTime()) {
      return appContent.cluemaster.game.leaderboard.clueLabel.finished(
        timeCompact(team.getGameTimeInSeconds(game)),
      );
    } else {
      return "Not Started";
    }
  }

  if (!team.isAssignedTrack) {
    return appContent.cluemaster.game.leaderboard.clueLabel.noTrack;
  }

  if (team.isOnInterstitial) {
    return appContent.cluemaster.game.leaderboard.clueLabel.isOnInterstitial;
  }

  if (team.isOnWrapItUp(game)) {
    const {lastAnsweredClue} = team.findProgressForTeam(game)
    if (lastAnsweredClue) {
      return `Wrapped after ${lastAnsweredClue.name} ${progress}`
    } else {
      return `Wrapped immediately ${progress}`;
    }
  }

  return null;
}

export function getPenaltyLeaderboardDataForTeam({game, team}) {
  return appContent.cluemaster.game.leaderboard.formatPenalties({
    penaltyCount: team.getTotalHintsTaken(),
    penaltyTime: team.getPenaltyTimeInSeconds(game),
    bonusCodesCount: team.coinPhrasesFound.length,
    pausedSeconds: team.getPausedForSeconds(),
    adjustmentTime: team.getAdjustmentsTimeInSeconds(game),
    team,
  })
}

export function getUnsortedLeaderboardData(game, teams = []) {
  const progressSeparator = "/";

  // Count enabled clues for each track
  const enabledCluesInTrack = {};
  Object.keys(game?.tracks || {}).forEach(trackId => {
    enabledCluesInTrack[trackId] = game?.getCluesForTrack(
      trackId,
      {filterDisabled: true},
    ).length;
  });

  return teams.map(team => {
    const {unansweredClue, lastAnsweredClue, answeredCount, answeredDisabledClues} = team.findProgressForTeam(game);
    const progressTrackTotal = INCLUDE_ANSWERED_DISABLED_CLUES_IN_LEADERBOARD_TOTAL ?
      enabledCluesInTrack[team.assignedTrackId] + answeredDisabledClues :
      enabledCluesInTrack[team.assignedTrackId];
    const progressAnswers = INCLUDE_ANSWERED_DISABLED_CLUES_IN_LEADERBOARD_TOTAL ?
      answeredCount :
      answeredCount - answeredDisabledClues;
    const progress = `${progressAnswers + 1}${progressSeparator}${progressTrackTotal}`;
    const clueName = (unansweredClue || lastAnsweredClue)?.name;

    return {
      team: team,
      name: team.name,
      clueCSV: getLeaderboardClueFieldOverride(team, game, progress) || `${clueName}`,
      clueUI: getLeaderboardClueFieldOverride(team, game, progress) || `${clueName} ${progress}`,
      penaltyMinutes: getPenaltyLeaderboardDataForTeam({game, team}),
      lastAdvancedTimestamp: team?.getLastAdvancedTimestamp(),
      awaitingFinish: !team.isFinished && !!(lastAnsweredClue && !unansweredClue),
      dataForSorting: {
        answeredCount: answeredCount,
        trackClueCount: progressTrackTotal,
        lastAdvancedSeconds: team?.getLastAdvancedTimestamp()?.seconds,
      },
    };
  });
}

export function getLeaderboardData(game, teams) {
  const unsortedData = getUnsortedLeaderboardData(game, teams);
  return unsortedData.sort((leaderboardDataA, leaderboardDataB) => {
    const teamA = leaderboardDataA.team;
    const teamB = leaderboardDataB.team;
    const teamStatusCompare = teamA.leaderboardStatusSort(game, teamB);

    if (teamStatusCompare !== 0)
      return teamStatusCompare;

    // If teams are finished use game time as a metric
    if (teamA.isFinished) {
      return compareValues(teamB.getGameTimeInSeconds(game), teamA.getGameTimeInSeconds(game));
    }

    // Next sort by progress
    const compareProgress = compareValues(
      leaderboardDataA.dataForSorting.answeredCount / leaderboardDataA.dataForSorting.trackClueCount,
      leaderboardDataB.dataForSorting.answeredCount / leaderboardDataB.dataForSorting.trackClueCount,
    );
    if (compareProgress !== 0) return compareProgress;

    // Next sort by game time
    const compareGameTime = compareValues(teamB.getGameTimeInSeconds(game), teamA.getGameTimeInSeconds(game));
    if (compareGameTime !== 0) return compareGameTime;
    const compareLastAdvanced = compareValues(
      leaderboardDataB.dataForSorting.lastAdvancedSeconds,
      leaderboardDataA.dataForSorting.lastAdvancedSeconds,
    );

    // Next sort by last advanced time
    if (compareLastAdvanced !== 0) return compareLastAdvanced;
    return compareTimestamps(teamA.createdAt, teamB.createdAt);
  });
}

export function getLeaderboardCSVData(game, teams = []) {
  const leaderboardData = getLeaderboardData(game, teams);
  const data = [
    [
      appContent.cluemaster.game.leaderboard.columnLabels.rank,
      appContent.cluemaster.game.leaderboard.columnLabels.name,
      appContent.cluemaster.game.leaderboard.columnLabels.clue,
      appContent.cluemaster.game.leaderboard.columnLabels.hintsTaken,
      appContent.cluemaster.game.leaderboard.columnLabels.hintsTakenTime,
      appContent.cluemaster.game.leaderboard.columnLabels.bonusTaken,
      appContent.cluemaster.game.leaderboard.columnLabels.bonusTakenTime,
      appContent.cluemaster.game.leaderboard.columnLabels.pauseTime,
      appContent.cluemaster.game.leaderboard.columnLabels.excludedClueTime,
      appContent.cluemaster.game.leaderboard.columnLabels.totalAdjustment,
      appContent.cluemaster.game.leaderboard.columnLabels.gameTime,
    ],
    ...leaderboardData.map((teamLeaderboardData, index) => {
    return [
      index + 1,
      teamLeaderboardData.team.name,
      teamLeaderboardData.clueCSV,
      teamLeaderboardData.team.getTotalHintsTaken(),
      timeCompact(teamLeaderboardData.team.getPenaltyTimeInSeconds(game)),
      teamLeaderboardData.team.coinPhrasesFound.length,
      timeCompact(teamLeaderboardData.team.getBonusTimeInSeconds(game)),
      timeCompact(teamLeaderboardData.team.getPausedForSeconds()),
      timeCompact(teamLeaderboardData.team.getExcludedTimesInSeconds(game)),
      timeCompact(teamLeaderboardData.team.getAdjustmentsTimeInSeconds(game)),
      timeCompact(teamLeaderboardData.team.getGameTimeInSeconds(game)),
    ]
    }),
  ];

  return convertToCSV(data);
}

