import React from "react";
import PropTypes from "prop-types";
import {ListGroupItem as BoostrapListGroupItem} from "react-bootstrap";
import "./list-group.scss";
import classNames from "classnames";

const ListGroupItem = ({title, secondaryLabel, footerLabel, children, onClick, style}) => {
  const hasTitle = title !== undefined && title !== null;
  const hasSecondaryLabel = secondaryLabel !== undefined && secondaryLabel !== null;
  const hasFooterLabel = footerLabel !== undefined && footerLabel !== null;

  let className = classNames([
    "list-group__item",
    { "list-group__item--hoverable": !!onClick }
  ]);

  const itemClick = () => {
    if (onClick) {
      onClick();
    }
  }

  return <BoostrapListGroupItem className={className} onClick={itemClick} style={style}>
    {hasSecondaryLabel && <div className="text-13 font-weight-bold">
      {secondaryLabel}
    </div>}
    {hasTitle && <div className="list-group__item__title">
      {title}
    </div> }
    {children}
    {hasFooterLabel && <div className="text-muted float-right">{footerLabel}</div>}
  </BoostrapListGroupItem>;
};

ListGroupItem.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  secondaryLabel: PropTypes.node,
  footerLabel: PropTypes.node,
  onClick: PropTypes.func,
}

export default ListGroupItem;
