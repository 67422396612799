import React, {useCallback, useContext, useState} from "react";
import PropTypes from "prop-types";
import {Button, Col, Modal, Row} from "react-bootstrap";
import Team, {TeamStatus} from "../../entities/team";
import useCurrentClueLogic from "../../hooks/useCurrentClueLogic";
import {finishTeam, undoFinishTeam} from "../../services/firebase";
import {ClueName, Link, TeamPhoneNumbersModal} from "../components";
import {ChevronLeft, ChevronRight} from "react-bootstrap-icons";
import {GameOptions} from "../../entities/game";
import {navigate} from "gatsby";
import {ClueMasterGameTabs} from "../../pages/clue-master/game";
import appContent from "../../markdown/app-content";
import UserDecoration from "../../app/shared/UserDecoration";
import PauseContext from "../../context/pause-context";
import {pauseTeam, unpauseTeam} from "../../services/firestore/teams";
import {AppAlertsContext} from "../../context/app-alerts-context";
import TeamAdjustmentsSummary from "../../app/clue-master/leaderboard/info/TeamAdjustmentsSummary";

const LeaderboardClues = ({clues = [], currentClue, onClickClue}) => {
  return clues.map(clue =>
    <dd key={clue.id}>
      <ClueName
        clue={clue}
        onClick={() => onClickClue(clue)}
        highlight={currentClue.id === clue.id}
      />
    </dd>,
  );
};

const LeaderboardTeamModal = ({game, team, teamRank, readonly, onNext, onPrevious, onHide, show, ...rest}) => {
  const {clue} = useCurrentClueLogic(game, team);
  const [showPhoneNumbersModal, setShowPhoneNumbersModal] = useState(false);
  const {pauseFlashOn} = useContext(PauseContext);
  const {popError, popConfirm} = useContext(AppAlertsContext);

  const clues = (team && game?.getCluesForTrack(team?.assignedTrackId)) || [];
  const showCopyPhoneNumbers = !readonly && game?.options[GameOptions.COLLECT_PHONES];
  const disableFinishTeamButton = team?.findTeamStatus(game) !== TeamStatus.AWAITING_CM_FINISH;
  const rankText = teamRank ? `${teamRank}.` : null;

  const handleClueOnClick = (clue) => {
    if (readonly) {
      return;
    }

    const params = new URLSearchParams({
      gameID: game.id,
      tabId: ClueMasterGameTabs.CLUES,
      clueId: clue.id,
    });
    onHide();
    navigate(`/clue-master/game?${params.toString()}`);
  };

  const handleFinish = () => {
    return popConfirm("Are you sure you want to finish this team?", () => {
      return finishTeam(team)
    });
  };

  const undoFinish = () => {
    return popConfirm("Are you sure you want to undo the finish for this team?", () => {
      return undoFinishTeam(team);
    });
  };

  const handlePause = useCallback(async () => {
    try {
      await pauseTeam({team});
    } catch (e) {
      popError(e.message);
    }
  }, [team]);

  const handleUnpause = useCallback(async () => {
    try {
      await unpauseTeam({team});
    } catch (e) {
      popError(e.message);
    }
  }, [team]);
  return <>
    <Modal
      show={show && !showPhoneNumbersModal}
      onHide={onHide}
      animation={false}
      scrollable={true}
      {...rest}
    >
      <Modal.Header closeButton>
        <Modal.Title className={[
          "w-100",
          {"text-warning": pauseFlashOn && team?.isPaused},
        ]}>
          {rankText} {team?.name}
          <div className="float-right">
            <Button onClick={onPrevious}><ChevronLeft/></Button>
            <Button onClick={onNext}><ChevronRight/></Button>
          </div>
        </Modal.Title>

      </Modal.Header>

      <Modal.Body>
        <Row noGutters>
          <Col xs={6}>
            <dl>
              <dt>{appContent.modals.leaderboardTeam.inviteCodeLabel}</dt>
              <dd>{team?.inviteCode}</dd>
            </dl>
            <dl>
              <dt>{appContent.modals.leaderboardTeam.trackLabel}</dt>
              <dd>{game?.getTrackName(team?.assignedTrackId)}</dd>
            </dl>
          </Col>
          <Col xs={6}>
            <TeamAdjustmentsSummary game={game} team={team}/>
          </Col>
        </Row>
        <dl>
          <dt>{appContent.modals.leaderboardTeam.clueslabel}</dt>
          <LeaderboardClues clues={clues} currentClue={clue} onClickClue={handleClueOnClick}/>
        </dl>
        <Row noGutters>
          <Col xs={6}>
            <dl className="comma-separated-list">
              <dt>
                {appContent.modals.leaderboardTeam.teamMembersLabel}
                {showCopyPhoneNumbers && <Link className="ml-2" onClick={() => setShowPhoneNumbersModal(true)}>
                  {appContent.modals.leaderboardTeam.displayPhoneNumbersButton}
                </Link>}
              </dt>
              {team?.orderedUsers.map(user => <dd key={user.id}>
                <UserDecoration userOrPlayerData={user}>
                  {user.name}
                </UserDecoration>
              </dd>)}
            </dl>
          </Col>
          <Col xs={6}>
            <dl>
              <dt>{appContent.modals.leaderboardTeam.teamID}</dt>
              <dd>{team?.id}</dd>
            </dl>
          </Col>
        </Row>
      </Modal.Body>

      {!readonly && <Modal.Footer>
        {team && (
          <Button type="button" block variant="info" onClick={team.isPaused ? handleUnpause : handlePause}>
            {team.isPaused ?
              appContent.modals.leaderboardTeam.unpauseTeamButton :
              appContent.modals.leaderboardTeam.pauseTeamButton
            }
          </Button>
        )}

        {!team?.isFinished &&
          <Button type="button" block variant="info" onClick={handleFinish} disabled={disableFinishTeamButton}>
            {appContent.modals.leaderboardTeam.finishTeamButton}
          </Button>}
        {team?.isFinished && <Button type="button" block variant="info" onClick={undoFinish}>
          {appContent.modals.leaderboardTeam.undoFinishTeamButton}
        </Button>}
      </Modal.Footer>}
    </Modal>
    <TeamPhoneNumbersModal onHide={() => setShowPhoneNumbersModal(false)} show={showPhoneNumbersModal} team={team}/>
  </>;
};

LeaderboardTeamModal.propTypes = {
  team: PropTypes.instanceOf(Team),
  teamRank: PropTypes.number,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
};

export default LeaderboardTeamModal;
