import React from "react";
import PropTypes from "prop-types";
import {getLeaderboardCSVData} from "../../../services/leaderboard/leaderboard";
import appContent from "../../../markdown/app-content";
import Game from "../../../entities/game";
import Team from "../../../entities/team";
import {Button} from "react-bootstrap";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";

const DownloadLeaderboardDataButton = ({game, teams, ...rest}) => {
  const {copyText} = useCopyToClipboard();

  const downloadCSV = () => {
    copyText(
      getLeaderboardCSVData(game, teams),
      appContent.cluemaster.game.optionsTab.actionsLeaderboardCSVSuccessMessage,
    );
  };

  return <Button
    onClick={downloadCSV}
    {...rest}
  >
    {appContent.cluemaster.game.optionsTab.actionsLeaderboardCSVButtonText}
  </Button>
};

DownloadLeaderboardDataButton.propTypes = {
  game: PropTypes.instanceOf(Game),
  teams: PropTypes.arrayOf(PropTypes.instanceOf(Team)),
};

export default DownloadLeaderboardDataButton;
