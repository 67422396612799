import React from "react";
import PropTypes from "prop-types";
import {Form} from "react-bootstrap";

const FormGroup = ({label, subText, children, ...rest}) => {
  return <Form.Group {...rest}>
    {label && <Form.Label>{label}</Form.Label> }
    {children}
    {subText && <Form.Text className="text-muted">
      {subText}
    </Form.Text>}
  </Form.Group>;
};

FormGroup.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  subText: PropTypes.string,
};

export default FormGroup;
