import React from "react";
import "./page-layout.scss";
import classNames from "classnames";

const PageLayout = (props) => {
  const {children, footer, fullscreen, className} = props;
  let pageLayoutGradientStyle = {};

  return <div
    className={classNames("page-layout", {"page-layout--fullscreen": fullscreen, }, className)}
    style={pageLayoutGradientStyle}
  >
    <main className="page-content page-content--gradient">
      {children}
    </main>
    {footer && (
      <footer className="page-footer">
        <div className="page-footer-content">
          {footer}
        </div>
      </footer>
    )}
  </div>;
};

export default PageLayout;
