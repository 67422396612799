import React from "react";
import PropTypes from "prop-types";
import {Table} from "react-bootstrap";
import AnswerLog from "../../../entities/answerLog";
import appContent from "../../../markdown/app-content";
import {TimeSince} from "../../../components/components";
import Game from "../../../entities/game";
import Team from "../../../entities/team";
import {CheckCircle} from "react-bootstrap-icons";
import classNames from "classnames";

const RecentAnswersTable = ({recentAnswers, game, teams}) => {
  return <Table variant="dark" size="sm">
    <thead>
    <tr>
      <th>
        {appContent.cluemaster.game.recentWrongAnswersTab.table.columnLabels.team}
      </th>
      <th>
        {appContent.cluemaster.game.recentWrongAnswersTab.table.columnLabels.clue}
      </th>
      <th>
        {appContent.cluemaster.game.recentWrongAnswersTab.table.columnLabels.answer}
      </th>
      <th>
        {appContent.cluemaster.game.recentWrongAnswersTab.table.columnLabels.time}
      </th>
    </tr>
    </thead>
    <tbody>
    {recentAnswers.map(answerLog => (
      <tr
        className={classNames([
          {'text-success': answerLog.isCorrect}
        ])}
        key={answerLog.id}
      >
        <td>
          {teams.find(t => t.id === answerLog.teamId)?.name}
        </td>
        <td>
          {game.clues[answerLog.clueId]?.name}
        </td>
        <td>
          {answerLog.answer}
          {answerLog.isCorrect && <CheckCircle className="ml-1"/>}
        </td>
        <td>
          <TimeSince timestamp={answerLog.addedAt}/>
        </td>
      </tr>
    ))}
    </tbody>
  </Table>;
};

RecentAnswersTable.propTypes = {
  recentAnswers: PropTypes.arrayOf(PropTypes.instanceOf(AnswerLog)),
  game: PropTypes.instanceOf(Game),
  teams: PropTypes.arrayOf(PropTypes.instanceOf(Team)),
};

export default RecentAnswersTable;
