import firebaseApp from "../../firebase-app";
import {minutesSinceTimestamp} from "../../../utilities/dates";

export const triggerSendSlackMessageWithWebhook = async ({game, team, clue, lastAdvancedTimestamp}) => {
  const {data} = await firebaseApp.functions().httpsCallable("triggerSendSlackMessageWithWebhook")({
    url: game.liveHints.urlForTeam(team),
    text: `Team ${team?.name} on Puzzle ${clue?.name} have requested a live hint. They have been on the puzzle for ${minutesSinceTimestamp(lastAdvancedTimestamp)} minutes. Please standby for your Game Controller.`,
  });
  return data;
}
