import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import appContent from "../../../markdown/app-content";
import Game, {GameOptions} from "../../../entities/game";
import {InputModal, Link} from "../../../components/components";
import {setGameOption} from "../../../services/firebase";
import firebase from "firebase";
import {AppAlertsContext} from "../../../context/app-alerts-context";

const IntroTextOption = ({game}) => {
  const {popError} = useContext(AppAlertsContext);
  const [isEditing, setIsEditing] = useState(false);

  const handleSetGameOption = async (inputValues) => {
    try {
      const value = inputValues[appContent.modals.editIntroText.label] || firebase.firestore.FieldValue.delete();
      await setGameOption(game, GameOptions.OVERRIDE_INTRO_TEXT, value);
      setIsEditing(false);
    } catch (e) {
      popError(e.message);
    }
  };

  return <>
    <li>
      <span className="mr-2">{appContent.cluemaster.game.optionsTab.optionLabelForCustomIntroText}:</span>
      <Link onClick={() => setIsEditing(true)}>
        {game?.getOption(GameOptions.OVERRIDE_INTRO_TEXT) ? appContent.cluemaster.game.optionsTab.optionCustomIntroTextSet : appContent.cluemaster.game.optionsTab.optionCustomIntroTextUnset}
      </Link>
    </li>
    <InputModal
      show={isEditing}
      onHide={() => setIsEditing(false)}
      onFinish={handleSetGameOption}
      title={appContent.modals.editIntroText.title}
      inputs={[{
        label: appContent.modals.editIntroText.label,
        defaultValue: game?.getOption(GameOptions.OVERRIDE_INTRO_TEXT) || "",
        formControlProps: {
          as: "textarea",
          rows: 12,
          required: false,
          placeholder: appContent.modals.editIntroText.placeholder,
        },
        subText: appContent.modals.editIntroText.subText,
      }]}
      buttonText={appContent.modals.editIntroText.buttonText}
    />
  </>;
};

IntroTextOption.propTypes = {
  game: PropTypes.instanceOf(Game),
};

export default IntroTextOption;
