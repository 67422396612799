import {parseFirstoreTimestamp} from "../utilities/dates";

class AnswerLog {
  constructor(id, answerLogData) {
    this._rawAnswerLogData = answerLogData;
    this.id = id;
    this.addedAt = parseFirstoreTimestamp(answerLogData.addedAt);
    this.answer = answerLogData.answer;
    this.answeredBy = answerLogData.answeredBy;
    this.clueId = answerLogData.clueId;
    this.hasRevealedContent = !!answerLogData.hasRevealedContent;
    this.isCorrect = answerLogData.isCorrect;
    this.teamId = answerLogData.teamId;
    this.usedLink = answerLogData.usedLink || false;
  }
}

export default AnswerLog;
