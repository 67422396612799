import {gameCollection, getCurrentUserID} from "../firebase";
import {newFirestoreTimeStamp} from "../../utilities/dates";

export function updateTrackName(game, trackId, trackName) {
  return gameCollection().doc(game.id).update({
    [`tracks.${trackId}.trackName`]: trackName,
  });
}

export function updateTrackClueOrder(game, trackId, clueIds) {
  return gameCollection().doc(game.id).update({
    [`tracks.${trackId}.clues`]: clueIds,
  });
}

export async function addTrack(game, trackName) {
  const newTrackId = String(Date.now());
  await gameCollection().doc(game.id).update({
    [`tracks.${newTrackId}`]: {
      id: newTrackId,
      trackName,
      clues: [],
    },
  }, {merge: true});
  return newTrackId;
}

export async function duplicateTrack(game, track) {
  const newTrackId = String(Date.now());
  await gameCollection().doc(game.id).update({
    [`tracks.${newTrackId}`]: {
      ...track,
      id: newTrackId,
      trackName: `${track.trackName} copy`,
    },
  }, {merge: true});
  return newTrackId;
}

export async function deleteTrack(game, trackId) {
  const newTrackId = String(Date.now());
  await gameCollection().doc(game.id).update({
    [`tracks.${trackId}.deleted`]: true,
    [`tracks.${trackId}.deletedBy`]: getCurrentUserID(),
    [`tracks.${trackId}.deletedAt`]: newFirestoreTimeStamp(),
  }, {merge: true});
  return newTrackId;
}
