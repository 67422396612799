import React, {useContext} from "react";
import PropTypes from "prop-types";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import Game, {GameOptions} from "../../../entities/game";
import {setGameOption, updateGameData} from "../../../services/firebase";
import {AppAlertsContext} from "../../../context/app-alerts-context";
import BulkAdd from "../../../app/clue-master/options/BulkAdd";
import AdditionalOptions from "../../../app/clue-master/options/AdditionalOptions";
import appContent from "../../../markdown/app-content";
import {FormGroupHeading, Markdown} from "../../../components/components";
import {AlertType} from "../../../components/providers/app-alerts/AppAlerts";
import GameCloseTime from "../../../app/clue-master/options/GameCloseTime";
import CopyPlayerInfo from "../../../app/clue-master/options/CopyPlayerInfo";
import DownloadLeaderboardDataButton from "../../../app/clue-master/options/DownloadLeaderboardDataButton";
import Team from "../../../entities/team";
import CopyPublicLeaderboardLink from "../../../app/clue-master/options/CopyPublicLeaderboardLink";
import EditInterstitialContentButton from "../../../app/clue-master/options/EditInterstitialContentButton";
import EditGameThemeButton from "../../../app/clue-master/options/EditGameThemeButton";
import {navigate} from "gatsby";
import IntroTextOption from "../../../app/clue-master/options/IntroTextOption";
import WrapItUpOption from "../../../app/clue-master/options/WrapItUpOption";
import {CopyClueTimesButton} from "../../../app/clue-master/options/CopyClueTimesButton";
import {EditableGameOption} from "../../../app/clue-master/options/EditableGameOption";
import LiveHintsTemplateButton from "../../../app/clue-master/options/LiveHintsTemplateButton";
import {CopyHintAnalytics} from "../../../app/clue-master/options/CopyHintAnalytics";

export const getOptionsTabRoute = (gameId) => {
  if (!gameId) {
    throw new Error("No game id. Can not navigate to options.");
  }

  return navigate(`/clue-master/game?gameID=${gameId}&tabId=options`)
}

const OptionsTab = ({game, teams}) => {
  const {popConfirm, popError, popAlert} = useContext(AppAlertsContext);

  const handleSetGameOption = (game, option, value) => {
    return setGameOption(game, option, value).catch(e => popError(e.message));
  };

  const handleCheckmarkOption = (option, value) => {
    if (option === GameOptions.IS_PRIMARY && value === true) {
      return popConfirm(appContent.cluemaster.game.optionsTab.confirmMessageMarkdownForPrimaryGame, () => {
        handleSetGameOption(game, GameOptions.IS_PRIMARY, value);
        handleSetGameOption(game, GameOptions.IS_ACTIVE, value);
        updateGameData(game, {hasClosed: false});
      });
    }

    if (option === GameOptions.IS_ACTIVE) {
      if (value === false) {
        return popConfirm(appContent.cluemaster.game.optionsTab.confirmMessageForDeactivatingActiveGames, () => {
          handleSetGameOption(game, GameOptions.IS_PRIMARY, value);
          handleSetGameOption(game, GameOptions.IS_ACTIVE, value);
          updateGameData(game, {hasClosed: true});
        });
      }

      if (value === true && game.hasClosed) {
        updateGameData(game, {hasClosed: false});
      }
    }

    handleSetGameOption(game, option, value);
  };

  const toggleArchiveOption = async () => {
    if (game?.getOption(GameOptions.IS_ARCHIVED)) {
      try {
        await Promise.all([
          setGameOption(game, GameOptions.IS_ARCHIVED, false),
        ]);
        popAlert(appContent.cluemaster.game.optionsTab.successMessageForUnarchiving, AlertType.SUCCESS);
      } catch (e) {
        popError(e.message);
      }
    } else {
      popConfirm(appContent.cluemaster.game.optionsTab.confirmMessageForArchiving, async () => {
        try {
          await Promise.all([
            setGameOption(game, GameOptions.IS_ARCHIVED, true),
            setGameOption(game, GameOptions.IS_PRIMARY, false),
            setGameOption(game, GameOptions.IS_ACTIVE, false),
            updateGameData(game, {hasClosed: true}),
          ]);
          popAlert(appContent.cluemaster.game.optionsTab.successMessageForArchiving, AlertType.SUCCESS);
        } catch (e) {
          popError(e.message);
        }
      });
    }
  };

  return <div>
    <Markdown>
      {appContent.cluemaster.game.optionsTab.markdown}
    </Markdown>

    <FormGroupHeading text="Game Type">
      <p>
        {!!game?.getOption(GameOptions.IS_HOSTED) ?
          "Game is Hosted" :
          "Game is Un-hosted"
        }
      </p>
    </FormGroupHeading>

    <Container className="p-0">
      <Row>
        <Col md={6}>
          <FormGroupHeading
            text={appContent.cluemaster.game.optionsTab.optionGroups.main.title}
            description={appContent.cluemaster.game.optionsTab.optionGroups.main.description}
          >
            <Form className="mb-3">
              <Form.Check
                type="switch"
                id="primary-game-switch"
                label={appContent.cluemaster.game.optionsTab.optionLabelForPrimaryGame}
                checked={!!game?.getOption(GameOptions.IS_PRIMARY)}
                onChange={(event) => {
                  handleCheckmarkOption(GameOptions.IS_PRIMARY, event.target.checked);
                }}
              />
              <Form.Check
                type="switch"
                id="active-game-switch"
                label={appContent.cluemaster.game.optionsTab.optionLabelForActiveGame}
                checked={!!game?.getOption(GameOptions.IS_ACTIVE)}
                onChange={(event) => {
                  handleCheckmarkOption(GameOptions.IS_ACTIVE, event.target.checked);
                }}
              />
              <Form.Check
                type="switch"
                id="shouldCollectEmail-switch"
                label={appContent.cluemaster.game.optionsTab.optionLabelForCollectEmails}
                checked={!!game?.getOption(GameOptions.COLLECT_EMAILS)}
                onChange={(event) => {
                  handleCheckmarkOption(GameOptions.COLLECT_EMAILS, event.target.checked);
                }}
              />
              <Form.Check
                type="switch"
                id="shouldCollectPhoneNumber-switch"
                label={appContent.cluemaster.game.optionsTab.optionLabelForCollectPhoneNumbers}
                checked={!!game?.getOption(GameOptions.COLLECT_PHONES)}
                onChange={(event) => {
                  handleCheckmarkOption(GameOptions.COLLECT_PHONES, event.target.checked);
                }}
              />
              <Form.Check
                type="switch"
                id="recordFinishOnLastQuestion-switch"
                label={appContent.cluemaster.game.optionsTab.optionLabelForRecordFinishOnLastQuestion}
                checked={!!game?.getOption(GameOptions.RECORD_FINISH_ON_LAST_QUESTION)}
                onChange={(event) => {
                  handleCheckmarkOption(GameOptions.RECORD_FINISH_ON_LAST_QUESTION, event.target.checked);
                }}
              />
              <Form.Check
                type="switch"
                id="content-reveal-switch"
                label={appContent.cluemaster.game.optionsTab.optionLabelForContentReveal}
                checked={!!game?.getOption(GameOptions.CONTENT_REVEAL_ENABLED)}
                onChange={(event) => {
                  handleCheckmarkOption(GameOptions.CONTENT_REVEAL_ENABLED, event.target.checked);
                }}
              />
              <ul>
                {/*<AdditionalRequiredFieldOption game={game} />*/}
                <IntroTextOption game={game}/>
                <WrapItUpOption game={game}/>
              </ul>
            </Form>

          </FormGroupHeading>
        </Col>

        <Col md={6}>
          <FormGroupHeading
            text={appContent.cluemaster.game.optionsTab.optionGroups.penalties.title}
            description={appContent.cluemaster.game.optionsTab.optionGroups.penalties.description}
          >
            <AdditionalOptions game={game}/>
          </FormGroupHeading>
        </Col>

        <Col md={6}>
          <FormGroupHeading
            text={appContent.cluemaster.game.optionsTab.optionGroups.secondaryOptions.title}
            description={appContent.cluemaster.game.optionsTab.optionGroups.secondaryOptions.description}
          >
            <Form className="mb-3">
              <Form.Check
                type="switch"
                id="disable-team-name-switch"
                label={appContent.cluemaster.game.optionsTab.optionGroups.secondaryOptions.inputs.disableTeamNameChange.label}
                checked={!!game?.getOption(GameOptions.DISABLE_TEAM_NAME_CHANGE)}
                onChange={(event) => {
                  handleCheckmarkOption(GameOptions.DISABLE_TEAM_NAME_CHANGE, event.target.checked);
                }}
              />
              <Form.Check
                type="switch"
                id="disable-clue-master-contact-switch"
                label={appContent.cluemaster.game.optionsTab.optionGroups.secondaryOptions.inputs.disableCluemasterContact.label}
                checked={!!game?.getOption(GameOptions.DISABLE_CLUEMASTER_CONTACT)}
                onChange={(event) => {
                  handleCheckmarkOption(GameOptions.DISABLE_CLUEMASTER_CONTACT, event.target.checked);
                }}
              />
              <Form.Check
                type="switch"
                id="enable-clue-master-time-limit-switch"
                label={appContent.cluemaster.game.optionsTab.optionGroups.secondaryOptions.inputs.enableTimeLimit.label}
                checked={!!game?.getOption(GameOptions.TIME_LIMIT_ENABLED)}
                onChange={(event) => {
                  handleCheckmarkOption(GameOptions.TIME_LIMIT_ENABLED, event.target.checked);
                }}
              />
              <EditableGameOption
                game={game}
                optionLabel={appContent.cluemaster.game.optionsTab.optionGroups.secondaryOptions.hintLocking.label}
                subText={appContent.cluemaster.game.optionsTab.optionGroups.secondaryOptions.hintLocking.subText}
                options={[
                  {
                    label: appContent.cluemaster.game.optionsTab.optionGroups.secondaryOptions.hintLocking.fields.feature.label,
                    gameOption: GameOptions.HINT_LOCKING_MINUTES,
                    formControlProps: {
                      type: "number",
                      required: false,
                    },
                    mutateValue: (v) => v || null,
                    subText: appContent.cluemaster.game.optionsTab.optionGroups.secondaryOptions.hintLocking.fields.feature.subText
                  },
                  {
                    label: appContent.cluemaster.game.optionsTab.optionGroups.secondaryOptions.hintLocking.fields.hintLockingTakenHint.label,
                    gameOption: GameOptions.HINT_LOCKING_FROM_TAKEN_HINT_MINUTES,
                    formControlProps: {
                      type: "number",
                      required: false,
                    },
                    mutateValue: (v) => v || null,
                    subText: appContent.cluemaster.game.optionsTab.optionGroups.secondaryOptions.hintLocking.fields.hintLockingTakenHint.subText
                  },
                  {
                    label: appContent.cluemaster.game.optionsTab.optionGroups.secondaryOptions.hintLocking.fields.liveHintLock.label,
                    gameOption: GameOptions.HINT_LOCKING_FROM_LIVE_HINT_MINUTES,
                    formControlProps: {
                      type: "number",
                      required: false,
                    },
                    mutateValue: (v) => v || null,
                    subText: appContent.cluemaster.game.optionsTab.optionGroups.secondaryOptions.hintLocking.fields.liveHintLock.subText
                  },
                ]}
              >
                {appContent.cluemaster.game.optionsTab.optionGroups.secondaryOptions.hintLocking.value(
                  game?.getOption(GameOptions.HINT_LOCKING_MINUTES),
                  game?.getOption(GameOptions.HINT_LOCKING_FROM_TAKEN_HINT_MINUTES),
                  game?.getOption(GameOptions.HINT_LOCKING_FROM_LIVE_HINT_MINUTES),
                )}
              </EditableGameOption>
              <EditableGameOption
                game={game}
                optionLabel={appContent.cluemaster.game.optionsTab.optionGroups.secondaryOptions.liveHints.label}
                subText={appContent.cluemaster.game.optionsTab.optionGroups.secondaryOptions.liveHints.subText}
                options={[
                  {
                    label: appContent.cluemaster.game.optionsTab.optionGroups.secondaryOptions.liveHints.fields.enabled.label,
                    gameOption: GameOptions.LIVE_HINTS_ENABLED,
                    formControlProps: {
                      type: "checkbox",
                      required: false,
                    },
                    subText: appContent.cluemaster.game.optionsTab.optionGroups.secondaryOptions.liveHints.fields.enabled.subText
                  },
                  {
                    label: appContent.cluemaster.game.optionsTab.optionGroups.secondaryOptions.liveHints.fields.teamToSlackURL.label,
                    gameOption: GameOptions.LIVE_HINTS_TEAM_TO_SLACK_WEBHOOK_URL,
                    formControlProps: {
                      as: "textarea",
                      required: false,
                    },
                    subText: appContent.cluemaster.game.optionsTab.optionGroups.secondaryOptions.liveHints.fields.teamToSlackURL.subText
                  },
                ]}
                secondaryFooterSlot={() => <LiveHintsTemplateButton teams={teams}>Copy Template With Current Teams</LiveHintsTemplateButton>}
              >
                {appContent.cluemaster.game.optionsTab.optionGroups.secondaryOptions.liveHints.value(
                  game?.getOption(GameOptions.LIVE_HINTS_ENABLED),
                  game?.getOption(GameOptions.LIVE_HINTS_TEAM_TO_SLACK_WEBHOOK_URL),
                )}
              </EditableGameOption>

            </Form>
          </FormGroupHeading>
        </Col>

        <Col md={6}>
          <FormGroupHeading
            text={appContent.cluemaster.game.optionsTab.optionGroups.closeOptions.title}
            description={appContent.cluemaster.game.optionsTab.optionGroups.closeOptions.description}
          >
            <GameCloseTime game={game}/>
          </FormGroupHeading>
        </Col>
      </Row>
    </Container>


    {/*Game Action Buttons*/}
    <h2>{appContent.cluemaster.game.optionsTab.actionsTitle}</h2>
    <div className="mb-3">
      <BulkAdd className="mb-2 mr-2" game={game}>{appContent.cluemaster.game.optionsTab.actionsBulkAddText}</BulkAdd>
      <Button className="mb-2 mr-2" onClick={toggleArchiveOption}>
        {game?.getOption(GameOptions.IS_ARCHIVED) ?
          appContent.cluemaster.game.optionsTab.unarchiveButtonText :
          appContent.cluemaster.game.optionsTab.archiveButtonText
        }
      </Button>
      <EditGameThemeButton className="mb-2 mr-2" game={game}/>
      <CopyPublicLeaderboardLink className="mb-2 mr-2" game={game}/>
    </div>

    <h2>{appContent.cluemaster.game.optionsTab.analyticsTitle}</h2>
    <div className="mb-3">
      <EditInterstitialContentButton className="mb-2 mr-2" game={game}/>
      <DownloadLeaderboardDataButton className="mb-2 mr-2" game={game} teams={teams}/>
      <CopyPlayerInfo game={game} className="mb-2 mr-2"/>
      <CopyClueTimesButton game={game} className="mb-2 mr-2"/>
      <CopyHintAnalytics game={game} className="mb-2 mr-2"/>
    </div>

  </div>;
};

OptionsTab.propTypes = {
  game: PropTypes.instanceOf(Game),
  teams: PropTypes.arrayOf(PropTypes.instanceOf(Team)),
};

export default OptionsTab;
