import React from "react";
import PropTypes from "prop-types";
import Clue from "../../../entities/clue";
import Team from "../../../entities/team";
import {Container, Row, Col, Form} from "react-bootstrap";
import {FormGroup} from "../../../components/components";
import appContent from "../../../markdown/app-content";

const RecentAnswersControls = ({clues, selectedClue, onClueChange, teams, onTeamChange, selectedTeam}) => {

  const handleClueSelectChange = (event) => {
    const clueId = event.target.value;
    onClueChange(clueId);
  };

  const handleTeamSelectChange = (event) => {
    const teamId = event.target.value;
    onTeamChange(teamId);
  };

  return <Container className="no-max-width">
    <Row className="justify-content-md-end">
      <Col xs={6} lg={4}>
        <FormGroup
          controlId="recentAnswer.Clue"
          label={appContent.cluemaster.game.recentWrongAnswersTab.table.filters.clueLabel}
        >
          <Form.Control
            as="select"
            onChange={handleClueSelectChange}
            value={selectedClue}
            size="sm"
          >
            <option value="">{appContent.cluemaster.game.recentWrongAnswersTab.table.filters.nonSelected}</option>
            {clues?.map(clue => (
              <option key={clue.id} value={clue.id}>
                {clue.name}
              </option>
            ))}
          </Form.Control>
        </FormGroup>
      </Col>
      <Col xs={6} lg={4}>
        <FormGroup
          controlId="recentAnswer.Team"
          label={appContent.cluemaster.game.recentWrongAnswersTab.table.filters.teamLabel}
        >
          <Form.Control
            as="select"
            onChange={handleTeamSelectChange}
            value={selectedTeam}
            size="sm"
          >
            <option value="">{appContent.cluemaster.game.recentWrongAnswersTab.table.filters.nonSelected}</option>
            {teams?.map(t => (
              <option key={t.id} value={t.id}>
                {t.name}
              </option>
            ))}
          </Form.Control>
        </FormGroup>
      </Col>
    </Row>
  </Container>;
};

RecentAnswersControls.propTypes = {
  clues: PropTypes.arrayOf(PropTypes.instanceOf(Clue)).isRequired,
  teams: PropTypes.arrayOf(PropTypes.instanceOf(Team)).isRequired,
  onClueChange: PropTypes.func.isRequired,
  onTeamChange: PropTypes.func.isRequired,
  selectedClue: PropTypes.string, // id of clue
  selectedTeam: PropTypes.string, // id of team
};

export default RecentAnswersControls;
