import {useCopyClueTimes} from "../../../hooks/useCopyClueTimes";
import appContent from "../../../markdown/app-content";
import {Button} from "react-bootstrap";
import React from "react";

export const CopyClueTimesButton = ({game, ...rest}) => {
  const copyData = useCopyClueTimes({game});

  return <Button onClick={copyData} {...rest}>
    {appContent.cluemaster.game.optionsTab.actionsCopyClueTimes}
  </Button>
}