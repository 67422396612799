import Clue from "./clue";
import {CLUE_CONTENT_REVEAL_DELIMINATOR} from "../config/config-options";
import {instanceOf} from "prop-types";
import Game from "./game";

class ClueContentReveal {
  constructor(id, contentRevealData = {}) {
    this._raw = contentRevealData;
    this.id = id;
    this.answers = contentRevealData.answers || [];
    this.content = contentRevealData.content;
  }

  checkAnswer(answer) {
    return !!this.answers.find(
      a => Clue.compareAnswers(a, answer)
    );
  }

  getAnswersAsPlainText() {
    let answersCSV = "";
    this.answers.forEach((answer, i) => {
      answersCSV += answer;
      if (i !== this.answers.length - 1) {
        answersCSV += `${CLUE_CONTENT_REVEAL_DELIMINATOR} `;
      }
    })
    return answersCSV;
  }

  getAnswersFromPlainText() {

  }
}

ClueContentReveal.getAnswersAsPlainText = (clueContentReveal) => {
  if (!clueContentReveal instanceof ClueContentReveal) {
    throw new Error("Clue Content Reveal required to getAnswersAsPlainText");
  }

  let answersCSV = "";
  clueContentReveal.answers.forEach((answer, i) => {
    answersCSV += answer;
    if (i !== clueContentReveal.answers.length - 1) {
      answersCSV += `${CLUE_CONTENT_REVEAL_DELIMINATOR} `;
    }
  })
  return answersCSV;
}

ClueContentReveal.getAnswersFromPlainText = (answersText = "") => {
  return answersText
    .replaceAll(`${CLUE_CONTENT_REVEAL_DELIMINATOR} `, CLUE_CONTENT_REVEAL_DELIMINATOR)
    .split(CLUE_CONTENT_REVEAL_DELIMINATOR);
}


export default ClueContentReveal;
