import React, {useState} from "react";
import {ClueEditContext} from "../../../../../context/clue-edit-context";
import ClueContentReveal from "../../../../../entities/clue-content-reveal";
import {getCurrentUserID, updateGameContentReveals} from "../../../../../services/firebase";

/*
  This provider is responsible for saving additional data outside of the clue.
 */
const ClueEditProvider = ({children}) => {
  const [unsavedClueRevealEdits, setUnsavedClueRevealEdits] = useState([]);

  const reset = () => {
    setUnsavedClueRevealEdits([]);
  };

  const save = (game) => {
    return updateGameContentReveals(game, unsavedClueRevealEdits);
  };

  const addClueRevealEdit = (id, {content, answers}) => {
    if (!answers || answers.length < 1) {
      throw new Error("Edited content reveal should have more than one answer.");
    }

    setUnsavedClueRevealEdits(currentUnsavedClueRevealEdits => {
      const unsavedEdits = [...currentUnsavedClueRevealEdits];
      const newEditedItem = new ClueContentReveal(id, {
        answers,
        content,
        id,
      });

      const existingIndex = unsavedEdits.findIndex(ccr => ccr.id === id);
      if (existingIndex === -1) {
        unsavedEdits.push(newEditedItem);
      } else {
        unsavedEdits.splice(existingIndex, 1, newEditedItem);
      }

      return unsavedEdits;
    });
  };

  return <ClueEditContext.Provider value={{
    unsavedClueRevealEdits,
    reset,
    save,
    addClueRevealEdit,
  }}>
    {children}
  </ClueEditContext.Provider>;
};

export default ClueEditProvider;
