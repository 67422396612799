import {
  compareTimestamps,
  convertToDayJs, getMostRecentTimestamp,
  newFirestoreTimeStamp,
  parseFirstoreTimestamp,
  secondsSinceTimestamp, subtractSecondsFromTimestamp,
} from "../utilities/dates";
import {compareValues, mapDictionaryValues} from "../utilities/helper-functions";
import User from "./user";
import {GameOptions} from "./game";
import Penalty from "./penalty";
import Clue from "./clue";

export const TeamStatus = Object.freeze({
  NOT_STARTED: "not-started",
  ON_INTERSTITIAL: "on-interstitial",
  PLAYING: "playing",
  AWAITING_CM_FINISH: "playing-awaiting-clue-master-finish",
  FINISHED: "finished",
  WRITE_TEAM_FINISH: "write-a-team-finish",
  QUIT: "players-quit",
});

class Team {
  constructor(gameId, teamData) {
    this._rawTeamData = teamData;
    this.id = teamData.id;
    this.createdAt = parseFirstoreTimestamp(teamData.createdAt);
    this.gameId = gameId;
    this.quit = !!teamData.quit;
    this.name = this.quit ? `${teamData.name} [Quit]` : teamData.name;
    this.inviteCode = teamData.inviteCode;
    this.isAssignedTrack = !!teamData.assignedTrackId;
    this.assignedTrackId = teamData.assignedTrackId;
    this.assignedTrackAt = parseFirstoreTimestamp(teamData.assignedTrackAt);
    this.clueAnswers = teamData.clueAnswers || [];
    this.clueStartTimes = teamData.clueStartTimes || {};
    this.cluePastDisabled = teamData.cluePastDisabled || {};
    this.pastInterstitial = !!teamData.finishedInterstitialAt;
    this.finishedInterstitialAt = parseFirstoreTimestamp(teamData.finishedInterstitialAt);
    this.manuallyStartedAt = parseFirstoreTimestamp(teamData.manuallyStartedAt);
    this.startTimeManuallySet = !!teamData.manuallyStartedAt;
    this.isHostedGame = !!teamData.isHostedGame;
    this.isOnInterstitial = !this.isHostedGame && !this.pastInterstitial;
    this.orderedUsers = (teamData.usersJoinOrder || []).map(userId => new User(userId, teamData.users[userId]));
    this.users = mapDictionaryValues(teamData.users, (userData, key) => new User(key, userData));
    this.isFinished = teamData.finishedAt;
    this.finishedAt = parseFirstoreTimestamp(teamData.finishedAt);
    this.alerts = teamData.alerts || [];
    this.ignoreDisabledClues = teamData.ignoreDisabledClues || [];
    this.penalties = (teamData.penalties || []).map(penaltyData => new Penalty(penaltyData));
    this.clueContentReveal = teamData.clueContentReveal || {};
    this.coinPhrasesFound = teamData.coinPhrasesFound || [];
    this.pausedAt = parseFirstoreTimestamp(teamData.pausedAt);
    this.pausedForSecondsLogged = teamData.pausedForSecondsLogged || 0;
    this.isPaused = Boolean(this.pausedAt);
    this.hasBeenPaused = Boolean(this.isPaused || this.pausedForSecondsLogged > 0);
    this.pauseHistory = this._formatPauseHistory();
    this.cluePastTimeLimitIds = teamData.cluePastTimeLimitIds || [];
    this.cluePastTimeLimit = teamData.cluePastTimeLimit || {};
  }

  hasAnsweredClue(clueId) {
    return !!this.clueAnswers.find(clueAnswer => clueAnswer.clueId === clueId);
  }

  getStartTime() {
    // If there is a manual start time use that
    if (this.manuallyStartedAt) {
      return this.manuallyStartedAt;
    }
    // For unhosted games the start time is when the players pass the interstitial
    else if (!this.isHostedGame) {
      return this.finishedInterstitialAt;
    }
  }

  getHasStarted() {
    return Boolean(this.getStartTime());
  }

  checkIsClueDisabledForTeam(clue) {
    return !!this.cluePastDisabled[clue.id] || (
      clue.disabled &&
      this.ignoreDisabledClues.indexOf(clue.id) === -1
    );
  }

  getPlayerCount() {
    return this.orderedUsers.length;
  }

  getTeamMemberName(id) {
    return this.users[id]?.name;
  }

  getLastCorrectAnswerTime() {
    const [lastAnswer] = this.clueAnswers.slice(-1);
    return parseFirstoreTimestamp(lastAnswer?.addedAt);
  }

  getMovedOnFromPreviousClueTime() {
    return getMostRecentTimestamp([
      this.getLastCorrectAnswerTime(),
      ...Object.keys(this.cluePastTimeLimit)
      .map(key => parseFirstoreTimestamp(this.cluePastTimeLimit[key].at))
    ]);
  }

  getLastAdvancedTimestamp = (options = {}) => {
    const {mustHaveStarted} = options;
    return (
      this.finishedAt ||
      this.getMovedOnFromPreviousClueTime() ||
      this.getStartTime() ||
      this.assignedTrackAt
    );
  };

  getShouldBePastWaitingRoom = (game) => {
    return (game?.options[GameOptions.IS_HOSTED] && this.isAssignedTrack) ||
      (!game?.options[GameOptions.IS_HOSTED] && this.pastInterstitial);
  };

  findProgressForTeam(game) {
    if (!game?.tracks[this.assignedTrackId]?.clues) {
      return {};
    }

    let unansweredClue = null;
    let lastAnsweredClueId = null;
    let answeredCount = 0;
    let answeredDisabledClues = 0;
    const teamSkippedOverDisabledClues = [];
    const teamSkippedOverCluesDueToTimeLimitHit = [];
    for (let i = 0; i < game.tracks[this.assignedTrackId].clues.length; i++) {
      const clueId = game.tracks[this.assignedTrackId].clues[i];
      if (this.hasAnsweredClue(clueId)) {
        answeredCount++;
        lastAnsweredClueId = clueId;

        // If clue is disabled keep track of it
        if (game.clues[clueId].disabled) {
          answeredDisabledClues++;
        }
      } else if (this.checkIsClueDisabledForTeam(game.getClue(clueId))) {
        teamSkippedOverDisabledClues.push(game.getClue(clueId));
      } else if (this.teamHitTimeLimitOnClue(clueId)) {
        teamSkippedOverCluesDueToTimeLimitHit.push(game.getClue(clueId));
      } else {
        unansweredClue = game.getClue(clueId);
        break;
      }
    }

    return {
      unansweredClue,
      lastAnsweredClue: lastAnsweredClueId && game.getClue(lastAnsweredClueId),
      answeredCount,
      answeredDisabledClues,
      teamSkippedOverDisabledClues,
      teamSkippedOverCluesDueToTimeLimitHit,
    };
  }

  findCurrentClueForTeamFromGame(game) {
    if (!game || !this.assignedTrackId || !game.tracks[this.assignedTrackId]) {
      return null;
    }

    return this.findProgressForTeam(game).unansweredClue || null;
  }

  findTeamStatus(game, options = {}) {
    const {ignoreQuit} = options;
    if (!game) {
      return null;
    }

    const endGameStatus = !game.options[GameOptions.RECORD_FINISH_ON_LAST_QUESTION] ?
      TeamStatus.AWAITING_CM_FINISH :
      TeamStatus.WRITE_TEAM_FINISH;

    if (!ignoreQuit && this.quit) {
      return TeamStatus.QUIT;
    } else if (!this.isAssignedTrack) {
      return TeamStatus.NOT_STARTED;
    } else if (this.isOnInterstitial) {
      return TeamStatus.ON_INTERSTITIAL;
    } else if (this.isFinished) {
      return TeamStatus.FINISHED;
    } else {
      const currentClue = this.findCurrentClueForTeamFromGame(game, this);
      if (currentClue) {
        if (currentClue?.hasAnswers()) {
          return TeamStatus.PLAYING;
        } else {
          return endGameStatus;
        }
      } else {
        return endGameStatus;
      }
    }
  }

  leaderboardStatusSort(game, teamB) {
    const leaderboardStatusOrder = {
      [TeamStatus.FINISHED]: 5,
      [TeamStatus.AWAITING_CM_FINISH]: 4,
      [TeamStatus.PLAYING]: 3,
      [TeamStatus.ON_INTERSTITIAL]: 2,
      [TeamStatus.NOT_STARTED]: 1,
      [TeamStatus.QUIT]: 0,
    };

    const teamAStatus = this.findTeamStatus(game, {ignoreQuit: true});
    const teamBStatus = teamB.findTeamStatus(game, {ignoreQuit: true});

    return compareValues(
      leaderboardStatusOrder[teamAStatus],
      leaderboardStatusOrder[teamBStatus],
    );
  }

  //
  // Archiving. I'm >90% sure this is no longer used
  //
  // getTrackProgress(game) {
  //   return {
  //     clueTotal: game.getCluesForTrack(this.assignedTrackId, {filterDisabled: true})?.length,
  //     answered: this.clueAnswers.filter(clueAnswer => {
  //       return !this.checkIsClueDisabledForTeam(game.getClue(clueAnswer.clueId));
  //     }).length,
  //   };
  // }

  getGameTimeInSeconds(game) {
    const endTime = this.finishedAt || newFirestoreTimeStamp();
    const startTime = this.getStartTime();

    if (startTime) {
      return convertToDayJs(endTime)
      .diff(convertToDayJs(startTime), "seconds") + this.getAdjustmentsTimeInSeconds(game);
    } else {
      return null;
    }
  }

  getRawTimeSpentOnClue({game, clue}) {
    const hasPastDueToClueBeingDisabled = !!this.cluePastDisabled[clue.id];
    if (
      !this.getHasRecordedClueStart(clue)
    ) {
      return 0;
    }

    let timestamp;
    if (hasPastDueToClueBeingDisabled) {
      timestamp = parseFirstoreTimestamp(this.cluePastDisabled[clue.id].at);
    } else if (!this.hasAnsweredClue(clue.id)) {
      timestamp = newFirestoreTimeStamp();
    } else {
      timestamp = parseFirstoreTimestamp(this.clueAnswers.find(clueAnswer => clueAnswer.clueId === clue.id).addedAt);
    }

    return subtractSecondsFromTimestamp(timestamp, this.getRecordedClueStart(clue).seconds).seconds;
  }

  getAdjustmentsTimeInSeconds(game) {
    return this.getPenaltyTimeInSeconds(game) - this.getBonusTimeInSeconds(game) - this.getPausedForSeconds() - this.getExcludedTimesInSeconds(game);
  }

  getExcludedTimesInSeconds(game) {
    let excludedTimeInSeconds = 0;
    game.getAllClues().forEach(clue => {
      if (clue.excludeTimes) {
        excludedTimeInSeconds += this.getRawTimeSpentOnClue({game, clue});
      }
    });
    return excludedTimeInSeconds;
  }

  getPenaltyTimeInSeconds(game) {
    let excludedClues = [];
    game.getAllClues().forEach(clue => {
      if (clue.excludeTimes) {
        excludedClues.push(clue);
      }
    });

    return this.penalties.reduce(
      (totalPenalty, penalty) => {
        if (
          game.hints[penalty.hintId]?.ignorePenalty || // hint is ignored
          excludedClues.find(c => c.id === penalty.clueId) // clue is ignored
        ) {
          return totalPenalty;
        } else {
          return totalPenalty + penalty.penaltyAmount;
        }
      },
      0,
    );
  }

  getBonusTimeInSeconds(game) {
    return this.coinPhrasesFound.length * game.bonusMinutesPerCode * 60;
  }

  getTotalHintsTaken() {
    return this.penalties.length;
  }

  contentRevealedForClue(clueOrClueId) {
    const clueId = clueOrClueId instanceof Clue ? clueOrClueId.id : clueOrClueId;
    return this.clueContentReveal[clueId] || [];
  }

  hasAlreadyRevealedContent(clueOrClueId, contentRevealId) {
    const reveals = this.contentRevealedForClue(clueOrClueId);
    return !!reveals.find(r => {
      return r.contentRevealId === contentRevealId;
    });
  }

  getPausedForSeconds() {
    return this.pausedForSecondsLogged + (this.pausedAt ? secondsSinceTimestamp(this.pausedAt) : 0);
  }

  getHasRecordedClueStart(clueOrClueId) {
    const clueId = clueOrClueId instanceof Clue ? clueOrClueId.id : clueOrClueId;
    return Boolean(this.clueStartTimes[clueId]);
  }

  getRecordedClueStart(clueOrClueId) {
    if (!this.getHasRecordedClueStart(clueOrClueId)) {
      return null;
    }

    const clueId = clueOrClueId instanceof Clue ? clueOrClueId.id : clueOrClueId;
    return parseFirstoreTimestamp(this.clueStartTimes[clueId].at);
  }

  _formatPauseHistory() {
    const pauseHistoryStart = this._rawTeamData.pausedHistoryStart || [];
    const pauseHistoryEnd = this._rawTeamData.pausedHistoryEnd || [];
    return pauseHistoryStart.map((d, index) => {
        return ({
          start: parseFirstoreTimestamp(pauseHistoryStart[index]?.at),
          end: parseFirstoreTimestamp(pauseHistoryEnd[index]?.at),
        });
      },
    );
  }

  isOnWrapItUp(game) {
    const lastAdvancedTimestamp = this.getLastAdvancedTimestamp();
    return (game?.wrapItUpEnabledAt && lastAdvancedTimestamp) &&
      compareTimestamps(game?.wrapItUpEnabledAt, lastAdvancedTimestamp) > 0;
  }

  teamHitTimeLimitOnClue(clueId) {
    return this.cluePastTimeLimitIds.includes(clueId);
  }

}

export default Team;
