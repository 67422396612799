import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import Clue from "../../entities/clue";
import Game from "../../entities/game";
import {InputModal, Link} from "../components";
import appContent from "../../markdown/app-content";
import {AppAlertsContext} from "../../context/app-alerts-context";

const EditTimeLimit = ({className, game, clue, onChange}) => {
  const {popError} = useContext(AppAlertsContext);
  const [showEdit, setShowEdit] = useState(false);

  if (!clue || !game) {
    return null;
  }

  const content = appContent.cluemaster.game.clueTab.timeLimitPopup;
  const label = content.label;

  const handleSetTimeLimit = (data) => {
    try {
      const value = data[label] || null;

      onChange(new Clue(clue.id, {
        ...clue._rawClueData,
        timeLimitMinutes: value,
      }));

      setShowEdit(false);
    } catch (e) {
      popError(e.message);
    }
  };

  return <>
    <div className={className}>
      <span className="mr-2">{label}:</span>
      <Link onClick={() => {
        setShowEdit(true);
      }}>
        {content.value(clue.rawTimeLimitMinutes)}
      </Link>
    </div>
    <InputModal
      show={showEdit}
      onHide={() => setShowEdit(false)}
      onFinish={handleSetTimeLimit}
      title={`Edit ${content.label}`}
      buttonText="Save"
      inputs={[
        {
          label,
          formControlProps: {
            type: "number",
            required: false,
          },
          defaultValue: clue.rawTimeLimitMinutes,
          subText: content.subText,
        }
      ]}
    />
  </>
};

EditTimeLimit.propTypes = {
  className: PropTypes.string,
  clue: PropTypes.instanceOf(Clue),
  game: PropTypes.instanceOf(Game),
  onChange: PropTypes.func.isRequired,
};

export default EditTimeLimit;
