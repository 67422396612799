import React from "react";
import PropTypes from "prop-types";
import Game from "../../../entities/game";
import appContent from "../../../markdown/app-content";
import {Button} from "react-bootstrap";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";

const CopyPublicLeaderboardLink = ({game, ...rest}) => {
  const {copyText} = useCopyToClipboard();

  const copyPublicLink = () => {
    const params = new URLSearchParams({gameId: game.id});
    copyText(
      `${window.location.protocol}//${window.location.host}/readonly-leaderboard?${params.toString()}`,
      appContent.cluemaster.game.optionsTab.actionsPublicLinkSuccessMessage
    );
  };

  return <Button
    onClick={copyPublicLink}
    {...rest}
  >
    {appContent.cluemaster.game.optionsTab.actionsPublicLinkButtonText}
  </Button>;
};

CopyPublicLeaderboardLink.propTypes = {
  game: PropTypes.instanceOf(Game),
};

export default CopyPublicLeaderboardLink;
