import React, {useContext} from "react";
import PropTypes from "prop-types";
import {ClipboardPlus, Files} from "react-bootstrap-icons";
import {Button} from "react-bootstrap";
import Clue from "../../../entities/clue";
import Game from "../../../entities/game";
import {cloneClue} from "../../../services/firestore/clues";
import {AppAlertsContext} from "../../../context/app-alerts-context";
import {AlertType} from "../../../components/providers/app-alerts/AppAlerts";

const DuplicateClueButton = ({ game, clue, ...rest }) => {
  const {popAlert, popError, popConfirm} = useContext(AppAlertsContext);

  const handleClueDuplicate = async () => {
    popConfirm(`Are you sure you want to clone ${clue.name}? This operation will happen immediately.`, async () => {
      try {
        await cloneClue(game, clue)
        popAlert(`Clue, ${clue.name}, cloned successfully`, AlertType.SUCCESS);
      } catch (e) {
        popError(e.message);
      }
    });
  }

  if (!clue) {
    return null;
  } else {
    return <Button variant="dark" onClick={handleClueDuplicate} {...rest}>
      <Files />
    </Button>
  }
}

DuplicateClueButton.propTypes = {
  game: PropTypes.instanceOf(Game),
  clue: PropTypes.instanceOf(Clue),
}

export default DuplicateClueButton;
