import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Button, Form} from "react-bootstrap";
import Game from "../../../entities/game";
import {
  InputModal,
  LoadingSpinner,
} from "../../../components/components";
import Team from "../../../entities/team";
import ClueListReorder from "../../../app/clue-master/track-editor/ClueListReorder";
import {
  addTrack,
  deleteTrack,
  duplicateTrack,
  updateTrackClueOrder,
  updateTrackName,
} from "../../../services/firestore/tracks";
import {AppAlertsContext} from "../../../context/app-alerts-context";
import {AlertType} from "../../../components/providers/app-alerts/AppAlerts";
import UnusedClueList from "../../../app/clue-master/track-editor/UnusedClueList";
import useTrackEditorClues from "../../../hooks/clue-master/use-track-editor-clues";
import PopupFooter from "../../../components/popup-footer/PopupFooter";
import appContent from "../../../markdown/app-content";

const TRACK_INPUT_POPUPS = {
  RENAME: "rename",
  ADD: "add",
};

const TrackEditorTab = ({game, teams, isLoading}) => {
  const [showTrackInputPopups, setShowTrackInputPopups] = useState(null);
  const tracks = game?.getTracks().filter(t => !t.deleted) || [];
  const {clues, unusedClues, lockPoint, track, trackIsEdited, setTrack} = useTrackEditorClues(game, teams);
  const {popAlert, popError, popConfirm} = useContext(AppAlertsContext);

  useEffect(() => {
    if (!isLoading && tracks[0]) {
      setTrack(tracks[0]);
    }
  }, [isLoading]);

  const handleTrackSwitch = (event) => {
    const trackId = event.target.value;
    setTrack(game?.getTrack(trackId));
  };

  const handleSaveTrack = async () => {
    try {
      await updateTrackClueOrder(game, track.id, track.clues);
      popAlert("Track updated successfully", AlertType.SUCCESS);
    } catch (e) {
      popError(e.message);
    }
  };

  const handleResetTrack = () => {
    setTrack(game?.getTrack(track.id));
  };

  const handleTrackCluesChange = (clueIds) => {
    setTrack({
      ...track,
      clues: clueIds,
    });
  };

  const handleClueAdd = (clueId) => {
    handleTrackCluesChange([
      ...clues.map(c => c.id),
      clueId,
    ]);
  };

  const handleTrackAdd = async (inputs) => {
    const newName = inputs[appContent.cluemaster.game.trackEditorTab.renamePopup.label];
    try {
      await addTrack(game, newName);
      setShowTrackInputPopups(null);
      popAlert("Track Added Successfully", AlertType.SUCCESS);
    } catch (e) {
      popError(e.message);
    }
  };

  const handleDelete = () => {
    popConfirm(appContent.cluemaster.game.trackEditorTab.deleteConfirmMessage(track.trackName), () => {
      deleteTrack(game, track.id);
      setTrack(null);
    });
  }

  const handleTrackRename = async (inputs) => {
    const newName = inputs[appContent.cluemaster.game.trackEditorTab.renamePopup.label];
    setShowTrackInputPopups(null);
    setTrack({...track, trackName: newName});
    await updateTrackName(game, track.id, newName);
  };

  const handleDuplicate = async () => {
    try {
      await duplicateTrack(game, track);
      popAlert("Track duplicated successfully", AlertType.SUCCESS)
    } catch (e) {
      popError(e.message);
    }
  }

  const popupData = {
    show: !!showTrackInputPopups,
    content: showTrackInputPopups === TRACK_INPUT_POPUPS.RENAME ?
      appContent.cluemaster.game.trackEditorTab.renamePopup :
      appContent.cluemaster.game.trackEditorTab.addPopup,
    defaultValue: showTrackInputPopups === TRACK_INPUT_POPUPS.RENAME ? game?.getTrack(track?.id)?.trackName : '',
    onFinish: showTrackInputPopups === TRACK_INPUT_POPUPS.RENAME ?
      handleTrackRename :
      handleTrackAdd,
  };

  return <LoadingSpinner isLoading={isLoading} hideChildrenWhenLoading>
    <Form>
      <Form.Group controlId="cluePreview.selectedClue">
        <div className="d-flex">
          <Form.Control
            as="select"
            onChange={handleTrackSwitch}
            value={track?.id}
          >
            <option value=""></option>
            {tracks.map(track => (
              <option key={track.id} value={track.id}>
                {track.trackName}
              </option>
            ))}
          </Form.Control>
          <Button
            className="ml-2"
            onClick={() => setShowTrackInputPopups(TRACK_INPUT_POPUPS.RENAME)}
            disabled={!track}
          >
            {appContent.cluemaster.game.trackEditorTab.renameButtonText}
          </Button>
          <Button
            className="ml-2"
            onClick={() => setShowTrackInputPopups(TRACK_INPUT_POPUPS.ADD)}
          >
            {appContent.cluemaster.game.trackEditorTab.addTrackButtonText}
          </Button>
          <Button
            className="ml-2"
            onClick={handleDuplicate}
          >
            {appContent.cluemaster.game.trackEditorTab.duplicateTrackButton}
          </Button>
          <Button
            className="ml-2"
            onClick={handleDelete}
            disabled={!track}
            variant="danger"
          >
            {appContent.cluemaster.game.trackEditorTab.deleteTrackButtonText}
          </Button>
        </div>
      </Form.Group>
    </Form>
    <ClueListReorder game={game} clues={clues} onChange={handleTrackCluesChange} editableAfterIndex={lockPoint}/>
    <UnusedClueList game={game} clues={unusedClues} onAdd={handleClueAdd}/>
    <PopupFooter show={trackIsEdited}>
      <div className="d-flex justify-content-between align-items-center">
        <div>{appContent.cluemaster.game.trackEditorTab.editedPopup.text}</div>
        <div>
          <Button className="text-no-wrap" variant="danger" onClick={handleResetTrack}>{appContent.cluemaster.game.trackEditorTab.editedPopup.resetButtonText}</Button>
          <Button className="text-no-wrap ml-2" variant="success" onClick={handleSaveTrack}>{appContent.cluemaster.game.trackEditorTab.editedPopup.saveButtonText}</Button>
        </div>
      </div>
    </PopupFooter>
    <InputModal
      show={popupData.show}
      onHide={() => setShowTrackInputPopups(false)}
      onFinish={(inputs) => popupData.onFinish(inputs)}
      title={popupData.content.title}
      inputs={[
        {
          label: popupData.content.label,
          formControlProps: {
            placeholder: popupData.content.placeholder,
            type: "text",
            required: true,
          },
          defaultValue: popupData.defaultValue,
        },
      ]}
      buttonText={popupData.content.buttonText}
    />
  </LoadingSpinner>;
};

TrackEditorTab.propTypes = {
  game: PropTypes.instanceOf(Game),
  teams: PropTypes.arrayOf(PropTypes.instanceOf(Team)),
  isLoading: PropTypes.bool,
};

export default TrackEditorTab;
