import React, {useContext} from "react";
import PropTypes from "prop-types";
import {EditableBadgeList} from "../components";
import Clue from "../../entities/clue";
import appContent from "../../markdown/app-content";
import KnownWrongAnswer from "../../entities/known-wrong-answer";
import {AppAlertsContext} from "../../context/app-alerts-context";

const EditClueWrongAnswers = ({clue, onChange}) => {
  const {popError} = useContext(AppAlertsContext);
  if (!clue) {
    return null;
  }

  const knownAnswerLabels = clue.knownWrongAnswers.map(wa => wa.answer);

  const handleEditOrAdd = (badgeName, editedValues) => {
    let wrongAnswers = [...(clue?._rawClueData.knownWrongAnswers || [])];
    const wrongAnswerText = editedValues[appContent.cluemaster.game.clueTab.knownWrongAnswerPopup.answerLabel];
    const wrongAnswerContent = editedValues[appContent.cluemaster.game.clueTab.knownWrongAnswerPopup.contentLabel];
    const pipedWrongAnswer = KnownWrongAnswer.pipedKnownWrongAnswer(wrongAnswerText, wrongAnswerContent);

    // Add if unique
    if (!badgeName) {
      const existingAnswer = wrongAnswers.find(wa =>
        KnownWrongAnswer.compareAnswers(wrongAnswerText, wa.split("|")[0])
      );

      if (!existingAnswer) {
        wrongAnswers.push(pipedWrongAnswer);
      } else {
        popError(`Wrong answer ${existingAnswer.split("|")[0]} already exists.`);
      }
    }
    // Editing existing
    else {
      wrongAnswers = wrongAnswers.map(wa => {
        if (wa.startsWith(`${badgeName}`))
          return pipedWrongAnswer;
        else
          return wa;
      });
    }
    onChange(
      new Clue(clue.id, {
        ...clue._rawClueData,
        knownWrongAnswers: wrongAnswers,
      }),
    );
  };

  const handleRemove = (badgeName) => {
    const wrongAnswers = [...(clue?._rawClueData.knownWrongAnswers || [])];
    const removeIndex = wrongAnswers.findIndex(wa => wa.includes(badgeName + "|"));
    if (removeIndex >= 0) {
      wrongAnswers.splice(removeIndex, 1);
    }

    onChange(
      new Clue(clue.id, {
        ...clue._rawClueData,
        knownWrongAnswers: wrongAnswers,
      }),
    );
  };

  return <div className="form-group">
    <div className="form-label">{appContent.cluemaster.game.clueTab.wrongAnswersLabel}</div>
    <EditableBadgeList
      badges={knownAnswerLabels}
      variant="danger"
      inputModal={{
        title: (isEditing) => isEditing ? appContent.cluemaster.game.clueTab.knownWrongAnswerPopup.editTitle : appContent.cluemaster.game.clueTab.knownWrongAnswerPopup.addTitle,
        inputs: (editingBadge) => [
          {
            label: appContent.cluemaster.game.clueTab.knownWrongAnswerPopup.answerLabel,
            defaultValue: editingBadge || "",
            formControlProps: {
              placeholder: appContent.cluemaster.game.clueTab.knownWrongAnswerPopup.answerPlaceholder,
              type: "text",
              required: true,
            },
          },
          {
            label: appContent.cluemaster.game.clueTab.knownWrongAnswerPopup.contentLabel,
            defaultValue: clue.knownWrongAnswers.find(wa => wa.answer === editingBadge)?.content || "",
            formControlProps: {
              as: "textarea",
              placeholder: appContent.cluemaster.game.clueTab.knownWrongAnswerPopup.contentPlaceholder,
              required: true,
            },
          },
        ],
        onFinish: handleEditOrAdd,
        buttonText: appContent.cluemaster.game.clueTab.knownWrongAnswerPopup.buttonText,
      }}
      addButtonText={appContent.cluemaster.game.clueTab.wrongAnswersAddText}
      onRemove={handleRemove}
    />
  </div>;
};

EditClueWrongAnswers.propTypes = {
  clue: PropTypes.instanceOf(Clue),
  onChange: PropTypes.func.isRequired,
};

export default EditClueWrongAnswers;
