import React, {useContext} from "react";
import useCurrentClueLogic from "../../../../hooks/useCurrentClueLogic";
import GameContext from "../../../../context/game-context";
import appContent from "../../../../markdown/app-content";
import {Markdown, ListGroup, ListGroupItem} from "../../../../components/components";
import "./clue-hints-taken-view.scss";

const ClueHintsTakenView = () => {
  const {game, team} = useContext(GameContext);
  const {clue, penaltiesFromHints = []} = useCurrentClueLogic(game, team);
  const hints = game?.getHintsForClue(clue) || [];
  const walkthrough = game?.getWalkthroughForClue(clue);
  const hintsAndWalkthrough = !!walkthrough ? [...hints, walkthrough] : hints;

  // Map Penalties to hints and only include ones that have a hint and have been served
  const hintsTaken = penaltiesFromHints.map(penalty => ({
    penalty,
    hint: hintsAndWalkthrough?.find(h => h.id === penalty.hintId),
  })).filter(mappedHintPenalty => mappedHintPenalty.penalty.hintServed && mappedHintPenalty.hint);

  if (hintsTaken && hintsTaken.length > 0) {
    return <>
      <h1 className="h3">{appContent.inGame.hints.hintsSectionTitle}</h1>
      <ListGroup>
        {
          hintsTaken.map(({penalty, hint}) => {
            return <ListGroupItem
              key={penalty.id}
              footerLabel={appContent.inGame.hints.hintsListItemLabel(team.getTeamMemberName(penalty.createdBy))}
            >
              <Markdown>
                {hint.hint}
              </Markdown>
            </ListGroupItem>;
          })
        }
      </ListGroup>
    </>;
  } else {
    return null;
  }
};

export default ClueHintsTakenView;
