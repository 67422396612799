import React, {useCallback, useState} from "react";
import PropTypes from "prop-types"
import { Button, Form, Modal } from "react-bootstrap";
import throttle from "lodash/throttle";
import {TEAM_NAME_CHARACTER_LIMIT, THROTTLE_DOUBLE_CLICK_TIME} from "../../config/config-options";

const TeamNameModal = (props) => {
  const {onFinish, scenario, isLoading, ...rest} = props;
  const [teamName, setTeamName] = useState('');

  const title = scenario === 'rename' ? 'Rename Team' : 'Create Team';

  const handleSubmit = (event) => {
    event.preventDefault();
    sendResult();
  }

  const sendResult = useCallback(
    throttle(() => {
      if (onFinish) {
        onFinish(teamName);
      }
    }, THROTTLE_DOUBLE_CLICK_TIME),
    [teamName],
  );

  return <Modal {...rest}>
    <Form onSubmit={handleSubmit}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <Form.Group>
        <Form.Label>Team Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter team name"
          required
          maxLength={TEAM_NAME_CHARACTER_LIMIT}
          value={teamName}
          onChange={(event) => setTeamName(event.target.value)}
        />
        <Form.Text className="text-muted">
          This name will be editable by you and your teammates while playing the game
        </Form.Text>
      </Form.Group>
    </Modal.Body>

    <Modal.Footer>
      <Button
        type="submit"
        variant="info"
        disabled={isLoading}
      >Submit</Button>
    </Modal.Footer>
    </Form>
  </Modal>
}

TeamNameModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  scenario: PropTypes.oneOf(['create', 'rename']).isRequired,
  isLoading: PropTypes.bool,
}

export default TeamNameModal;
