import React, { useState } from "react";
import PropTypes from "prop-types"
import { Button, Form, Modal } from "react-bootstrap";

const JoinTeamModal = (props) => {
  const {onFinish, isLoading, ...rest} = props;
  const [phrase, setPhrase] = useState('');

  const handleSubmit = event => {
    event.preventDefault();

    if (onFinish) {
      onFinish(phrase)
    }
  }

  return <Modal {...rest}>
    <Form onSubmit={handleSubmit}>
      <Modal.Header closeButton>
        <Modal.Title>Join a Team</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group>
          <Form.Label>Secret Phrase</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your secret phrase"
            required
            value={phrase}
            onChange={(event) => setPhrase(event.target.value)}
          />
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <Button type="submit" variant="info" disabled={isLoading}>Submit</Button>
      </Modal.Footer>
    </Form>
  </Modal>
}

JoinTeamModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default JoinTeamModal;
