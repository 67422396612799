import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import plur from "plur";
import Game, {GameOptions} from "../../../entities/game";
import {
  ClueView,
  EditClueAnswer,
  EditClueFormatting,
  EditClueHints,
  EditClueWrongAnswers,
  LoadingSpinner,
} from "../../../components/components";
import Clue from "../../../entities/clue";
import {updateClue} from "../../../services/firestore/clues";
import {AppAlertsContext} from "../../../context/app-alerts-context";
import appContent from "../../../markdown/app-content";
import {AlertType} from "../../../components/providers/app-alerts/AppAlerts";
import {StringParam, useQueryParam} from "use-query-params";
import Team from "../../../entities/team";
import PopupFooter from "../../../components/popup-footer/PopupFooter";
import RenameClueButton from "../../../app/clue-master/clues/components/RenameClueButton";
import DisableOrReEnableClueButton from "../../../app/clue-master/clues/components/DisableOrReEnableClueButton";
import DuplicateClueButton from "../../../app/clue-master/track-editor/DuplicateClueButton";
import {updateGameHints} from "../../../services/firebase";
import EditClueWalkthrough from "../../../components/clue-master-edit-clue/EditClueWalkthrough";
import NewClueButton from "../../../app/clue-master/clues/components/NewClueButton";
import EditClueRevealContent from "../../../components/clue-master-edit-clue/EditClueRevealContent";
import {ClueEditContext} from "../../../context/clue-edit-context";
import EditExcludeTimesSwitch from "../../../components/clue-master-edit-clue/EditExcludeTimesSwitch";
import EditTimeLimit from "../../../components/clue-master-edit-clue/EditTimeLimit";

const ClueTabUI = ({game, teams, isLoading}) => {
  const [clueIdInQuery] = useQueryParam("clueId", StringParam);
  const [editableClue, setEditableClue] = useState(null);
  const [hasEditedWalkthrough, setHasEditedWalkthrough] = useState(false);
  const [editableWalkthrough, setEditableWalkthrough] = useState(null);
  const [editableHints, setEditableHints] = useState(null);
  const [hasEditedHints, setHasEditedHints] = useState(false);
  const clues = game?.getAllClues() || [];
  const {popAlert, popError} = useContext(AppAlertsContext);
  const {save: saveClueReveal, reset} = useContext(ClueEditContext)
  const teamsOnClue = (
    editableClue && teams
    .map(team => ({
      team,
      clue: team.findCurrentClueForTeamFromGame(game),
    }))
    .filter(({clue}) => clue?.id === editableClue.id)
    .map(({team}) => team) || []
  );
  const hints = editableClue ? game.getHintsForClue(editableClue) : [];
  const walkthrough = editableClue ? game.getWalkthroughForClue(editableClue) : null;

  useEffect(() => {
    // only update hints from db if hints have not been edited
    if (!hasEditedHints) {
      setEditableHints(hints);
    }
  }, [game, editableClue, hasEditedHints]);

  useEffect(() => {
    // only update hints from db if hints have not been edited
    if (!hasEditedWalkthrough) {
      setEditableWalkthrough(walkthrough);
    }
  }, [game, editableClue, hasEditedWalkthrough]);

  useEffect(() => {
    const queryParamClue = game.getClue(clueIdInQuery);
    if (queryParamClue) {
      setEditableClue(queryParamClue);
    }
  }, [clueIdInQuery]);

  const handleClueSwitch = (event) => {
    const clueId = event.target.value;
    setEditableClue(clues.find(c => c.id === clueId));
  };

  const handleResetClue = () => {
    setEditableClue(game.getClue(editableClue.id));
    setEditableHints(game.getHintsForClue(editableClue.id));
    setEditableWalkthrough(game.getWalkthroughForClue(editableClue.id));
    setHasEditedHints(false);
    setHasEditedWalkthrough(false);
    reset();
  };

  const handleClueEdit = (event) => {
    const value = event.target.value;
    const editedClue = new Clue(editableClue.id, {
      ...editableClue._rawClueData,
      content: value,
    });
    editedClue.isAnEditedClue = true;
    setEditableClue(editedClue);
  };

  const handleClueSave = async (event) => {
    try {
      if (hasEditedHints) {
        await updateGameHints(game, editableHints);
      }

      if (hasEditedWalkthrough && editableWalkthrough) {
        await updateGameHints(game, [editableWalkthrough]);
      }

      if (editableClue.isAnEditedClue) {
        await updateClue(game, editableClue);
      }

      await saveClueReveal(game);

      popAlert(
        appContent.cluemaster.game.clueTab.clueSavedMessage(editableClue),
        AlertType.SUCCESS,
      );

      // Reset edited flag on editableClue
      setHasEditedHints(false);
      setHasEditedWalkthrough(false);
      setEditableClue(new Clue(editableClue.id, editableClue._rawClueData));
    } catch (e) {
      popError(e.message);
    }
  };

  const handleClueChange = (clue) => {
    clue.isAnEditedClue = true;
    setEditableClue(clue);
  };

  const handleHintChange = (hints) => {
    setEditableHints(hints);
    setHasEditedHints(true);
  };

  const handleWalkthroughChange = (walkthrough) => {
    setEditableWalkthrough(walkthrough);
    setHasEditedWalkthrough(true);
  };

  return <LoadingSpinner isLoading={isLoading} hideChildrenWhenLoading>
    <Container fluid>
      <Row>
        <Col lg={7}>
          <Form>
            <Form.Group controlId="cluePreview.selectedClue">
              <Form.Label>Clue</Form.Label>
              <div className="d-flex">
                <Form.Control
                  as="select"
                  onChange={handleClueSwitch}
                  value={editableClue?.id}
                >
                  <option value=""></option>
                  {clues.map(clue => (
                    <option key={clue.id} value={clue.id}>
                      {clue.id === editableClue?.id ? editableClue?.name : clue.name}
                    </option>
                  ))}
                </Form.Control>
                <RenameClueButton
                  className="text-no-wrap ml-2"
                  clue={editableClue}
                  game={game}
                  onRename={handleClueChange}
                >
                  {appContent.cluemaster.game.clueTab.renameButtonText}
                </RenameClueButton>
                <DuplicateClueButton
                  className="ml-2"
                  variant="primary"
                  game={game}
                  clue={editableClue}
                  disabled={!!editableClue?.isAnEditedClue}
                />
                <NewClueButton
                  className="ml-2"
                  game={game}
                />
              </div>
            </Form.Group>
          </Form>
        </Col>
        {editableClue && <Col lg={5}>
          <div className="d-flex">
            <Form.Group className="mb-1">
              <div className="form-label">
                {appContent.cluemaster.game.clueTab.teamsOnClueLabel}
              </div>
              <div>
                {teamsOnClue.length} {plur("team", teamsOnClue.length)}
              </div>
            </Form.Group>
            <Form.Group className="ml-2 mb-1">
              <div className="form-label">
                {editableClue.disabled ?
                  appContent.cluemaster.game.clueTab.clueMessageWhenDisabled
                  : appContent.cluemaster.game.clueTab.clueMessageWhenEnabled
                }
              </div>
              <DisableOrReEnableClueButton
                disabled={editableClue?.isAnEditedClue}
                className="text-no-wrap"
                clue={game?.getClue(editableClue?.id)} // pass a non-edited clue
                game={game}
                teamsOnClue={teamsOnClue}
              />
            </Form.Group>
            <EditExcludeTimesSwitch className="ml-2 mb-1" clue={editableClue} onChange={handleClueChange}/>
          </div>
        </Col>}
      </Row>
      <Row>
        <Col lg={7}>
          {game?.getOption(GameOptions.TIME_LIMIT_ENABLED) && (
            <EditTimeLimit className="form-group" game={game} clue={editableClue} onChange={handleClueChange}/>
          )}
          <EditClueAnswer clue={editableClue} onChange={handleClueChange}/>
          {game?.getOption(GameOptions.CONTENT_REVEAL_ENABLED) && (
            <EditClueRevealContent clue={editableClue} game={game} onChange={handleClueChange}/>)}
          <EditClueWrongAnswers clue={editableClue} onChange={handleClueChange}/>
          <EditClueFormatting clue={editableClue} onChange={handleClueChange}/>
          <EditClueHints clue={editableClue} hints={editableHints} onHintChange={handleHintChange}
                         onClueChange={handleClueChange}/>
          <EditClueWalkthrough clue={editableClue} walkthrough={editableWalkthrough}
                               onWalkthroughChange={handleWalkthroughChange} onClueChange={handleClueChange}/>
        </Col>
      </Row>
      <Row>
        <Col lg={7}>
          <Form.Group controlId="clueMasterAlertForm.message">
            <Form.Label>{appContent.cluemaster.game.clueTab.contentLabel}</Form.Label>
            <Form.Control
              as="textarea"
              rows={12}
              value={editableClue?.content || ""}
              onChange={handleClueEdit}
              required
            />
          </Form.Group>
        </Col>
        <Col lg={5}>
          <Form.Label>{appContent.cluemaster.game.clueTab.contentPreviewLabel}</Form.Label>
          <ClueView clue={editableClue}/>
        </Col>
      </Row>
      <PopupFooter show={!!editableClue?.isAnEditedClue || hasEditedHints || hasEditedWalkthrough}>
        <div className="d-flex justify-content-between align-items-center">
          <div>{appContent.cluemaster.game.clueTab.editedPopup.text}</div>
          <div>
            <Button className="text-no-wrap" variant="danger"
                    onClick={handleResetClue}>{appContent.cluemaster.game.clueTab.editedPopup.resetButtonText}</Button>
            <Button className="text-no-wrap ml-2" variant="success"
                    onClick={handleClueSave}>{appContent.cluemaster.game.clueTab.editedPopup.saveButtonText}</Button>
          </div>
        </div>
      </PopupFooter>
    </Container>
  </LoadingSpinner>;
};

ClueTabUI.propTypes = {
  game: PropTypes.instanceOf(Game),
  teams: PropTypes.arrayOf(PropTypes.instanceOf(Team)),
  isLoading: PropTypes.bool,
};

export default ClueTabUI;
