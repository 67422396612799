import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import "firebase/database";
import { firebaseConfig, firebaseFunctionsDevelopmentConfig } from "../firebase-config";
import { isLocalNetwork } from "../utilities/helper-functions";

const isOnClient = typeof window !== 'undefined';

let firebaseApp;
if (isOnClient) {
  firebaseApp = firebase.initializeApp(firebaseConfig);

  // Setup Firebase Functions Emulator if configured
  if (isLocalNetwork() && firebaseFunctionsDevelopmentConfig.shouldUseEmulatorLocally) {
    firebaseApp.functions().useEmulator(
      firebaseFunctionsDevelopmentConfig.host,
      firebaseFunctionsDevelopmentConfig.port,
    );
  }
}

export default firebaseApp;