import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {EditableBadgeList} from "../components";
import Clue from "../../entities/clue";
import appContent from "../../markdown/app-content";
import Game from "../../entities/game";
import CopyClueAdvancedButton from "../../app/clue-master/clues/components/CopyClueAdvancedButton";
import ClueContentReveal from "../../entities/clue-content-reveal";
import {ClueEditContext} from "../../context/clue-edit-context";

const EditClueRevealContent = ({game, clue, onChange}) => {
  const [contentRevealLabels, setContentRevealLabels] = useState([]);
  const [contentRevealItems, setContentRevealItems] = useState({});
  const {addClueRevealEdit, unsavedClueRevealEdits} = useContext(ClueEditContext);

  const popupAnswerLabel = appContent.cluemaster.game.clueTab.contentRevealAnswerPopup.answerLabel;
  const popupContentLabel = appContent.cluemaster.game.clueTab.contentRevealAnswerPopup.contentLabel;

  useEffect(() => {
    const labels = [];
    const items = {};
    game.getContentRevealForClue(clue).forEach(clueContentReveal => {
      const contentRevealEntity = unsavedClueRevealEdits.find(ccr => ccr.id === clueContentReveal.id) || clueContentReveal; // If there's an edit use that instead
      const answers = ClueContentReveal.getAnswersAsPlainText(contentRevealEntity);
      labels.push(answers);
      items[answers] = contentRevealEntity;
    });

    setContentRevealLabels(labels);
    setContentRevealItems(items);
  }, [game, clue, unsavedClueRevealEdits]);


  const handleEditOrAdd = (badgeName, editedValues) => {
    const contentRevealId = contentRevealItems[badgeName]?.id;
    const answers = ClueContentReveal.getAnswersFromPlainText(editedValues[popupAnswerLabel]);
    const content = editedValues[popupContentLabel];

    // Generate a new id for new entries, otherwise use the current one.
    const id = contentRevealId || `new-reveal-${Date.now().toString()}`;
    addClueRevealEdit(id, {
      answers,
      content,
    });
    onChange(
      new Clue(clue.id, {
        ...clue._rawClueData,
        masterCluesContentReveal: Array.from(
          new Set([
            ...(clue._rawClueData.masterCluesContentReveal || []),
            id,
          ])
        ),
      }),
    );
  };

  const handleRemove = (badgeName) => {
    const contentRevealIdFromBadgeName = contentRevealItems[badgeName]?.id;
    // remove that id from the raw data
    const masterCluesContentReveal = (clue._rawClueData.masterCluesContentReveal || []).filter(id => id !== contentRevealIdFromBadgeName);
    onChange(
      new Clue(clue.id, {
        ...clue._rawClueData,
        masterCluesContentReveal,
      }),
    );
  };

  if (!clue || !game) {
    return null;
  } else {
    return <div className="form-group">
      <div className="form-label">{appContent.cluemaster.game.clueTab.contentRevealAnswersLabel}</div>
      <EditableBadgeList
        badges={contentRevealLabels}
        variant="success"
        inputModal={{
          title: (isEditing) => isEditing ? appContent.cluemaster.game.clueTab.contentRevealAnswerPopup.editTitle : appContent.cluemaster.game.clueTab.contentRevealAnswerPopup.addTitle,
          inputs: (editingBadge) => [
            {
              label: popupAnswerLabel,
              defaultValue: editingBadge || "",
              formControlProps: {
                placeholder: appContent.cluemaster.game.clueTab.contentRevealAnswerPopup.answerPlaceholder,
                type: "text",
                required: true,
              },
              subText: appContent.cluemaster.game.clueTab.contentRevealAnswerPopup.answerSubtext,
            },
            {
              label: popupContentLabel,
              defaultValue: contentRevealItems[editingBadge]?.content || "",
              formControlProps: {
                placeholder: appContent.cluemaster.game.clueTab.contentRevealAnswerPopup.contentPlaceholder,
                as: "textarea",
                required: true,
              },
            },
          ],
          onFinish: handleEditOrAdd,
          buttonText: appContent.cluemaster.game.clueTab.contentRevealAnswerPopup.buttonText,
          secondaryFooterSlot: (inputValues) => {
            const answersText = inputValues[popupAnswerLabel];
            if (answersText) {
              const firstAnswer = answersText.split(",")[0];
              return <CopyClueAdvancedButton answerToEncode={firstAnswer}>
                {appContent.cluemaster.game.clueTab.copyAdvancedLink}
              </CopyClueAdvancedButton>;
            }
          },
        }}
        addButtonText={appContent.cluemaster.game.clueTab.contentRevealAddText}
        onRemove={handleRemove}
      />
    </div>;
  }
};

EditClueRevealContent.propTypes = {
  clue: PropTypes.instanceOf(Clue),
  game: PropTypes.instanceOf(Game),
  onChange: PropTypes.func.isRequired,
};

export default EditClueRevealContent;
