import {useContext, useEffect, useState} from "react";
import GameContext from "../context/game-context";
import largeLogo from "../images/logo-large.png";
import horizontalRuleLogo from "../images/logo-horizontal-rule.png";
import {GameImageOverrides} from "../entities/game";

export default function useGameLogo(options = {}) {
  const {useGameOnHomePage, useGame, useDefault} = options;
  const {gameOnHomePage, game} = useContext(GameContext);
  const [bigLogo, setBigLogo] = useState(largeLogo);
  const [smallLogo, setSmallLogo] = useState(horizontalRuleLogo);

  useEffect(() => {
    let referenceGame = null;
    if (!useDefault) {
      referenceGame = useGame || useGameOnHomePage ? gameOnHomePage : game;
    }
    const customImages =  referenceGame?.customImages || {};

    // Set Image Overrides from reference game or use default if there are none set
    setBigLogo(customImages[GameImageOverrides.LARGE_LOGO]?.src || bigLogo);
    setSmallLogo(customImages[GameImageOverrides.SMALL_LOGO]?.src || smallLogo);
  }, [useGame, useGameOnHomePage, gameOnHomePage, game]);

  return {
    bigLogo,
    smallLogo,
  };
}
