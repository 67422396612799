import { parseFirstoreTimestamp } from "../utilities/dates";

class User {
  constructor(id, userData = {}) {
    this.id = id;
    this.name = userData.name;
    this.addedAt = parseFirstoreTimestamp(userData.addedAt);
    this.hasAddedEmail = userData.hasAddedEmail;
    this.hasAddedPhoneNumber = userData.hasAddedPhoneNumber;
    this.leftTeamAt = userData.leftTeamAt ? parseFirstoreTimestamp(userData.leftTeamAt) : null;
  }
}

export default User;
