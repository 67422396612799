import { TeamStatus } from "../entities/team";
import {compareTimestamps, getLocalTimeFromTimestamp} from "../utilities/dates";
import Penalty from "../entities/penalty";

export default function useCurrentClueLogic(game, team) {
  const isLoading = !game || !team;

  const status = team?.findTeamStatus(game);
  const currentUnansweredClue = team?.findCurrentClueForTeamFromGame(game, team);
  const finishedAllClues = [
    TeamStatus.FINISHED,
    TeamStatus.AWAITING_CM_FINISH,
    TeamStatus.WRITE_TEAM_FINISH,
  ].includes(status);
  const lastAdvancedTimestamp = team?.getLastAdvancedTimestamp();
  const showWrapItUp = team?.isOnWrapItUp(game);

  const clue = finishedAllClues && !currentUnansweredClue ?
    // If all clues are answered, should show last clue
    game?.getCluesForTrack(team.assignedTrackId, { filterDisabled: true })?.slice(-1)[0] :
    // If all clues aren't answers, should show current clue
    currentUnansweredClue;

  const penalties = (team?.penalties || [])
    .filter(penalty => penalty.clueId === clue?.id)
    .sort((penaltyA, penaltyB) => compareTimestamps(penaltyB.createdAt, penaltyA.createdAt));

  return {
    clue: clue,
    finishedAllClues,
    lastAdvancedTimestamp,
    showWrapItUp,
    penalties,
    penaltiesFromHints: Penalty.filterNonHints(penalties),
    noAssignedTrack: !isLoading && !team?.assignedTrackId,
    status: status,
  };
}
