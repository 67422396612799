import React from "react";
import PropTypes from "prop-types";
import Clue from "../../entities/clue";
import classNames from "classnames";

const ClueName = ({clue, highlight, onClick}) => {
  return <span
    className={
      classNames([
        { 'text-info': highlight },
        { 'text-strike-through': clue.disabled },
      ])
    }
    onClick={onClick}
  >
    {clue.name}
  </span>
}

ClueName.defaultProps = {
  highlight: false,
  onClick: () => {}, //noop
}

ClueName.propTypes = {
  clue: PropTypes.instanceOf(Clue).isRequired,
  highlight: PropTypes.bool,
  onClick: PropTypes.func,
}

export default ClueName;
