import React from 'react';
import { Nav } from 'react-bootstrap'
import PropTypes from "prop-types"

const NavigationBar = (props) => {
  const { activeID, links = [], ...rest } = props;
  const activeLink = links.find(link => link.id === activeID)

  return <Nav
    activeKey={activeLink && activeLink.link}
    variant="tabs"
    style={{marginBottom: '20px'}}
    {...rest}
  >
    { links.map(link => (
      <Nav.Item key={link.id}>
        <Nav.Link href={link.link} active={activeID === link.id}>{link.text}</Nav.Link>
      </Nav.Item>
    ))}
  </Nav>
};

NavigationBar.propTypes = {
  activeID: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      link: PropTypes.string,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default NavigationBar;