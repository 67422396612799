import firebaseApp from "../../firebase-app";
import appContent from "../../../markdown/app-content";

export const CONTACT_CLUEMASTER_METHODS = {
  TWILIO: "contact-twilio", // archived Dec 2022
  TWILIO_GROUP_US_ZAPIER_INTERNATIONAL: "contact-twilio-group-zapier-fallback",
  ZAPIER: "zapier-webhook",
};

export const twilioTextCluemaster = (args) => {
  const {team, currentClueName, name, phone, message, hintsTaken} = args;

  const sendMessage = appContent.inGame.contactClueMaster.textToCluemasterTwilio({
    name: name,
    teamName: team?.name,
    clueName: currentClueName,
    hints: hintsTaken,
    message,
    phone,
  });

  return firebaseApp.functions().httpsCallable("contactClueMasterForHelp")({
    gameId: team.gameId,
    message: sendMessage,
  });
};

export const bulkTextPlayers = async (args) => {
  const {game, message, phones = [], blastOption} = args;

  const {data} = await firebaseApp.functions().httpsCallable("bulkTextPlayers")({
    gameId: game?.id,
    message: message,
    phones,
    blastOption,
  });

  return {
    realtimeDbStatusPath: data?.realtimeDbStatusPath,
    twilioData: data?.twilioData,
  };
};

export const getAllQueueData = async () => {
  const {data} = await firebaseApp.functions().httpsCallable("getAllQueueData")();
  return data;
};
