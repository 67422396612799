import React, { useEffect, useState } from "react";
import PropTypes from "prop-types"
import {Button, Form, ListGroup, ListGroupItem, Modal} from "react-bootstrap";
import {ChevronDown, ChevronUp} from "react-bootstrap-icons";

const ReorderModal = (props) => {
  const {onFinish, items, title, buttonText, show, ...rest} = props;
  const [reorderItems, setReorderItems] = useState([]);

  useEffect(() => {
    if (show) {
      setReorderItems(items)
    }
  }, [show, items])

  const handleSubmit = event => {
    event.preventDefault();

    if (onFinish) {
      onFinish(reorderItems);
    }
  }

  const handleReorder = (item, direction) => {
    const index = reorderItems.findIndex(i => i.id === item.id);

    if (direction === "up" && index > 0) {
      const itemsCopy = [...reorderItems];
      itemsCopy.splice(index, 1);
      itemsCopy.splice(index - 1, 0, item);
      setReorderItems(itemsCopy)
    }
    else if (direction === "down" && index < reorderItems.length -1) {
      const itemsCopy = [...reorderItems];
      itemsCopy.splice(index, 1);
      itemsCopy.splice(index + 1, 0, item);
      setReorderItems(itemsCopy)
    }
  }

  return <Modal className="input-modal" show={show} scrollable {...rest}>
    <Form onSubmit={handleSubmit}>
      <Modal.Header closeButton>
        <Modal.Title className="input-modal__title">{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ListGroup variant="flush">
          {reorderItems.map(item => (
            <ListGroupItem key={item.id}>
              <div className="d-flex justify-content-between align-items-center">
                <div className="text-overflow-ellipsis">
                  {item.text}
                </div>
                <div className="text-no-wrap">
                  <Button className="reorder-up" variant="light" onClick={() => handleReorder(item, "up")}>
                    <ChevronUp/>
                  </Button>
                  <Button className="reorder-down" variant="light" onClick={() => handleReorder(item, "down")}>
                    <ChevronDown/>
                  </Button>
                </div>
              </div>
            </ListGroupItem>))}
        </ListGroup>
      </Modal.Body>

      <Modal.Footer>
        <Button className="input-modal__submit" type="submit" variant="info">{buttonText}</Button>
      </Modal.Footer>
    </Form>
  </Modal>
}

ReorderModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
  })),
}

export default ReorderModal;
