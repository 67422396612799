import {getCurrentUserID, getTeams, teamCollection, validUserIdSwitchMap} from "../firebase";
import firebase from "firebase/app";
import {docData, collection} from "rxfire/firestore";
import {first, map} from "rxjs/operators";
import PlayerData from "../../entities/playerData";
import Team from "../../entities/team";
import firebaseApp from "../firebase-app";
import {of} from "rxjs";

const collectionName = "playerData";
const playerDataAllCollections = () => firebaseApp.firestore().collectionGroup(collectionName);

function playerDataCollection(teamOrEquivalentIds, docId) {
  let gameId, teamId;
  if (teamOrEquivalentIds instanceof Team) {
    gameId = teamOrEquivalentIds.gameId;
    teamId = teamOrEquivalentIds.id;
  } else {
    gameId = teamOrEquivalentIds.gameId;
    teamId = teamOrEquivalentIds.teamId;
  }

  if (!gameId || !teamId) {
    throw new Error("Missing required args on playerDataCollection");
  }
  const collectionRef = teamCollection(gameId).doc(teamId).collection(collectionName);

  if (docId)
    return collectionRef.doc(docId);
  else
    return collectionRef;
}

export function markPlayerDataAsLeft(team) {
  return playerDataCollection(team, getCurrentUserID()).set({
    leftTeam: true,
  }, {merge: true});
}

export function mostRecentGameAndTeamId() {
  const noDataFoundResponse = {gameId: null, teamId: null};

  return validUserIdSwitchMap(userId => {
    if (!userId)
      return of([noDataFoundResponse]);
    const query = playerDataAllCollections()
      .where("leftTeam", "==", false)
      .where("userId", "==", getCurrentUserID() || null)
      .orderBy("addedAt", "desc")
      .limit(1);

    return collection(
      query,
    ).pipe(
      map(docs => {
        if (docs?.length > 0) {
          const mostRecentPlayerDataDoc = docs[0];
          const gameId = mostRecentPlayerDataDoc.ref.parent.parent.parent.parent.id;
          const teamId = mostRecentPlayerDataDoc.ref.parent.parent.id;
          return {gameId, teamId};
        } else {
          return noDataFoundResponse;
        }
      }),
    );
  });
}

export function getCurrentPlayerData(gameId, teamId) {
  return validUserIdSwitchMap(userId => {
    return docData(
      playerDataCollection({gameId, teamId}, userId),
    ).pipe(
      map(docData => {
        return new PlayerData(userId, docData);
      }),
    );
  });
}

//
// Only authenticated cluemasters are able to get other players data due to security rules
//
export async function getTeamPlayerData(team) {
  const players = await firebaseApp.functions().httpsCallable("getAllPlayerDataFromGame")({
    gameId: team?.gameId,
    teamId: team?.id,
  }).then(r => r.data);

  return players.map(playerData => {
    return new PlayerData(playerData.userId, playerData);
  });
}

//
// Only authenticated cluemasters are able to get other players data due to security rules
//
export async function getGamePlayerData(game) {
  const [players, teams] = await Promise.all([
    firebaseApp.functions().httpsCallable("getAllPlayerDataFromGame")({
      gameId: game?.id,
    }).then(r => r.data),
    getTeams(game?.id).pipe(first()).toPromise(),
  ]);

  const playersToTeamNames = {};
  players.forEach(p => {
    const teamIdFromRef = p.ref?._path?.segments[3];
    const t = teams.find(t => t.id === teamIdFromRef)
    playersToTeamNames[p.id] = t?.name
  })

  return {
    playerData: players.map(playerData => {
      return new PlayerData(playerData.userId, playerData);
    }),
    playersToTeamNames,
  };
}

export function markAlertAsSeen(team, alertId) {
  return playerDataCollection(team).doc(getCurrentUserID()).set({
    seenAlertIds: firebase.firestore.FieldValue.arrayUnion(alertId),
  }, {merge: true});
}
