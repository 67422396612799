import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import "./phone-input.scss";
import intlTelInput from "intl-tel-input";
import intlTelInputUtilsScript from "intl-tel-input/build/js/utils";
import "intl-tel-input/build/css/intlTelInput.css";

const PhoneInput = ({onChange, value}) => {
  const [localValue, setLocalValue] = useState("");
  const inputRef = useRef();
  const itiRef = useRef();

  useEffect(() => {
    const input = inputRef.current;
    const iti = itiRef.current;
    if (iti && input) {
      if (iti.isValidNumber()) {
        input.setCustomValidity("");
        onChange(iti.getNumber());
      } else {
        input.setCustomValidity("Phone number is not formatted correctly.");
        onChange("");
      }
    }
  }, [localValue]);

  useEffect(() => {
    const input = inputRef.current;
    if (!input)
      return;

    const iti = intlTelInput(input, {
      customContainer: "phone-input",
      utilsScript: intlTelInputUtilsScript,
    });
    itiRef.current = iti;

    return () => {
      iti.destroy();
      itiRef.current = null;
    };
  }, []);

  return <div>
    <input
      ref={inputRef}
      id="phone"
      className="phone-input__input"
      type={"tel"}
      onChange={(e) => {
        setLocalValue(e.target.value);
      }}
      value={localValue}
      required={true}
    />
  </div>;
};

PhoneInput.propType = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default PhoneInput;
