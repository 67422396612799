const homeNoActiveGameMarkdown = `
# Greetings, challengers.

You've reached the Great Gotham Challenge Clue Portal App.

There is no game in-session currently, but if you'd like to learn more about our games, head to [The Great Gotham Challenge](https://info.ggc.nyc) for public games, and [Gotham Immersive Laboratories](https://www.gothamimmersive.com) for info about our private, corporate, and bespoke activations.  

We hope to challenge you soon!
`;

export default homeNoActiveGameMarkdown;
