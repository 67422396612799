import {parseFirstoreTimestamp} from "../utilities/dates";
import {readRealtimeDbData} from "../services/realtime-db/realtime-db";

class SmsAlert {
  constructor(data = {}) {
    this.id = data.id;
    this.sids = data.initialTwilioData?.map(d => d.sid) || [];
    this.title = data.title; // title only exists for alerts that were sent in app only, ie. sentInAppOnly
    this.message = data.message;
    this.realtimeDbStatusPath = data.realtimeDbStatusPath;
    this.createdBy = data.createdBy;
    this.createdAt = parseFirstoreTimestamp(data.createdAt);
    this.blastOption = data.blastOption;
    this.sentInAppOnly = !!data.sentInAppOnly;
  }

  getRealtimeStatus() {
    if (this.realtimeDbStatusPath) {
      return readRealtimeDbData(this.realtimeDbStatusPath);
    }
  }
}

export default SmsAlert;
