import React, {useContext, useEffect, useRef, useState} from "react";
import {CrossTabContext} from "../../../context/cross-tab-context";
import {CROSS_TAB_EVENTS, listenForCrossTabEvents, sendCrossTabEvent} from "../../../services/cross-tab-communication/cross-tab-communication";
import GameContext from "../../../context/game-context";
import {CROSS_TAB_DELAY_CHECK_CODE_MS, CROSS_TAB_DELAY_CHECK_TEAM_MS, CROSS_TAB_DELAY_DEFAULT_MS} from "../../../config/config-options";
import useClueAdvanceCheck from "../../../hooks/useClueAdvanceCheck";
import {applyCoinBonus} from "../../../services/firestore/functions/coins";
import appContent from "../../../markdown/app-content";
import {AlertType} from "../app-alerts/AppAlerts";

const CrossTabProvider = ({children}) => {
  const {team} = useContext(GameContext);
  const [eventResponses, setEventResponses] = useState({});
  const eventResponsesRef = useRef();
  const handleAdvanceQuestionCode = useClueAdvanceCheck();

  const handleListeningForEventResponse = (event, onEvent, giveUpDelay = CROSS_TAB_DELAY_DEFAULT_MS) => new Promise((resolve, reject) => {
    // Keep checking for a response from the other tabs
    let interval = setInterval(() => {
      if (eventResponsesRef.current.hasOwnProperty(event)) {
        resolve(onEvent(eventResponsesRef.current[event]));
        setEventResponses(current => Object.keys(current).filter(k => k !== event));
        clearInterval(interval);
        interval = null;
      }
    }, 10);

    setTimeout(() => {
      if (interval)
        clearInterval(interval);

      reject();
    }, giveUpDelay);
  });

  useEffect(() => {
    eventResponsesRef.current = eventResponses;
  }, [eventResponses]);

  const checkTeams = () => {
    sendCrossTabEvent(CROSS_TAB_EVENTS.CHECK_TEAM, Date.now());
    return handleListeningForEventResponse(
      CROSS_TAB_EVENTS.RECEIVED_EVENT_ON_TAB_WITH_TEAM,
      (d) => null,
      CROSS_TAB_DELAY_CHECK_TEAM_MS,
    );
  };

  const checkAdvance = (code) => {
    sendCrossTabEvent(CROSS_TAB_EVENTS.CLUE_ADVANCE, code);
    return handleListeningForEventResponse(
      CROSS_TAB_EVENTS.CLUE_ADVANCE_RESPONSE,
      (response) => response,
    );
  };

  const checkCoin = (code) => {
    sendCrossTabEvent(CROSS_TAB_EVENTS.CHECK_COIN, code);
    return handleListeningForEventResponse(
      CROSS_TAB_EVENTS.CHECK_COIN_RESPONSE,
      (response) => response,
      CROSS_TAB_DELAY_CHECK_CODE_MS,
    );
  };

  useEffect(() => {
    return listenForCrossTabEvents(async (eventKey, eventValue) => {
      if (
        team &&
        eventKey !== CROSS_TAB_EVENTS.RECEIVED_EVENT_ON_TAB_WITH_TEAM
      ) {
        sendCrossTabEvent(CROSS_TAB_EVENTS.RECEIVED_EVENT_ON_TAB_WITH_TEAM, Date.now());

        if (eventKey === CROSS_TAB_EVENTS.CLUE_ADVANCE) {
          const response = await handleAdvanceQuestionCode(eventValue)
          sendCrossTabEvent(CROSS_TAB_EVENTS.CLUE_ADVANCE_RESPONSE, response);
        }

        if (eventKey === CROSS_TAB_EVENTS.CHECK_COIN) {
          try {
            await applyCoinBonus({team, phrase: eventValue});
            sendCrossTabEvent(CROSS_TAB_EVENTS.CHECK_COIN_RESPONSE, "success");
          } catch (e) {
            sendCrossTabEvent(CROSS_TAB_EVENTS.CHECK_COIN_RESPONSE, e.code);
          }
        }
      }

      setEventResponses(current => ({
        ...current,
        [eventKey]: eventValue,
      }));
    });
  }, [team]);

  return <CrossTabContext.Provider value={{
    checkTeams,
    checkAdvance,
    checkCoin,
  }}>
    {children}
  </CrossTabContext.Provider>;
};

export default CrossTabProvider;
