import React from "react";
import propTypes from "prop-types";
import { Button, Table } from "react-bootstrap";
import { PlusCircle } from "react-bootstrap-icons";
import { ClueName } from "../../../components/components";
import Clue from "../../../entities/clue";
import DuplicateClueButton from "./DuplicateClueButton";
import Game from "../../../entities/game";

const UnusedClueList = ({ game, clues, onAdd }) => {
  return <Table bordered variant="dark">
    <tbody>
    {clues.map(clue => {
        return <tr key={clue.id}>
          <td>
            <div className="d-flex justify-content-between align-items-center">
              <ClueName clue={clue} />
              <div>
                <DuplicateClueButton game={game} clue={clue} />
                <Button variant="dark" onClick={() => onAdd(clue.id)}>
                  <PlusCircle/>
                </Button>
              </div>
            </div>
          </td>
        </tr>
      }
    )}
    </tbody>
  </Table>;
}

UnusedClueList.prototype = {
  game: propTypes.instanceOf(Game),
  clues: propTypes.arrayOf(propTypes.instanceOf(Clue)),
  onAdd: propTypes.func.isRequired,
}

export default UnusedClueList;
