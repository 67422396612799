import appContent from "../../../markdown/app-content";
import {Button} from "react-bootstrap";
import React from "react";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import {convertToCSV} from "../../../utilities/helper-functions";
import {getTeams} from "../../../services/firebase";
import {first} from "rxjs/operators";

const useHintAnalytics = ({game}) => {
  const {copyText} = useCopyToClipboard();

  return async () => {
    const teams = await getTeams(game?.id).pipe(first()).toPromise();
    const clues = game.getAllClues();
    const data = [["Puzzle", "Hint Number","# Teams Taken","Hint Question","Hint Text"]];

    clues.forEach((clue) => {
      const hints = game.getHintsForClue(clue.id);
      hints.forEach((hint) => {
        let teamsTaken = 0;
        teams.forEach((team) => {
          const tookPenalty = team.penalties.find((p) => p.hintId === hint.id);
          if (tookPenalty) {
            teamsTaken += 1;
          }
        });

        data.push([
          clue.name,
          hint.isWalkthrough ? "W" : hint.sequence,
          teamsTaken,
          hint.question,
          hint.hint,
        ]);
      });
    });

    return await copyText(convertToCSV(data));
  }
}

export const CopyHintAnalytics = ({game, ...rest}) => {
  const copyData = useHintAnalytics({game});

  return <Button onClick={copyData} {...rest}>
    {appContent.cluemaster.game.optionsTab.actionsCopyHintAnalytics}
  </Button>
}
