import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {Button} from "react-bootstrap";
import appContent from "../../../markdown/app-content";
import {InputModal} from "../../../components/components";
import Game, {GameOptions} from "../../../entities/game";
import {updateGameInterstitial} from "../../../services/firebase";
import {AppAlertsContext} from "../../../context/app-alerts-context";
import {AlertType} from "../../../components/providers/app-alerts/AppAlerts";
import {SHOW_EDIT_INTERSTITIAL_ON_HOSTED_GAMES} from "../../../config/config-options";

const EditInterstitialContentButton = ({game, ...rest}) => {
  const [showModal, setShowModal] = useState(false);
  const {popAlert, popError} = useContext(AppAlertsContext);

  const handleUpdatingGameContent = async (inputs) => {
    const content = inputs[appContent.modals.editInterstitialContent.label];
    try {
      await updateGameInterstitial(game, content);
      popAlert(appContent.modals.editInterstitialContent.successMessage, AlertType.SUCCESS);
      setShowModal(false);
    } catch (e) {
      popError(e.message);
    }
  };

  if (
    !SHOW_EDIT_INTERSTITIAL_ON_HOSTED_GAMES
    && game?.getOption(GameOptions.IS_HOSTED)
  ) {
    return null;
  } else {
    return <>
      <Button
        onClick={() => setShowModal(true)}
        {...rest}
      >
        {appContent.cluemaster.game.optionsTab.actionsEditInterstitialContent}
      </Button>
      <InputModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onFinish={handleUpdatingGameContent}
        title={appContent.modals.editInterstitialContent.title}
        inputs={[{
          label: appContent.modals.editInterstitialContent.label,
          defaultValue: game?.interstitialContent,
          formControlProps: {
            placeholder: appContent.modals.editInterstitialContent.placeholder,
            as: "textarea",
            rows: 5,
            required: true,
          },
        }]}
        buttonText={appContent.modals.editInterstitialContent.buttonText}
      />
    </>;
  }
};

EditInterstitialContentButton.propTypes = {
  game: PropTypes.instanceOf(Game),
};

export default EditInterstitialContentButton;
