import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const FormGroupHeading = ({text, description, children, ...rest}) => {

  return <>
    <div
      className={
        classNames([
          'h3',
          {'mb-1': !!description},
        ])
      }
      {...rest}
    >
      {text}
    </div>
    {description && <p className="text-muted">{description}</p>}
    {children}
  </>;
};

FormGroupHeading.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  description: PropTypes.string,
};

export default FormGroupHeading;
