import Clue from "./clue";
import {compareValues, getValuesFromDictionary} from "../utilities/helper-functions";
import Hint from "./hint";
import {
  DEFAULT_BONUS_TIME_MINUTES,
  DEFAULT_HINT_NUDGE_TIME_MINUTES,
  DEFAULT_HINT_PENALTY_MINUTES_BASE,
  DEFAULT_HINT_PENALTY_MINUTES_MINIMUM,
  DEFAULT_WALKTHROUGH_PENALTY_MINUTES_BASE,
  DEFAULT_WALKTHROUGH_PENALTY_MINUTES_MINIMUM,
} from "../config/config-options";
import {minutesSinceTimestamp, parseFirstoreTimestamp} from "../utilities/dates";
import ClueContentReveal from "./clue-content-reveal";

export const GameOptions = {
  IS_PRIMARY: "isPrimaryGame",
  IS_ACTIVE: "isActiveGame",
  IS_HOSTED: "isHostedByClueMaster",
  IS_ARCHIVED: "isArchived",
  COLLECT_EMAILS: "colleSctEmails",
  COLLECT_PHONES: "collectPhoneNumbers",
  RECORD_FINISH_ON_LAST_QUESTION: "recordFinishOnLastQuestion",
  HINT_PENALTY_BASE: "hintPenaltyBase",
  HINT_PENALTY_MINIMUM: "hintPenaltyMin",
  WALKTHROUGH_PENALTY_BASE: "walkthroughPenaltyBase",
  WALKTHROUGH_PENALTY_MIN: "walkthroughPenaltyMin",
  HINT_NUDGE_AFTER_MINUTES: "hintNudgeAfterMin",
  GAME_CLOSE_AFTER_TIMESTAMP: "closeGameAfterTimestamp",
  DISABLE_TEAM_NAME_CHANGE: "disableTeamNameChange",
  DISABLE_CLUEMASTER_CONTACT: "disableCluemasterContact",
  TIME_LIMIT_ENABLED: "timeLimitEnabled",
  HINT_LOCKING_MINUTES: "hintLockingMinutes",
  HINT_LOCKING_FROM_TAKEN_HINT_MINUTES: "hintLockingFromTakenHintMinutes",
  HINT_LOCKING_FROM_LIVE_HINT_MINUTES: "hintLockingFromLiveHintMinutes",
  LIVE_HINTS_ENABLED: "liveHintsEnabled",
  LIVE_HINTS_TEAM_TO_SLACK_WEBHOOK_URL: "liveHintsTeamToSlackWebhookUrl",
  CONTENT_REVEAL_ENABLED: "contentRevealEnabled",
  BONUS_TIME_PER_CODE: "bonusTimePerCode",
  REQUIRED_ADDITIONAL_FIELD_NAME: "additionalRequiredFieldName",
  OVERRIDE_INTRO_TEXT: "overrideIntroText",
  WRAP_IT_UP_CONTENT: "wrapItUpContent",
};

export const GameImageOverrides = {
  LARGE_LOGO: "largeLogo",
  SMALL_LOGO: "smallLogo",
};

class Game {
  constructor(gameData) {
    this._rawGameData = gameData;
    this.id = gameData.id;
    this.challengeName = gameData.challengeName;
    this.name = gameData.name;
    this.createdAt = parseFirstoreTimestamp(gameData.createdAt);
    this.lastActivatedAt = parseFirstoreTimestamp(gameData.lastActivatedAt);
    this.interstitialContent = gameData.interstitialContent;
    this.tracks = gameData.tracks;
    this.options = gameData.options || {};
    this.clues = gameData.clues || {};
    this.hints = gameData.hints || {};
    this.hintPenaltyBase = this.getOption(GameOptions.HINT_PENALTY_BASE) || DEFAULT_HINT_PENALTY_MINUTES_BASE;
    this.hintPenaltyMinimum = this.getOption(GameOptions.HINT_PENALTY_MINIMUM) || DEFAULT_HINT_PENALTY_MINUTES_MINIMUM;
    this.walkthroughPenaltyBase = this.getOption(GameOptions.WALKTHROUGH_PENALTY_BASE) || DEFAULT_WALKTHROUGH_PENALTY_MINUTES_BASE;
    this.walkthroughPenaltyMinimum = this.getOption(GameOptions.WALKTHROUGH_PENALTY_MIN) || DEFAULT_WALKTHROUGH_PENALTY_MINUTES_MINIMUM;
    this.hintNudgeAfterMinutes = this.getOption(GameOptions.HINT_NUDGE_AFTER_MINUTES) || DEFAULT_HINT_NUDGE_TIME_MINUTES;
    this.bonusMinutesPerCode = this.getOption(GameOptions.BONUS_TIME_PER_CODE) || DEFAULT_BONUS_TIME_MINUTES;
    this.customHeadContent = gameData?.gameTheme?.customHead?.content || ""; // Used for Custom Game Theme
    this.customImages = gameData?.gameTheme?.customImages || {};
    this.contentReveal = gameData.contentReveal || {};
    this.requiresAdditionalField = Boolean(this.getOption(GameOptions.REQUIRED_ADDITIONAL_FIELD_NAME))
    this.requiredAdditionalFieldName = this.getOption(GameOptions.REQUIRED_ADDITIONAL_FIELD_NAME) || "";
    this.hasClosed = Boolean(gameData.hasClosed);
    this.wrapItUpEnabled = Boolean(gameData.wrapItUpEnabledAt);
    this.wrapItUpEnabledAt = this.wrapItUpEnabled && parseFirstoreTimestamp(gameData.wrapItUpEnabledAt);
    const hintLockingEnabled = Game.processTimeLimit(this.getOption(GameOptions.HINT_LOCKING_MINUTES)) !== null;
    this.hintLocking = {
      enabled: hintLockingEnabled,
      minutes: Game.processTimeLimit(this.getOption(GameOptions.HINT_LOCKING_MINUTES)),
      fromTakenHintMinutes: hintLockingEnabled ? Game.processTimeLimit(this.getOption(GameOptions.HINT_LOCKING_FROM_TAKEN_HINT_MINUTES)) || Game.processTimeLimit(this.getOption(GameOptions.HINT_LOCKING_MINUTES)) : null,
      fromLiveHintMinutes: hintLockingEnabled ? Game.processTimeLimit(this.getOption(GameOptions.HINT_LOCKING_FROM_LIVE_HINT_MINUTES)) || Game.processTimeLimit(this.getOption(GameOptions.HINT_LOCKING_MINUTES)) : null,
    };
    this.liveHints = {
      enabled: Boolean(this.getOption(GameOptions.LIVE_HINTS_ENABLED)),
      urlEnabledForTeam: (team) => {
        if (!team?.id) return false;
        const entries = this.getOption(GameOptions.LIVE_HINTS_TEAM_TO_SLACK_WEBHOOK_URL) || "";
        return Game.processLiveHintSlackToTeamData(entries).map(([team]) => team).includes(String(team?.id));
      },
      urlForTeam: (team) => {
        if (!team?.id) return false;
        const entries = this.getOption(GameOptions.LIVE_HINTS_TEAM_TO_SLACK_WEBHOOK_URL) || "";
        const data = Game.processLiveHintSlackToTeamData(entries).find((d) => d[0] === team.id) || [];

        if (data[1]) return data[1];
        else return null;
      }
    }
  }

  getOption(gameOption) {
    return this.options[gameOption];
  }

  trackNames() {
    const tracksIDs = Object.keys(this.tracks || {});
    const names = [];
    tracksIDs.forEach(trackID => {
      names.push(this.tracks[trackID].trackName);
    });
    return names.sort();
  }

  getTracks() {
    return getValuesFromDictionary((this.tracks)).sort((a, b) => {
      return compareValues(b.trackName, a.trackName);
    });
  }

  getTrack(trackId) {
    return this.tracks[trackId];
  }

  getTrackName(trackId) {
    return this.tracks[trackId]?.trackName;
  }

  getClue(id) {
    if (!this.clues[id]) {
      console.warn(`Clue ${id} not found.`);
      return null;
    }

    return new Clue(id, this.clues[id]);
  }

  getAllClues() {
    return Object.keys(this.clues)
      .map(clueId => new Clue(clueId, this.clues[clueId]))
      .sort((a, b) => {
        return compareValues(b.name, a.name);
      });
  }

  getCluesForTrack(trackId, additionOptions = {}) {
    const {filterDisabled} = additionOptions;
    if (!this.tracks[trackId]) {
      console.warn(`Clues were not found for track ${trackId}.`);
      return null;
    }

    let clues = this.tracks[trackId]?.clues.map(clueId => this.getClue(clueId));

    if (filterDisabled) {
      clues = clues.filter(clue => !clue.disabled);
    }

    return clues;
  }

  getHintsForClue(clueOrClueId) {
    const clue = clueOrClueId instanceof Clue ? clueOrClueId : this.getClue(clueOrClueId);
    const hintIds = clue?.hintIds || [];

    return hintIds
      .map(hintId => new Hint(this.hints[hintId]))
      .filter(hint => hint.id)
      .filter(hint => !hint.isWalkthrough)
      .sort(Hint.sort);
  }

  getContentRevealForClue(clueOrClueId) {
    const clue = clueOrClueId instanceof Clue ? clueOrClueId : this.getClue(clueOrClueId);
    const contentRevealIds = clue?.contentRevealIds || [];
    return contentRevealIds
      .map(id => this.getContentRevealFromId(id))
  }

  getContentRevealFromId(id) {
    return new ClueContentReveal(id, this.contentReveal[id]);
  }

  getWalkthroughForClue(clueOrClueId) {
    const clue = clueOrClueId instanceof Clue ? clueOrClueId : this.getClue(clueOrClueId);
    const hintIds = clue?.hintIds || [];

    return hintIds
      .map(hintId => new Hint(this.hints[hintId]))
      .filter(hint => hint.id)
      .filter(hint => hint.isWalkthrough)
      .sort(Hint.sort)[0];
  }

  getHintPenaltyInMinutes(startedClueOrLastHintTimestamp) {
    if (this.hintLocking.enabled) {
      return 0;
    }

    const minutesOnClue = minutesSinceTimestamp(startedClueOrLastHintTimestamp, 2);
    const calculatedPenalty = this.hintPenaltyBase - minutesOnClue;

    if (calculatedPenalty > this.hintPenaltyBase) {
      return this.hintPenaltyBase
    } else if (calculatedPenalty < this.hintPenaltyMinimum) {
      return this.hintPenaltyMinimum;
    } else {
      return calculatedPenalty;
    }
  }

  getWalkthoughPenaltyInMinutes(startedClueTimestamp) {
    if (this.hintLocking.enabled) {
      return 0;
    }

    const minutesOnClue = minutesSinceTimestamp(startedClueTimestamp, 2);
    const calculatedPenalty = this.walkthroughPenaltyBase - minutesOnClue;
    return calculatedPenalty > this.walkthroughPenaltyMinimum ? calculatedPenalty : this.walkthroughPenaltyMinimum;
  }

  checkAnswerForContentReveal(clueOrClueId, answerText) {
    if (!this.getOption(GameOptions.CONTENT_REVEAL_ENABLED)) {
      return [];
    }

    const revealIdsMatched = [];
    this.getContentRevealForClue(clueOrClueId).forEach(clueContentReveal => {
      if (clueContentReveal.checkAnswer(answerText)) {
        revealIdsMatched.push(clueContentReveal.id);
      }
    });

    return revealIdsMatched;
  }

  static processTimeLimit = (timeLimitValue) => {
    if (timeLimitValue && timeLimitValue !== "0") {
      return Number(timeLimitValue);
    } else {
      return null;
    }
  }

  static processLiveHintSlackToTeamData = (liveHintTeamToSlackWebhookUrlData = '') => {
    return liveHintTeamToSlackWebhookUrlData.split("\n").map(entry => {
      const [teamName, teamId, url] = entry.split(",");
      return [teamId, url];
    });
  }

}



export default Game;
