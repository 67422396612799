import {useCallback, useContext} from "react";
import {AppAlertsContext} from "../context/app-alerts-context";
import {copyToClipboard} from "../utilities/helper-functions";
import {AlertType} from "../components/providers/app-alerts/AppAlerts";
import appContent from "../markdown/app-content";

export default function useCopyToClipboard() {
  const {popAlert, popError} = useContext(AppAlertsContext);

  const copyText = useCallback(async (text, successText = appContent.alerts.defaultCopySuccess) => {
    try {
      await copyToClipboard(text);
      popAlert(successText, AlertType.SUCCESS);
    } catch (e) {
      popError(e.message);
    }
  }, []);

  return {
    copyText,
  }
}
