// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ag-js": () => import("./../../../src/pages/ag.js" /* webpackChunkName: "component---src-pages-ag-js" */),
  "component---src-pages-clue-master-clue-preview-js": () => import("./../../../src/pages/clue-master/clue-preview.js" /* webpackChunkName: "component---src-pages-clue-master-clue-preview-js" */),
  "component---src-pages-clue-master-create-game-js": () => import("./../../../src/pages/clue-master/create-game.js" /* webpackChunkName: "component---src-pages-clue-master-create-game-js" */),
  "component---src-pages-clue-master-game-alert-tab-jsx": () => import("./../../../src/pages/clue-master/game/AlertTab.jsx" /* webpackChunkName: "component---src-pages-clue-master-game-alert-tab-jsx" */),
  "component---src-pages-clue-master-game-clue-tab-jsx": () => import("./../../../src/pages/clue-master/game/ClueTab.jsx" /* webpackChunkName: "component---src-pages-clue-master-game-clue-tab-jsx" */),
  "component---src-pages-clue-master-game-js": () => import("./../../../src/pages/clue-master/game.js" /* webpackChunkName: "component---src-pages-clue-master-game-js" */),
  "component---src-pages-clue-master-game-leaderboard-jsx": () => import("./../../../src/pages/clue-master/game/Leaderboard.jsx" /* webpackChunkName: "component---src-pages-clue-master-game-leaderboard-jsx" */),
  "component---src-pages-clue-master-game-options-tab-jsx": () => import("./../../../src/pages/clue-master/game/OptionsTab.jsx" /* webpackChunkName: "component---src-pages-clue-master-game-options-tab-jsx" */),
  "component---src-pages-clue-master-game-recent-answers-tab-jsx": () => import("./../../../src/pages/clue-master/game/RecentAnswersTab.jsx" /* webpackChunkName: "component---src-pages-clue-master-game-recent-answers-tab-jsx" */),
  "component---src-pages-clue-master-game-start-tab-jsx": () => import("./../../../src/pages/clue-master/game/StartTab.jsx" /* webpackChunkName: "component---src-pages-clue-master-game-start-tab-jsx" */),
  "component---src-pages-clue-master-game-track-editor-tab-jsx": () => import("./../../../src/pages/clue-master/game/TrackEditorTab.jsx" /* webpackChunkName: "component---src-pages-clue-master-game-track-editor-tab-jsx" */),
  "component---src-pages-clue-master-games-js": () => import("./../../../src/pages/clue-master/games.js" /* webpackChunkName: "component---src-pages-clue-master-games-js" */),
  "component---src-pages-clue-master-message-queue-js": () => import("./../../../src/pages/clue-master/message-queue.js" /* webpackChunkName: "component---src-pages-clue-master-message-queue-js" */),
  "component---src-pages-cm-js": () => import("./../../../src/pages/cm.js" /* webpackChunkName: "component---src-pages-cm-js" */),
  "component---src-pages-game-clue-advance-js": () => import("./../../../src/pages/game/clue-advance.js" /* webpackChunkName: "component---src-pages-game-clue-advance-js" */),
  "component---src-pages-game-coin-js": () => import("./../../../src/pages/game/coin.js" /* webpackChunkName: "component---src-pages-game-coin-js" */),
  "component---src-pages-game-contact-cluemaster-js": () => import("./../../../src/pages/game/contact-cluemaster.js" /* webpackChunkName: "component---src-pages-game-contact-cluemaster-js" */),
  "component---src-pages-game-in-game-js": () => import("./../../../src/pages/game/in-game.js" /* webpackChunkName: "component---src-pages-game-in-game-js" */),
  "component---src-pages-game-join-game-js": () => import("./../../../src/pages/game/join-game.js" /* webpackChunkName: "component---src-pages-game-join-game-js" */),
  "component---src-pages-game-phone-email-required-js": () => import("./../../../src/pages/game/phone-email-required.js" /* webpackChunkName: "component---src-pages-game-phone-email-required-js" */),
  "component---src-pages-game-sign-up-js": () => import("./../../../src/pages/game/sign-up.js" /* webpackChunkName: "component---src-pages-game-sign-up-js" */),
  "component---src-pages-game-waiting-area-js": () => import("./../../../src/pages/game/waiting-area.js" /* webpackChunkName: "component---src-pages-game-waiting-area-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-readonly-leaderboard-js": () => import("./../../../src/pages/readonly-leaderboard.js" /* webpackChunkName: "component---src-pages-readonly-leaderboard-js" */)
}

