class Hint {
  constructor(hintData = {}) {
    this._rawHintData = hintData;
    this.id = hintData.id;
    this.question = hintData.question;
    this.hint = hintData.hint;
    this.isWalkthrough = !!hintData.isWalkthrough;
    this.sequence = hintData.sequence;
    this.ignorePenalty = hintData.ignorePenalty || false;
  }

}

Hint.sort = (a, b) => {
  if (a.sequence < b.sequence) return -1;
  if (b.sequence > b.sequence) return 1;
  return 0;
};

export default Hint;
