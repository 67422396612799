import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./fade-contents.scss";

const FadeContents = ({show, children}) => {
  const [hasShown, setHasShown] = useState(false);
  const [contentsShown, setContentsShown] = useState(false);
  const c = classNames([
    "fade-contents",
    {"fade-contents--shown": contentsShown},
  ]);

  useEffect(() => {
    if (show && !hasShown) {
      const timeoutId = setTimeout(() => {
        setContentsShown(show);
      }, 150); // delay initial render so fade takes effect and prevent flickers on screens that redirect

      return () => clearTimeout(timeoutId);
    } else {
      setHasShown(show);
      if (show)
        setHasShown(true);
    }
  }, [show]);

  return <div className={c}>
    {children}
  </div>;
};

FadeContents.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default FadeContents;
