import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import Team from "../../entities/team";
import {Button, Modal} from "react-bootstrap";
import appContent from "../../markdown/app-content";
import {Link, LoadingSpinner} from "../components";
import {getTeamPlayerData} from "../../services/firestore/player-data";
import {AppAlertsContext} from "../../context/app-alerts-context";
import {convertToCSV} from "../../utilities/helper-functions";
import {AlertType} from "../providers/app-alerts/AppAlerts";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import UserDecoration from "../../app/shared/UserDecoration";

const TeamPhoneNumbersModal = ({team, show, onHide, ...rest}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [playerInfo, setPlayerInfo] = useState([]);
  const {popError, popAlert} = useContext(AppAlertsContext);
  const {copyText} = useCopyToClipboard();

  useEffect(() => {
    if (!show || !team) {
      return;
    }

    let isCanceled = false;
    setIsLoading(true);
    getTeamPlayerData(team).then((playerData) => {
      if (!isCanceled) {
        setIsLoading(false);
        setPlayerInfo(playerData);
      }
    }).catch((e) => {
      if (!isCanceled) {
        setIsLoading(false);
        popError(e.message);
      }
    });

    return () => {
      isCanceled = true;
    };
  }, [show, team]);

  const copyIndividualNumber = async (phoneNumber) => {
    await copyText(phoneNumber);
  };

  const copyAllNumbers = async () => {
    const phoneNumbers = [];
    playerInfo.forEach((p) => {
      if (!p.leftTeam && p.phoneNumber) {
        phoneNumbers.push(p.phoneNumber);
      }
    });

    if (phoneNumbers.length > 0) {
      await copyText(convertToCSV([phoneNumbers]));
    } else {
      popAlert(appContent.modals.teamPhoneNumbers.noPhoneNumbersMessage, AlertType.WARNING)
    }
  }

  return <Modal show={show} onHide={onHide} scrollable {...rest}>
    <LoadingSpinner isLoading={isLoading}>
      <Modal.Header closeButton>
        <Modal.Title>
          {appContent.modals.teamPhoneNumbers.title}
        </Modal.Title>

      </Modal.Header>
      <Modal.Body>
        <ul className="mb-0">
          {playerInfo.map(p => (
            <li key={p.id}>
              <UserDecoration className="mr-1" userOrPlayerData={p}>
                {p.name}
              </UserDecoration>
              <Link onClick={() => copyIndividualNumber(p.phoneNumber)}>{p.phoneNumber}</Link>
            </li>
          ))}
        </ul>
      </Modal.Body>

      <Modal.Footer>
        <Button type="button" variant="light" onClick={onHide}>
          {appContent.modals.teamPhoneNumbers.closeButton}
        </Button>
        <Button type="button" variant="info" onClick={copyAllNumbers}>
          {appContent.modals.teamPhoneNumbers.buttonText}
        </Button>
      </Modal.Footer>
    </LoadingSpinner>
  </Modal>;
};

TeamPhoneNumbersModal.propTy2qpes = {
  team: PropTypes.instanceOf(Team),
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
};

export default TeamPhoneNumbersModal;
