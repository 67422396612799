import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Button, Modal} from "react-bootstrap";
import appContent from "../../markdown/app-content";
import {LoadingSpinner} from "../components";
import {getGamePlayerData} from "../../services/firestore/player-data";
import Game, {GameOptions} from "../../entities/game";
import {AppAlertsContext} from "../../context/app-alerts-context";
import {convertToCSV} from "../../utilities/helper-functions";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import PlayerData from "../../entities/playerData";

const AllPlayerDataModal = ({game, show, onHide, ...rest}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [playerInfo, setPlayerInfo] = useState([]);
  const {popError} = useContext(AppAlertsContext);
  const {copyText} = useCopyToClipboard();

  useEffect(() => {
    if (!show) {
      return;
    }

    let isCanceled = false;
    setIsLoading(true);
    getGamePlayerData(game).then(({playerData, playersToTeamNames}) => {
      if (!isCanceled) {
        setIsLoading(false);
        setPlayerInfo(() => {
          const output = [];
          const fields = [
            "Name",
            "Team",
          ];

          if (game.getOption(GameOptions.COLLECT_EMAILS)) {
            fields.push("Email");
          }

          if (game.getOption(GameOptions.COLLECT_PHONES)) {
            fields.push("Phone");
          }

          if (game.getOption(GameOptions.REQUIRED_ADDITIONAL_FIELD_NAME)) {
            fields.push(game.getOption(GameOptions.REQUIRED_ADDITIONAL_FIELD_NAME));
          }

          output.push(fields);

          playerData.map(p => {
            const pOutput = [
              p.name || "",
              playersToTeamNames[p.id] || "",
            ];

            if (game.getOption(GameOptions.COLLECT_EMAILS)) {
              pOutput.push(p.email || "-");
            }

            if (game.getOption(GameOptions.COLLECT_PHONES)) {
              pOutput.push(p.phoneNumber || "-");
            }

            if (game.getOption(GameOptions.REQUIRED_ADDITIONAL_FIELD_NAME)) {
              pOutput.push(
                p.additionalFields[
                  game.getOption(GameOptions.REQUIRED_ADDITIONAL_FIELD_NAME)
                ] || "-"
              );
            }

            output.push(pOutput);
          });

          return output;
        });
      }
    }).catch((e) => {
      if (!isCanceled) {
        setIsLoading(false);
        popError(e.message);
      }
    });

    return () => {
      isCanceled = true;
    };
  }, [show]);

  const handleCopyPlayerInfo = () => {
    copyText(
      convertToCSV(playerInfo),
      appContent.cluemaster.game.optionsTab.successForCopyPlayerDataFromGame(playerInfo.length),
    );
  };

  return <Modal show={show} onHide={onHide} scrollable {...rest}>
    <LoadingSpinner isLoading={isLoading}>
      <Modal.Header closeButton>
        <Modal.Title>{appContent.modals.allPlayerData.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ul>
          {convertToCSV(playerInfo).split("\n").map((info, index) => {
            return <li key={`info-${index}`}>{info}</li>;
          })}
        </ul>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="light" onClick={onHide}>
          {appContent.modals.allPlayerData.closeButton}
        </Button>
        <Button variant="info" onClick={handleCopyPlayerInfo} disabled={isLoading}>
          {appContent.modals.allPlayerData.buttonText}
        </Button>
      </Modal.Footer>

    </LoadingSpinner>
  </Modal>;
};

AllPlayerDataModal.propTypes = {
  game: PropTypes.instanceOf(Game),
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default AllPlayerDataModal;
