import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {Button, Form} from "react-bootstrap";
import Game, {GameOptions} from "../../../entities/game";
import {AppAlertsContext} from "../../../context/app-alerts-context";
import appContent from "../../../markdown/app-content";
import {AlertType} from "../../../components/providers/app-alerts/AppAlerts";
import {sendAlertToTeams} from "../../../services/firestore/teams";
import {getTeamPlayerData} from "../../../services/firestore/player-data";
import {bulkTextPlayers} from "../../../services/firestore/functions/twilio";
import {FormGroup, LoadingSpinner, Markdown} from "../../../components/components";
import SmsHistory from "../../../app/clue-master/alert/SmsHistory";
import {logInAppOnlyAlertInSmsHistory} from "../../../services/firestore/sms-alerts";
import useAlertTeamGroups from "../../../hooks/useAlertTeamGroups";

const BlastTeamOption = {
  ALL: "All",
  UNFINISHED: "Unfinished",
  FINISHED: "Finished",
  CLUE: "On Clue",
};

const AlertTab = ({game, teams = []}) => {
  const {popConfirm, popDismissibleAlert, popAlert, popError} = useContext(AppAlertsContext);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [blastTeamOption, setBlastTeamOption] = useState(null);
  const [sendAsSMS, setSendAsSMS] = useState(false);
  const [isSendingAlert, setIsSendingAlert] = useState(false);
  const {
    unfinishedTeams,
    finishedTeams,
    clues,
    clueTeams,
    selectedClue,
    setSelectedClue,
  } = useAlertTeamGroups(game, teams);
  const smsText = `${title}\n${message}`;

  const handleSubmit = (event) => {
    event.preventDefault();

    let blastTeams;
    if (blastTeamOption === BlastTeamOption.ALL) {
      blastTeams = teams;
    } else if (blastTeamOption === BlastTeamOption.UNFINISHED) {
      blastTeams = unfinishedTeams;
    } else if (blastTeamOption === BlastTeamOption.FINISHED) {
      blastTeams = finishedTeams;
    } else if (blastTeamOption === BlastTeamOption.CLUE) {
      blastTeams = clueTeams;
    }

    const playerCount = blastTeams.reduce((count, team) => team.getPlayerCount() + count, 0);
    if (playerCount === 0) {
      popError("No players will be alerted.");
      return;
    }

    popConfirm(appContent.cluemaster.game.alertTab.confirmMessage(playerCount, game.name), async () => {
      setIsSendingAlert(true);
      try {
        await sendAlertToTeams(blastTeams, {
          title,
          message,
          additionalOptions: {variant: "info"},
        });
        popAlert("In App Alert Sent", AlertType.SUCCESS);
        if (sendAsSMS) {
          const teamsPlayerData = await Promise.all(blastTeams.map(team => getTeamPlayerData(team)));
          const phoneNumbers = [];
          teamsPlayerData.forEach(teamPlayerData => {
            teamPlayerData.forEach(playerData => {
              const phoneNumber = playerData.phoneNumber;
              if (phoneNumber) {
                phoneNumbers.push(phoneNumber);
              }
            });
          });

          if (phoneNumbers.length === 0) {
            popError("No players have entered phone numbers.");
          } else {
            await bulkTextPlayers({
              game,
              message: smsText,
              phones: phoneNumbers,
              blastOption: blastTeamOption,
            });
            popAlert("In App and SMS Alerts Sent", AlertType.SUCCESS);
          }
        } else {
          logInAppOnlyAlertInSmsHistory(game, {
            blastOption: blastTeamOption === BlastTeamOption.CLUE ? selectedClue.name : blastTeamOption,
            title,
            message,
          });
        }
      } catch (e) {
        popError(e.message);
      }
      setIsSendingAlert(false);
    });
  };

  const handleInAppPreview = () => {
    popDismissibleAlert(message, AlertType.INFO, {
      title: title,
      showAsPlainText: true,
      variant: "info",
    });
  };

  const handleClueSwitch = (event) => {
    const clueId = event.target.value;
    setSelectedClue(clues.find(c => c.id === clueId));
  };

  const handleSMSPreview = () => {
    popDismissibleAlert(smsText, AlertType.WARNING, {
      showAsPlainText: true,
    });
  };

  const checkboxOptions = [
    {name: "All", label: appContent.cluemaster.game.alertTab.sendToOptions.all(teams.length)},
    {name: "Unfinished", label: appContent.cluemaster.game.alertTab.sendToOptions.unfinished(unfinishedTeams.length)},
    {name: "Finished", label: appContent.cluemaster.game.alertTab.sendToOptions.finished(finishedTeams.length)},
    {name: "On Clue", label: appContent.cluemaster.game.alertTab.sendToOptions.clue(clueTeams.length)},
  ];

  return <LoadingSpinner isLoading={isSendingAlert}>
    <Markdown>
      {appContent.cluemaster.game.alertTab.markdown}
    </Markdown>

    <Form className="mb-4" onSubmit={handleSubmit}>

      <FormGroup
        controlId="clueMasterAlertForm.messageTitle"
        label={appContent.cluemaster.game.alertTab.titleLabel}
        subText={appContent.cluemaster.game.alertTab.titleSubtext}
      >
        <Form.Control
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </FormGroup>
      <FormGroup
        controlId="clueMasterAlertForm.message"
        label={appContent.cluemaster.game.alertTab.messageLabel}
        subText={appContent.cluemaster.game.alertTab.messageSubtext}
      >
        <Form.Control
          as="textarea"
          rows={3}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
      </FormGroup>
      <FormGroup
        controlId="clueMasterAlertForm.teamsOnClue"
        label={appContent.cluemaster.game.alertTab.clueLabel}
      >
        <Form.Control
          as="select"
          onChange={handleClueSwitch}
          value={selectedClue?.id}
          disabled={blastTeamOption !== BlastTeamOption.CLUE}
        >
          <option value=""></option>
          {clues.map(clue => (
            <option key={clue.id} value={clue.id}>
              {clue.name}
            </option>
          ))}
        </Form.Control>
      </FormGroup>
      <Form.Group controlId="clueMasterAlertForm.whichTeams">
        <Form.Label>{appContent.cluemaster.game.alertTab.sendToLabel}</Form.Label>
        <div>
          {checkboxOptions.map(option => (
            <Form.Check
              key={option.name}
              inline
              type="radio"
              name="teamGroup"
              label={option.label}
              id={option.name}
              onChange={(e) => setBlastTeamOption(option.name)}
              required
            />
          ))}
        </div>
      </Form.Group>
      {game?.getOption(GameOptions.COLLECT_PHONES) && (
        <Form.Group controlId="clueMasterAlertForm.alertType">
          <Form.Check
            type="switch"
            inline
            name="alertType"
            label={appContent.cluemaster.game.alertTab.sendAsSMSLabel}
            onChange={(event) => {
              setSendAsSMS(event.target.checked);
            }}
          />
        </Form.Group>
      )}
      <Button type="submit">{appContent.cluemaster.game.alertTab.buttons.send}</Button>
      <Button
        className="ml-2"
        type="button"
        variant="dark"
        onClick={handleInAppPreview}
        disabled={!message}
      >
        {appContent.cluemaster.game.alertTab.buttons.previewInApp}
      </Button>

      <Button
        className="ml-2"
        type="button"
        variant="dark"
        onClick={handleSMSPreview}
        disabled={!message || isSendingAlert}
      >
        {appContent.cluemaster.game.alertTab.buttons.previewSMS}
      </Button>

    </Form>

    <SmsHistory game={game}/>
  </LoadingSpinner>;
};

AlertTab.propTypes = {
  game: PropTypes.instanceOf(Game),
};

export default AlertTab;
