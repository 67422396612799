import React  from "react";
import PropTypes from "prop-types"
import { Modal } from "react-bootstrap";
import startCase from "lodash/startCase";
import { getValuesFromDictionary } from "../../utilities/helper-functions";
import appContent from "../../markdown/app-content";

const GameCreationSummaryModal = (props) => {
  const {summaryData, ...rest} = props;
  const {name, options = {}, tracks = {}, clues = {}} = summaryData;

  const trackNames = getValuesFromDictionary(tracks, (track) => track.trackName);
  const clueNames = getValuesFromDictionary(clues, (clue) => clue.name);

  return <Modal {...rest}>
    <Modal.Header closeButton>
      <Modal.Title>{appContent.modals.createGameModal.title}</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <dl>
        <dt>{appContent.modals.createGameModal.labels.name}</dt>
        <dd>{name}</dd>
        <dt>{appContent.modals.createGameModal.labels.options}</dt>
        {Object.keys(options).map(option => <dd key={option}>{startCase(option)}: {options[option]?.toString()}</dd>)}
        <dt>{appContent.modals.createGameModal.labels.tracks}</dt>
        {trackNames.map(name => <dd key={name}>{name}</dd>)}
        <dt>{appContent.modals.createGameModal.labels.clues}</dt>
        {clueNames.map(name => <dd key={name}>{name}</dd>)}
      </dl>

    </Modal.Body>

  </Modal>
}

GameCreationSummaryModal.propTypes = {
  summaryData: PropTypes.shape({
    name: PropTypes.string,
    options: PropTypes.objectOf(PropTypes.bool),
    tracks: PropTypes.objectOf(PropTypes.object),
    clue: PropTypes.objectOf(PropTypes.object),
  }),
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}

export default GameCreationSummaryModal;
