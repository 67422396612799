import React, {useLayoutEffect, useRef, useState} from "react";
import {CSSTransition} from "react-transition-group";
import "./popup-footer.scss";
import AttentionContainer from "../attention-container/AttentionContainer";

const PopupFooter = ({show, children}) => {
  const [spacerSize, setSpacerSize] = useState(0);
  const footerEl = useRef(null);

  useLayoutEffect(() => {
    if (show) {
      setSpacerSize(footerEl.current.clientHeight);
    } else {
      setSpacerSize(0);
    }
  });

  return <>
    <div style={{height: spacerSize}}/>
    <CSSTransition
      in={show}
      timeout={500} // Should match transition in CSS
      classNames="popup-footer"
      mountOnEnter
      unmountOnExit
    >
      <div className="popup-footer">
        <div className="popup-footer__container">
          <AttentionContainer attention="light">
            <div className="popup-footer__content" ref={footerEl}>
              {children}
            </div>
          </AttentionContainer>
        </div>
      </div>
    </CSSTransition>
  </>;
};

export default PopupFooter;
