import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {EditableBadgeList} from "../components";
import appContent from "../../markdown/app-content";
import Clue from "../../entities/clue";
import Hint from "../../entities/hint";
import {FormCheck} from "react-bootstrap";

const EditClueHints = ({ hints, clue, onHintChange, onClueChange } ) => {
  const [lastHintEdited, setLastHintEdited] = useState(null);
  const [ignoreHintPenaltyValue, setIgnoreHintPenaltyValue] = useState(false);

  useEffect(() => {
    if (lastHintEdited)
      setIgnoreHintPenaltyValue(lastHintEdited.ignorePenalty)
    else
      setIgnoreHintPenaltyValue(false)
  }, [lastHintEdited])

  if (!clue) {
    return  null;
  }

  const handleEditOrAdd = (badgeName, editedValues) => {
    const editingHint = hints.find(h => h.question === badgeName);
    const isAdding = !editingHint;
    const questionText = editedValues[appContent.cluemaster.game.clueTab.hintPopup.questionLabel];
    const hintText = editedValues[appContent.cluemaster.game.clueTab.hintPopup.hintLabel];

    const changedHints = hints.map(h => {
      if (h.id === editingHint?.id) {
        return new Hint({
          ...h._rawHintData,
          question: questionText,
          hint: hintText,
          ignorePenalty: ignoreHintPenaltyValue,
        });
      } else {
        return h;
      }
    });

    if (isAdding) {
      const newHintId = String(Date.now());
      changedHints.push(new Hint({
        id: String(Date.now()),
        question: questionText,
        hint: hintText,
        sequence: hints.length,
        ignorePenalty: ignoreHintPenaltyValue,
      }));

      const newMasterHints = [...clue._rawClueData.masterCluesHints || []]
      newMasterHints.push(newHintId);
      onClueChange(new Clue(clue.id,{
        ...clue._rawClueData,
        masterCluesHints: newMasterHints,
      }))
    }

    onHintChange(changedHints);
  }

  const handleRemove = (badgeName) => {
    // Remove reference to hint from this clue instead of deleting the hint
    // since there's a chance it might be used in a different clue
    // if configured in airtable as such.
    const editingHint = hints.find(h => h.question === badgeName);
    const editingHintId = editingHint?.id;
    onClueChange(new Clue(clue.id,{
      ...clue._rawClueData,
      masterCluesHints: clue._rawClueData.masterCluesHints.filter(id => id !== editingHintId),
    }));
    onHintChange(hints.filter(hint => hint.id !== editingHintId));
  }

  const handleReorder = (questions) => {
    const changedHints = questions.map((question, index) => {
      const hint = hints.find(h => h.question === question);

      return new Hint({
        ...hint._rawHintData,
        sequence: index,
      });
    });

    onHintChange(changedHints);
  }

  return <div className="form-group">
    <div className="form-label">{appContent.cluemaster.game.clueTab.hintsLabel}</div>
    <EditableBadgeList
      badges={hints.map(h => h.question)}
      variant="primary"
      inputModal={{
        title: (isEditing) => isEditing ? appContent.cluemaster.game.clueTab.hintPopup.editTitle : appContent.cluemaster.game.clueTab.hintPopup.addTitle,
        inputs: (editingBadge) => {
          const editingHint = hints.find(h => h.question === editingBadge);
          return [
            {
              label: appContent.cluemaster.game.clueTab.hintPopup.questionLabel,
              defaultValue: editingHint?.question || '',
              formControlProps: {
                as: "textarea",
                placeholder: appContent.cluemaster.game.clueTab.hintPopup.questionPlaceholder,
                rows: 5,
                required: true,
              }
            },
            {
              label: appContent.cluemaster.game.clueTab.hintPopup.hintLabel,
              defaultValue: editingHint?.hint || '',
              formControlProps: {
                as: "textarea",
                placeholder: appContent.cluemaster.game.clueTab.hintPopup.hintPlaceholder,
                rows: 5,
                required: true,
              }
            },
          ]
        },
        onFinish: handleEditOrAdd,
        onHide: () => setLastHintEdited(null),
        buttonText: appContent.cluemaster.game.clueTab.hintPopup.buttonText,
        secondaryFooterSlot: (inputValues, editingBadge) => {
          return <FormCheck
            className="mr-auto"
            type="checkbox"
            label={appContent.cluemaster.game.clueTab.hintPopup.ignorePenaltyLabel}
            checked={ignoreHintPenaltyValue}
            onChange={v => setIgnoreHintPenaltyValue(v.target.checked)}
          />
        }
      }}
      addButtonText={appContent.cluemaster.game.clueTab.hintsAddText}
      onRemove={handleRemove}
      enableReorder
      reorderText={appContent.cluemaster.game.clueTab.hintReorderText}
      onReorder={handleReorder}
      onStartAddingBadge={() => setLastHintEdited(null)}
      onStartEditingBadge={(badgeName) => {
        setLastHintEdited(hints.find(h => h.question === badgeName));
      }}
    />
  </div>
};

EditClueHints.defaultProps = {
  hints: [],
}

EditClueHints.propTypes = {
  clue: PropTypes.instanceOf(Clue),
  hints: PropTypes.arrayOf(PropTypes.instanceOf(Hint)),
  onHintChange: PropTypes.func.isRequired,
  onClueChange: PropTypes.func.isRequired,
}

export default EditClueHints;
