import React from "react";
import {StringParam, useQueryParam} from "use-query-params";
import {Button, Tab, Tabs} from "react-bootstrap";
import {navigate} from "gatsby";
import {ArrowLeftShort} from "react-bootstrap-icons";
import {AppLikeHeader, LoadingSpinner, PageLayout, PrivateClueMasterGuard} from "../../components/components";
import useFirebaseGame from "../../hooks/useFirebaseGame";
import LeaderboardTab from "./game/Leaderboard";
import OptionsTab from "./game/OptionsTab";
import StartTab from "./game/StartTab";
import useFirebaseTeams from "../../hooks/useFirebaseTeams";
import AlertTab from "./game/AlertTab";
import ClueTab from "./game/ClueTab";
import TrackEditorTab from "./game/TrackEditorTab";
import appContent from "../../markdown/app-content";

export const ClueMasterGameTabs = {
  LEADERBOARD: "leaderboard",
  START: "start",
  CLUES: "clues",
  TRACK_EDITOR: "track-editor",
  ALERTS: "alerts",
  OPTIONS: "options",
  RECENT_WRONG_ANSWERS: "wrong-answers"
}

const navigation = [
  {
    id: ClueMasterGameTabs.LEADERBOARD,
    text: appContent.cluemaster.game.leaderboard.tabName,
    component: LeaderboardTab,
  },
  {
    id: ClueMasterGameTabs.START,
    text: appContent.cluemaster.game.startTab.tabName,
    component: StartTab,
  },
  {
    id: ClueMasterGameTabs.CLUES,
    text: appContent.cluemaster.game.clueTab.tabName,
    component: ClueTab,
  },
  {
    id: ClueMasterGameTabs.TRACK_EDITOR,
    text: appContent.cluemaster.game.trackEditorTab.tabName,
    className: 'app-max-width',
    component: TrackEditorTab,
  },
  {
    id: ClueMasterGameTabs.ALERTS,
    text: appContent.cluemaster.game.alertTab.tabName,
    className: 'app-max-width',
    component: AlertTab,
  },
  {
    id: ClueMasterGameTabs.OPTIONS,
    text: appContent.cluemaster.game.optionsTab.tabName,
    className: 'app-max-width',
    component: OptionsTab,
  },
];

const GamePage = ({ children }) => {
  const [gameID] = useQueryParam("gameID", StringParam);
  const [tabId] = useQueryParam("tabId", StringParam);
  const defaultTab = navigation[0];
  const currentTab = navigation.find(navLink => navLink.id === tabId) || defaultTab;
  const { game, isLoading: isLoadingGames } = useFirebaseGame(gameID);
  const { teams, isLoading: isLoadingTeams } = useFirebaseTeams(gameID);

  const isLoading = isLoadingGames || isLoadingTeams;
  const title = game?.name || '';

  const handleBackButton = () => {
    navigate('/clue-master/games/')
  }

  return <PrivateClueMasterGuard>
    <PageLayout fullscreen>
      <AppLikeHeader
        title={title}
        leftSlot={<Button
          className="d-flex align-items-center pl-0 mb-3"
          variant="link"
          onClick={handleBackButton}
        >
          <ArrowLeftShort size={40}/>Games
        </Button>}
      />

      <Tabs
        className="mb-4 app-max-width"
        activeKey={currentTab.id}
        onSelect={(key) => {
          const params = new URLSearchParams({
            gameID: game.id,
            tabId: key,
          });
          navigate(`/clue-master/game?${params.toString()}`)
        }}
        style={{
          flexWrap: 'nowrap',
          whiteSpace: 'nowrap',
          overflowX: 'auto',
          overflowY: 'hidden',
        }}
        unmountOnExit
        mountOnEnter
      >
        {navigation.map(navItem => (
          <Tab key={navItem.id} eventKey={navItem.id} title={navItem.text}>
            {!isLoading &&(
              navItem.component ?
                <div className={navItem.className}>
                  <navItem.component game={game} teams={teams} isLoading={isLoading} />
                </div> :
                <p>{navItem.text} to be programmed</p>
            )}
            <LoadingSpinner isLoading={isLoading} />
          </Tab>
        ))}
      </Tabs>

      {children}
    </PageLayout>
  </PrivateClueMasterGuard>
}

export default GamePage;
