import React from "react";
import Proptypes from "prop-types";
import classNames from "classnames";
import {Button} from "react-bootstrap";
import "./button-with-overlay.scss";

const ButtonWithOverlay = ({children, overlayText, className, disabled, ...rest}) => {
  const c = classNames('button-with-overlay', className);
  const hasOverlayText = overlayText !== undefined && overlayText !== null;
  const buttonIsDisabled = disabled || hasOverlayText;

  return <Button {...rest} className={c} disabled={buttonIsDisabled}>
    { hasOverlayText && <div className="button-with-overlay__overlay">{overlayText}</div> }
    {children}
  </Button>
}

ButtonWithOverlay.propTypes = {
  children: Proptypes.node.isRequired,
  overlayText: Proptypes.string,
  className: Proptypes.string,
}

export default ButtonWithOverlay;
