class PlayerData {
  constructor(id, data = {}) {
    this.id = id;
    this.seenAlertIds = data.seenAlertIds || [];
    this.name = data.name;
    this.email = data.email;
    this.phoneNumber = data.phoneNumber;
    this.leftTeam = data.leftTeam;
    this.additionalFields = data.additionalFields || {};
  }

  hasSeenAlert(alertId) {
    return !!this.seenAlertIds.find(alert => alert === alertId);
  }
}

export default PlayerData;
