import {useEffect, useState} from "react";
import useIsLoading from "../hooks/useIsLoading";
import {getAllGames, GET_ALL_GAMES_QUERIES} from "../services/firebase";
import {GameOptions} from "../entities/game";
import {compareValues} from "../utilities/helper-functions";

export default function useFirebaseGameOnHomePage() {
  const {isLoading, setIsLoading} = useIsLoading(true);
  const [gameOnHomePage, setGameOnHomePage] = useState();

  useEffect(() => {
    const subscription = getAllGames([GET_ALL_GAMES_QUERIES.ALL_ACTIVE_GAMES]).subscribe(games => {
      if (games.length === 0) {
        return null;
      }

      let primaryGame = games.find(g => g.getOption(GameOptions.IS_PRIMARY));
      setGameOnHomePage(primaryGame || games.sort((a,b) => compareValues(a.lastActivatedAt?.seconds, b.lastActivatedAt?.seconds))[0]);
      setIsLoading(false);
    });
    return () => subscription.unsubscribe();
  }, [setIsLoading]);

  return {
    isLoading,
    gameOnHomePage,
  };
}
