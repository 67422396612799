import React, { useState } from "react";
import PropTypes from "prop-types"
import { Button, Modal } from "react-bootstrap";
import appContent from "../../markdown/app-content";
import Clue from "../../entities/clue";
import { ConfirmationModal, Markdown } from "../components";

export const DisableClueType = {
  ADVANCE_ALL: 'advance-all-teams',
  TEAMS_NOT_ON_CLUE: 'teams-not-on-clue',
}

const DisableClueModal = ({clue, onHide, onFinish, ...rest}) => {
  const [disableType, setDisableType] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  let confirmMessage;
  if (disableType === DisableClueType.ADVANCE_ALL)
    confirmMessage = appContent.modals.disabledClueModal.advanceAllConfirmMessage;
  else if (disableType === DisableClueType.TEAMS_NOT_ON_CLUE)
    confirmMessage = appContent.modals.disabledClueModal.disabledForTeamsNotOnClueConfirmMessage;

  const handleClickedDisableOption = (disableTypeOption) => {
    setDisableType(disableTypeOption);
    onHide();
    setShowConfirm(true);
  }

  return <>
    <Modal onHide={onHide} {...rest}>
      <Modal.Header closeButton>
        <Modal.Title>{appContent.modals.disabledClueModal.title(clue.name)}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Markdown>
          {appContent.modals.disabledClueModal.markdown}
        </Markdown>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex">
          <Button
            className="text-no-wrap ml-2"
            variant="light"
            onClick={() => handleClickedDisableOption(DisableClueType.TEAMS_NOT_ON_CLUE)}
          >
            {appContent.modals.disabledClueModal.disabledForTeamsNotOnClueButtonText}
          </Button>
          <Button
            className="text-no-wrap d-inline"
            variant="info"
            onClick={() => handleClickedDisableOption(DisableClueType.ADVANCE_ALL)}
          >
            {appContent.modals.disabledClueModal.advanceAllButtonText}
          </Button>
        </div>

      </Modal.Footer>
    </Modal>
    <ConfirmationModal
      show={showConfirm}
      onHide={() => setShowConfirm(false)}
      onConfirm={() => {
        setShowConfirm(false);
        onFinish(disableType);
      }}
      messageMarkdown={confirmMessage}
    />
  </>
}

DisableClueModal.propTypes = {
  clue: PropTypes.instanceOf(Clue),
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired
}

export default DisableClueModal;
