import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Alert as BootstrapAlert, Button} from "react-bootstrap";
import {X} from "react-bootstrap-icons";
import {CSSTransition} from "react-transition-group";
import classNames from "classnames";
import "./inline-alert.scss";
import Markdown from "../markdown/Markdown";
import {LinksAllowedText} from "../components";

const InlineAlert = ({alert, disappearAfter, dismissible, className, ...rest}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [readyToShowNewMessage, setReadyToShowNewMessage] = useState(true);
  const [alertQueue, setAlertQueue] = useState([]);
  const [activeAlert, setActiveAlert] = useState(null);
  const alertDisappearAfter = alert?.additionalOptions?.overrideDisappearAfter || disappearAfter;

  useEffect(() => {
    // Add alert to queue if it isn't already being shown
    if (
      alert &&
      alert.id !== activeAlert?.id &&
      !alertQueue.find(a => a.id === alert.id)
    ) {
      if (alert.id !== activeAlert?.id && !alertQueue.find(a => a.id === alert.id)) {
        setAlertQueue([...alertQueue, alert]);
      }
    }
  }, [alert, activeAlert, alertQueue]);

  useEffect(() => {
    if (alertQueue.length > 0) {
      setShowAlert(false);
    }
  }, [alertQueue]);

  useEffect(() => {
    let timeout;

    if (alertDisappearAfter && activeAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, alertDisappearAfter);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [activeAlert, alertDisappearAfter]);

  useEffect(() => {
    if (readyToShowNewMessage && alertQueue.length > 0) {
      setReadyToShowNewMessage(false);
      setAlertQueue(alertQueue.slice(1));
      setActiveAlert(alertQueue[0]);
      setShowAlert(true);
    }
  }, [readyToShowNewMessage, alertQueue]);

  return <CSSTransition
    in={showAlert}
    timeout={500} // Should match transition in CSS
    classNames="alert"
    onExited={() => setReadyToShowNewMessage(true)}
    mountOnEnter
    unmountOnExit
  >
    <BootstrapAlert
      className={classNames(className, "alert", "alert--center-text")}
      variant={activeAlert?.additionalOptions?.variant || activeAlert?.variant}
      {...rest}
    >
      {dismissible && <Button
        className="alert__close"
        variant={activeAlert?.additionalOptions?.variant || activeAlert?.variant}
        onClick={() => setShowAlert(false)}
      >
        <X className="alert__close__icon" size={16}/>
      </Button>}
      {activeAlert?.additionalOptions?.title && <h1 className="h3">{activeAlert.additionalOptions.title}</h1>}
      {activeAlert?.message &&
      activeAlert?.additionalOptions?.showAsPlainText
        ? <LinksAllowedText text={activeAlert?.message || ''} />
        : <Markdown>{activeAlert?.message}</Markdown>
      }
    </BootstrapAlert>
  </CSSTransition>;
};

InlineAlert.propTypes = {
  alert: PropTypes.shape({
    id: PropTypes.string.isRequired,
    message: PropTypes.string,
    variant: PropTypes.oneOf(["danger", "success", "warning"]).isRequired,
    additionalOptions: PropTypes.shape({
      showAsPlainText: PropTypes.bool,
      title: PropTypes.string,
      overrideDisappearAfter: PropTypes.number,
      variant: PropTypes.string, // will override alert variant
    }),
  }),
  className: PropTypes.string,
  disappearAfter: PropTypes.number,
  dismissible: PropTypes.bool,
};

export default InlineAlert;
