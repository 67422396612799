import React from "react";
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import Markdown from "../markdown/Markdown";
import appContent from "../../markdown/app-content";

const TermsAndConditionsModal = (props) => {
  return <Modal scrollable {...props}>
    <Modal.Header closeButton>
      <Modal.Title>{appContent.inGame.termsAndConditions.title}</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <Markdown>
        {appContent.inGame.termsAndConditions.markdown}
      </Markdown>
    </Modal.Body>

  </Modal>;
};

TermsAndConditionsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default TermsAndConditionsModal;
