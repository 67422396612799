import React, { useEffect } from "react"
import { navigate } from "gatsby"
import {getAuth} from "../../services/firebase";

const PrivateClueMasterGuard = ({ children }) => {
  useEffect(() => {
    getAuth().subscribe(auth => {
      // Cluemasters should be logged in via email provider. Kick anyone logged in with anonymous auth
      if (!auth || auth.isAnonymous) {
        navigate("/login")
      }
    })
  }, [])

  return <>{children}</>
}
export default PrivateClueMasterGuard;
