import React from "react";
import propTypes from "prop-types";
import {Button} from "react-bootstrap";
import {encodeClueAnswer} from "../../../../services/firestore/clues";
import {getSiteOrigin} from "../../../../utilities/helper-functions";
import appContent from "../../../../markdown/app-content";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard";

const CopyClueAdvancedButton = ({children, answerToEncode, ...rest}) => {
  const {copyText} = useCopyToClipboard();

  const handleCopy = () => {
    const advancedLink = encodeClueAnswer(answerToEncode);
    const params = new URLSearchParams({code: advancedLink});
    copyText(
      `${getSiteOrigin()}/game/clue-advance?${params.toString()}`,
      appContent.cluemaster.game.clueTab.copyAdvancedLinkSuccessMessage(answerToEncode)
    );
  };
  
  if (!answerToEncode) {
    return null;
  } else {
    return <Button onClick={handleCopy} {...rest}>
      {children}
    </Button>;
  }
};

CopyClueAdvancedButton.propTypes = {
  answerToEncode: propTypes.string.isRequired,
  children: propTypes.node,
};

export default CopyClueAdvancedButton;

