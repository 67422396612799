import {parseFirstoreTimestamp} from "../utilities/dates";

export const TEAM_PENALTIES = {
  HINT: "hint-penalty",
  LIVE_HINT: "live-hint-penalty",
  WALK_THROUGH: "walkthrough-penalty",
}

class Penalty {
  constructor(penaltyData = {}) {
    this._rawPenaltyData = penaltyData;
    this.id = penaltyData.id;
    this.penaltyAmount = penaltyData.penaltyAmount || 0;
    this.type = penaltyData.type;
    this.createdBy = penaltyData.createdBy;
    this.createdAt = parseFirstoreTimestamp(penaltyData.createdAt);
    this.clueId = penaltyData.clueId;
    this.hintServed = !!penaltyData.hintServed;
    this.hintId = penaltyData.hintId;
    this.lockHintsForMins = penaltyData.lockHintsForMins || 0;
  }

  isAHintPenalty() {
    const TEAM_PENALTIES_THAT_ARE_HINTS = [
      TEAM_PENALTIES.HINT,
      TEAM_PENALTIES.LIVE_HINT,
      TEAM_PENALTIES.WALK_THROUGH,
    ];

    return TEAM_PENALTIES_THAT_ARE_HINTS.includes(this.type);
  }

  isAnUnfinishedHint() {
    return this.isAHintPenalty() && !this.hintServed;
  }
}

Penalty.filterNonHints = (penalties = []) => {
  return penalties.filter(penalty => penalty.isAHintPenalty())
}

export default Penalty;
