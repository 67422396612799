import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Button } from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import "./removable-badge.scss";

const RemovableBadge = ({children, variant, onRemove, onClick, ...rest}) => {
  return <Badge
    className="removable-badge"
    variant={variant}
    onClick={onClick}
    {...rest}
  >
    <div className="removable-badge__content">{children}</div>
    <Button className="removable-badge__close" variant={variant} onClick={(event) => {
      event.stopPropagation();
      onRemove();
    }}>
      <X />
    </Button>
  </Badge>
}

RemovableBadge.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onClick: PropTypes.func,
}

export default RemovableBadge;
