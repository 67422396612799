import React from "react";
import PropTypes from "prop-types";
import {Badge} from "react-bootstrap";
import Game, {GameOptions} from "../../entities/game";
import "./ClueMasterGameList.scss";
import classNames from "classnames";
import {ListGroup, ListGroupItem, LoadingSpinner} from "../components";
import appContent from "../../markdown/app-content";

function ClueMasterGameListItem(props) {
  const {
    game, onClick = () => {
    },
  } = props;
  const isPrimaryGame = !!game?.getOption(GameOptions.IS_PRIMARY);

  return <ListGroupItem
    key={game.id}
    className={classNames([
      "clue-master-game-list__item",
    ])}
    action
    onClick={() => onClick(game.id)}
    secondaryLabel={isPrimaryGame && appContent.cluemaster.games.gamesListPrimaryGameLabel}
    title={<div className="d-flex align-items-center">
      {isPrimaryGame && appContent.cluemaster.games.gamesListPrimaryGamePrefix}
      {game.name}
      {isPrimaryGame && appContent.cluemaster.games.gamesListPrimaryGameSuffix}
    </div>}
  >
    <div>
      <Badge
        className="clue-master-game-list__item__track"
        variant="secondary"
      >
        {game.challengeName}
      </Badge>
      {game.trackNames().map(tracks => (
        <Badge
          key={tracks}
          className="clue-master-game-list__item__track"
          variant="dark"
        >
          {tracks}
        </Badge>
      ))}
    </div>
  </ListGroupItem>;
}

ClueMasterGameListItem.propTypes = {
  game: PropTypes.instanceOf(Game),
  onClick: PropTypes.func.isRequired,
};

function ClueMasterGameList(props) {
  const {games, isLoading, children, ...rest} = props;

  return <ListGroup>
    {!games || games.length === 0 && !isLoading && (
      <ListGroupItem>{appContent.cluemaster.games.gamesListNoGamesText}</ListGroupItem>
    )}
    {isLoading && <ListGroupItem style={{minHeight: "110px"}}><LoadingSpinner isLoading={true} /></ListGroupItem>}
    {(games || []).map(game => <ClueMasterGameListItem
      key={game.id}
      game={game}
      {...rest}
    />)}
  </ListGroup>
}

ClueMasterGameList.propTypes = {
  ...ClueMasterGameListItem.propTypes,
  isLoading: PropTypes.bool.isRequired,
};

export default ClueMasterGameList;
