import {useEffect, useState} from "react";
import useInterval from "../../hooks/useInterval";

export default function useCountdown(countdown = 0) {
  const [secondsLeft, setSecondsLeft] = useState(0);

  // Set counter when countdown changes
  useEffect(() => {
    if (countdown) {
      setSecondsLeft(countdown);
    } else {
      setSecondsLeft(0);
    }
  }, [countdown]);

  // Count down
  useInterval(() => {
    if (secondsLeft > 0) {
      setSecondsLeft(secondsLeft - 1);
    }
  }, 1000);

  const resetCounter = () => {
    setSecondsLeft(countdown);
  }

  return {
    secondsLeft,
    resetCounter,
  };
}
