import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import BulkAddModal from "../../../components/modals/BulkAddModal";
import { bulkCreateTeams } from "../../../services/firestore/teams";
import { LoadingSpinner } from "../../../components/components";
import { AppAlertsContext } from "../../../context/app-alerts-context";
import { randomPluralName } from "../../../utilities/helper-functions";
import BulkAddSummaryModal from "../../../components/modals/BulkAddSummaryModal";
import appContent from "../../../markdown/app-content"
import Game from "../../../entities/game";

const BulkAdd = ({game, ...rest}) => {
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [showSummaryPopup, setShowSummaryPopup] = useState(false);
  const [teamsFromLastBulkAdd, setTeamsFromLastBulkAdd] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {popError} = useContext(AppAlertsContext);

  const handleBulkAdd = async (teamCount) => {
    const teamNames = [];
    setIsLoading(true);
    for (let i = 0; i < teamCount; i++) {
      teamNames.push(randomPluralName());
    }
    try {
      const createTeamData = await bulkCreateTeams(game, teamNames);
      setTeamsFromLastBulkAdd(createTeamData);
      setShowAddPopup(false);
      setShowSummaryPopup(true);
    } catch (e) {
      popError(e.message)
    }
    setIsLoading(false);
  }

  return <LoadingSpinner isLoading={isLoading}>
    <Button onClick={() => setShowAddPopup(!showAddPopup)} {...rest}>
      {appContent.modals.bulkAddTeams.triggerPopupButtonText}
    </Button>
    <BulkAddModal
      show={showAddPopup}
      onHide={() => setShowAddPopup(false)}
      onFinish={handleBulkAdd}
      isLoading={isLoading}
    />
    <BulkAddSummaryModal
      show={showSummaryPopup}
      onHide={() => setShowSummaryPopup(false)}
      teamNamesAndCodes={teamsFromLastBulkAdd}
      shownAfterCreation
    />

  </LoadingSpinner>
}

BulkAdd.prototype = {
  game: PropTypes.instanceOf(Game),
}

export default BulkAdd;
