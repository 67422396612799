import { object } from "rxfire/database";
import firebaseApp from "../firebase-app";
import { map } from "rxjs/operators";

export const readRealtimeDbData = (refPath) => {
  if (!refPath) {
    throw new Error("ref path required to read data");
  }

  const ref = firebaseApp.database().ref(refPath);
  return object(ref)
    .pipe(
      map(change => ({
        _key: change.snapshot.key,
        ...change.snapshot.val()
      }))
    )
}
