import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {Link, LinkNewTab, TeamNameModal} from "../components";
import {GET_ALL_GAMES_QUERIES, getAllGames} from "../../services/firebase";
import {navigate} from "gatsby";
import {Col, Container, Row} from "react-bootstrap";
import GameContext from "../../context/game-context";
import {leaveTeam, renameTeam} from "../../services/firestore/teams";
import {AppAlertsContext} from "../../context/app-alerts-context";
import {AlertType} from "../providers/app-alerts/AppAlerts";
import {first} from "rxjs/operators";
import appContent from "../../markdown/app-content";
import UserDecoration from "../../app/shared/UserDecoration";
import {GameOptions} from "../../entities/game";

const InGameFooter = ({finishedGame}) => {
  const {popAlert, popError} = useContext(AppAlertsContext);
  const {game, team} = useContext(GameContext);
  const [showTeamRename, setShowTeamRename] = useState(false);
  const disableTeamChange = game?.getOption(GameOptions.DISABLE_TEAM_NAME_CHANGE) || finishedGame;

  const onLeaveTeam = async () => {
    try {
      // When leaving a team they should be redirected to join a primary game
      // if there is one, otherwise keeping them in the current game is okay as they'll be redirected and
      // can still join a team with an invite code.
      const [primaryGame] = await getAllGames([GET_ALL_GAMES_QUERIES.PRIMARY_GAME]).pipe(first()).toPromise();
      const params = new URLSearchParams({gameId: primaryGame ? primaryGame.id : team.gameId});
      await navigate(`/game/join-game?${params.toString()}`); // By redirecting the user to another screen we destroy subscriptions that rely on being apart of the team
      await leaveTeam(team);
      await navigate(`/game/join-game?${params.toString()}`);
      popAlert(appContent.alerts.leaveTeamSuccess, AlertType.SUCCESS);
    } catch (e) {
      popError(e.message);
    }
  };

  const onShowTeamRename = () => {
    setShowTeamRename(true);
  };

  const onRenameTeam = (name) => {
    renameTeam(team, name)
      .then(() => setShowTeamRename(false))
      .catch(e => alert(e));
  };

  if (!team) {
    return null;
  } else {
    const displayTeamName = team?.name || "Untitled Team";
    return team && <Container>
      <Row>
        <Col sm={7}>
          <h1 className="h4">{appContent.inGame.footer.team.title}</h1>
          <p>
            {/*Team Name*/}
            {appContent.inGame.footer.team.teamName.prefixBeforeName}
            <Link onClick={onShowTeamRename} disabled={disableTeamChange}>{displayTeamName}</Link>
            {appContent.inGame.footer.team.teamName.middleBetweenNameAndCode}
            <b>{team?.inviteCode}</b>
            {appContent.inGame.footer.team.teamName.suffixAfterCode}
            {!disableTeamChange && appContent.inGame.footer.team.teamName.suffixWhenEditable}
            {/*Contact Cluemaster*/}
            {!game?.getOption(GameOptions.DISABLE_CLUEMASTER_CONTACT) && (
              <>
                {appContent.inGame.footer.team.contactCM.prefix}
                <LinkNewTab
                  to="/game/contact-cluemaster">{appContent.inGame.footer.team.contactCM.linkText}</LinkNewTab>
                {appContent.inGame.footer.team.contactCM.suffix}
              </>
            )}
          </p>
          <dl className="comma-separated-list">
            <dt>{appContent.inGame.footer.team.teammatesLabel}</dt>
            {team?.orderedUsers.map(user => <dd key={user.id}>
              <UserDecoration userOrPlayerData={user}>
                {user.name}
              </UserDecoration>
            </dd>)}
          </dl>
        </Col>
        <Col>
          <h1 className="h4">{appContent.inGame.footer.leaveGame.title}</h1>
          <p>
            {appContent.inGame.footer.leaveGame.leave.prefix}
            <Link onClick={onLeaveTeam}>{appContent.inGame.footer.leaveGame.leave.linkText}</Link>
            {appContent.inGame.footer.leaveGame.leave.suffix}
          </p>
        </Col>
      </Row>
      <TeamNameModal
        scenario="rename"
        show={showTeamRename}
        onHide={() => setShowTeamRename(false)}
        onFinish={onRenameTeam}
      />
    </Container>;
  }
};

InGameFooter.propTypes = {
  finishedGame: PropTypes.bool,
};

export default InGameFooter;
