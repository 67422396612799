import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import Game, {GameOptions} from "../../../entities/game";
import {AppAlertsContext} from "../../../context/app-alerts-context";
import {DateTimeInputModal, Link} from "../../../components/components";
import {getLocalTimeFromTimestamp} from "../../../utilities/dates";
import appContent from "../../../markdown/app-content";
import {setGameOption} from "../../../services/firebase";

const GameCloseTime = ({game}) => {
  const {popError} = useContext(AppAlertsContext);
  const [showEdit, setShowEdit] = useState(false);

  const handleSetGameCloseTime = (timestamp) => {
    try {
      setGameOption(game, GameOptions.GAME_CLOSE_AFTER_TIMESTAMP, timestamp).catch(e => popError(e.message));
      setShowEdit(false);
    } catch (e) {
      popError(e.message);
    }
  };

  return <>
    <div className="mb-3">
      <span className="mr-2">{appContent.cluemaster.game.optionsTab.optionLabelForGameCloseTime}:</span>
      <Link onClick={() => {
        setShowEdit(true);
      }}>
        {getLocalTimeFromTimestamp(
          game?.getOption(GameOptions.GAME_CLOSE_AFTER_TIMESTAMP)) ||
        "-"
        }
      </Link>
      <div className="text-muted">{appContent.cluemaster.game.optionsTab.optionLabelForGameCloseTimeHintText}</div>
    </div>
    <DateTimeInputModal
      show={showEdit}
      onHide={() => setShowEdit(false)}
      onSetStartTime={handleSetGameCloseTime}
      initialValue={game?.getOption(GameOptions.GAME_CLOSE_AFTER_TIMESTAMP) || null}
      title={appContent.modals.gameClose.title}
      dateLabel={appContent.modals.gameClose.startDateLabel}
      timeLabel={appContent.modals.gameClose.startTimeLabel}
      timeHint={appContent.modals.gameClose.timeHint}
      submitText={appContent.modals.gameClose.submitText}
    />
  </>;
};

GameCloseTime.propTypes = {
  game: PropTypes.instanceOf(Game),
};

export default GameCloseTime;
