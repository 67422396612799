import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Button, Form, Modal} from "react-bootstrap";
import {LoadingSpinner} from "../components";
import appContent from "../../markdown/app-content";

const BulkAddModal = ({onFinish, show, isLoading, ...rest}) => {
  const [teamNumber, setTeamNumber] = useState(0);

  useEffect(() => {
    setTeamNumber(0);
  }, [show]);

  const handleSubmit = event => {
    event.preventDefault();

    if (onFinish) {
      onFinish(teamNumber);
    }
  };

  return <Modal show={show} {...rest}>
    <Form onSubmit={handleSubmit}>
      <Modal.Header closeButton>
        <Modal.Title>{appContent.modals.bulkAddTeams.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <LoadingSpinner isLoading={isLoading}>
          <Form.Group>
            <Form.Label>{appContent.modals.bulkAddTeams.label}</Form.Label>
            <Form.Control
              type="number"
              placeholder={appContent.modals.bulkAddTeams.placeholder}
              required
              value={teamNumber}
              min={1}
              max={100}
              onChange={(event) => setTeamNumber(event.target.value)}
            />
          </Form.Group>
        </LoadingSpinner>
      </Modal.Body>

      <Modal.Footer>
        <Button type="submit" variant="info" disabled={isLoading}>
          {appContent.modals.bulkAddTeams.buttonText}
        </Button>
      </Modal.Footer>
    </Form>
  </Modal>;
};

BulkAddModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default BulkAddModal;
