import React from "react";
import PropTypes from "prop-types";
import Game from "../../../entities/game";
import Team from "../../../entities/team";
import ClueTabUI from "../../../app/clue-master/clues/ClueTabUI";
import ClueEditProvider from "../../../app/clue-master/clues/provider/clue-edit-provider/ClueEditProvider";

const ClueTab = ({game, teams, isLoading}) => {
  return <ClueEditProvider>
    <ClueTabUI
      game={game}
      teams={teams}
      isLoading={isLoading}
    />
  </ClueEditProvider>;
};

ClueTab.propTypes = {
  game: PropTypes.instanceOf(Game),
  teams: PropTypes.arrayOf(PropTypes.instanceOf(Team)),
  isLoading: PropTypes.bool,
};

export default ClueTab;
