import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Container, Row } from "react-bootstrap";
import Game from "../../../entities/game";
import useFirebaseTeams from "../../../hooks/useFirebaseTeams";
import { ClueMasterStartTeamTable, LoadingSpinner } from "../../../components/components";
import { manuallyStartTeams } from "../../../services/firebase";
import useIsLoading from "../../../hooks/useIsLoading";
import BulkAddSummaryModal from "../../../components/modals/BulkAddSummaryModal";
import appContent from "../../../markdown/app-content";

const TeamTable = ({teams, noTeamMessage}) => {
  if (teams.length > 0) {
    return <ClueMasterStartTeamTable teams={teams} />
  }
  else {
    return <p className="text-center">
      {noTeamMessage}
    </p>
  }
};

const StartTab = ({ game }) => {
  const [showTeamSummary, setShowTeamSummary] = useState(false);
  const { teams, isLoading: isLoadingTeams } = useFirebaseTeams(game?.id);
  const { isLoading: isLoadingStartUnstarted, setIsLoading: setIsLoadingStartUnstarted } = useIsLoading(false);
  const unstartedTeams = [];
  const startedTeams = [];

  teams.forEach(team => {
    if (team.getStartTime()) {
      startedTeams.push(team);
    } else {
      unstartedTeams.push(team);
    }
  });

  const handleStartAllUnstarted = async () => {
    setIsLoadingStartUnstarted(true);
    await manuallyStartTeams(unstartedTeams)
      .catch(e => alert(e.message));
    setIsLoadingStartUnstarted(false)
  }

  return <LoadingSpinner isLoading={isLoadingTeams} hideChildrenWhenLoading>
    <LoadingSpinner isLoading={isLoadingStartUnstarted} />
    <Container fluid>
      <Row>
        <Col lg={6}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h1 className="h3 mb-0">{appContent.cluemaster.game.startTab.unstartedTitle}</h1>
            <div>
              <Button
                className="ml-2"
                onClick={() => setShowTeamSummary(true)}
                disabled={teams.length === 0}
              >
                {appContent.cluemaster.game.startTab.viewTeamsButtonText}
              </Button>
              <Button
                className="ml-2"
                onClick={handleStartAllUnstarted}
                disabled={unstartedTeams.length === 0}
              >
                {appContent.cluemaster.game.startTab.unstartedStartAllButtonText}
              </Button>
            </div>
          </div>
          <TeamTable teams={unstartedTeams} noTeamMessage={appContent.cluemaster.game.startTab.unstartedNoTeamsText} />
        </Col>
        <Col lg={6}>
          <h1 className="h3 mb-3">{appContent.cluemaster.game.startTab.startedTitle}</h1>
          <TeamTable teams={startedTeams} noTeamMessage={appContent.cluemaster.game.startTab.startedNoTeamsText} />
        </Col>
      </Row>
    </Container>
    <BulkAddSummaryModal
      onHide={() => setShowTeamSummary(null)}
      teamNamesAndCodes={teams.map(team => ({
        name: team.name,
        id: team.id,
        phrase: team.inviteCode,
      }))}
      show={showTeamSummary}
    />
  </LoadingSpinner>
}

StartTab.propTypes = {
  game: PropTypes.instanceOf(Game),
}

export default StartTab;
