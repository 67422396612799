import { gameCollection } from "../firebase";

export async function createClue(game, name) {
  const newId = `new-clue-${Date.now().toString()}`;

  await gameCollection().doc(game.id).update({
    [`clues.${newId}`]: {
      id: newId,
      name,
      content: '',
      answer: [],
    },
  });

  return newId;
}

export function updateClue(game, clue) {
  return gameCollection().doc(game.id).update({
    [`clues.${clue.id}`]: clue._rawClueData,
  });
}

export function cloneClue(game, clue) {
  const newId = `${clue.id}-clone`;
  return gameCollection().doc(game.id).update({
    [`clues.${newId}`]: {
      ...clue._rawClueData,
      id: newId,
      name: `${clue.name} [clone]`,
    },
  });
}

export function encodeClueAnswer(answer) {
  return answer ? encodeURI(window.btoa(answer)) : null;
}

export function decodeClueAnswer(code) {
  return window.atob(decodeURI(code));
}
