import React from "react"
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";
import "./LoadingSpinner.scss";

const LoadingSpinner = ({ children, isLoading, hideChildrenWhenLoading }) => {
  const showChildren = !isLoading || !hideChildrenWhenLoading;
  return <>
    {showChildren && children}
    {isLoading && <Spinner className="loading-spinner" animation="grow" variant="dark" />}
  </>
}

LoadingSpinner.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hideChildrenWhenLoading: PropTypes.bool,
  children: PropTypes.node,
}

export default LoadingSpinner;
