import React, { useState } from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import Team from "../../entities/team";
import { getLocalTimeFromTimestamp } from "../../utilities/dates";
import { Link, DateTimeInputModal } from "../components";
import { manuallySetTeamStartTime } from "../../services/firebase";
import appContent from "../../markdown/app-content";

const ClueMasterStartTeamTable = ({teams = []}) => {
  const [editingTimeOfTeam, setEditingTimeOfTeam] = useState(false);
  const [defaultEditTime, setDefaultEditTime] = useState(null);

  const triggerTeamTimeEdit = (team) => {
    setEditingTimeOfTeam(team)
    setDefaultEditTime(team.getStartTime());
  }

  const onEditedTeamTime = async (timestamp) => {
    setEditingTimeOfTeam(null)
    await manuallySetTeamStartTime(editingTimeOfTeam, timestamp)
      .catch(e => alert(e.message));
  }

  return <>
    <Table striped bordered variant="dark">
      <thead>
        <tr>
          <th>Team Name</th>
          <th>Players</th>
          <th>Start Time</th>
          <th>Manually Set</th>
        </tr>
        {teams.map(team => <tr key={team.id}>
          <td>{team.name}</td>
          <td>{team.getPlayerCount()}</td>
          <td>
            <Link onClick={() => triggerTeamTimeEdit(team)}>
              {getLocalTimeFromTimestamp(team.getStartTime()) || '-'}
            </Link>
          </td>
          <td>{team.startTimeManuallySet ? "Yes" : "No"}</td>
        </tr>)}
      </thead>
    </Table>
    <DateTimeInputModal
      show={!!editingTimeOfTeam}
      onHide={() => setEditingTimeOfTeam(null)}
      onSetStartTime={onEditedTeamTime}
      initialValue={defaultEditTime}
      title={appContent.modals.editStartTime.title}
      dateLabel={appContent.modals.editStartTime.startDateLabel}
      timeLabel={appContent.modals.editStartTime.startTimeLabel}
      timeHint={appContent.modals.editStartTime.timeHint}
      submitText={appContent.modals.editStartTime.submitText}
    />
  </>
}

ClueMasterStartTeamTable.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.instanceOf(Team)),
}

export default ClueMasterStartTeamTable;
