import React from "react";
import {AppAlerts, BaseStyles, CrossTabProvider, GameProvider, PauseProvider} from "../../components";
import UserProfileProvider from "../../providers/user-profile/UserProfileProvider";
import {Helmet} from "react-helmet";
import {StaticQuery, graphql} from "gatsby";
import favicon from "../../../images/favicon.ico";
import ReactHelmetProvider from "../../providers/react-helmet/ReactHelmetProvider";

const AppLayout = (props) => {
  const {children} = props;

  return <StaticQuery
    query={graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
    render={data => (
      <ReactHelmetProvider>
        <BaseStyles>
          <AppAlerts>
            <GameProvider>
              <PauseProvider>
                <UserProfileProvider>
                  <CrossTabProvider>
                    {children}
                  </CrossTabProvider>
                </UserProfileProvider>
              </PauseProvider>
            </GameProvider>
          </AppAlerts>
          <Helmet>
            <title>{data.site.siteMetadata.title}</title>
            <link rel="icon" href={favicon}/>
          </Helmet>
        </BaseStyles>
      </ReactHelmetProvider>
    )}
  />;
};

export default AppLayout;
