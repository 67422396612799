import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {PlusSquare} from "react-bootstrap-icons";
import {Button} from "react-bootstrap";
import Game from "../../../../entities/game";
import {createClue} from "../../../../services/firestore/clues";
import {AppAlertsContext} from "../../../../context/app-alerts-context";
import {AlertType} from "../../../../components/providers/app-alerts/AppAlerts";
import {InputModal} from "../../../../components/components";
import appContent from "../../../../markdown/app-content";

const NewClueButton = ({game, onNewClue, ...rest}) => {
  const {popAlert, popError} = useContext(AppAlertsContext);
  const [showInputModal, setShowInputModal] = useState(false);

  const handleSubmit = async (inputs) => {
    const newName = inputs[appContent.cluemaster.game.clueTab.createCluePopup.label];
    try {
      const newClueId = await createClue(game, newName);
      popAlert(appContent.cluemaster.game.clueTab.createCluePopup.successMessage(newName), AlertType.SUCCESS);
      if (onNewClue) {
        onNewClue(newClueId);
      }
      setShowInputModal(false);
    } catch (e) {
      popError(e.message);
    }
  };

  return <>
    <Button onClick={() => setShowInputModal(true)} {...rest}>
      <PlusSquare/>
    </Button>
    <InputModal
      show={showInputModal}
      onHide={() => setShowInputModal(false)}
      onFinish={handleSubmit}
      title={appContent.cluemaster.game.clueTab.createCluePopup.title}
      inputs={[{
        label: appContent.cluemaster.game.clueTab.createCluePopup.label,
        defaultValue: "",
        formControlProps: {
          placeholder: appContent.cluemaster.game.clueTab.createCluePopup.placeholder,
          type: "text",
          required: true,
        },
      }]}
      buttonText={appContent.cluemaster.game.clueTab.createCluePopup.buttonText}
    />
  </>;
};

NewClueButton.propTypes = {
  game: PropTypes.instanceOf(Game).isRequired,
  onNewClue: PropTypes.func,
};

export default NewClueButton;
