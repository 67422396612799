import {parseFirstoreTimestamp, prettySecondsAsMinutes, subtractSecondsFromTimestamp, timeCompact} from "../../utilities/dates";
import {convertToCSV} from "../../utilities/helper-functions";

export const getTeamClueTimesTable = (args) => {
  const {clues, teamTimes, teams} = getTeamClueTimes(args);
  return convertToCSV([
    ["Team ID", "Team Name",...clues.map(c => c.name)],
    ...teamTimes.map((data, index) => [
      teams[index].id,
      teams[index].name,
      ...data,
    ])
  ]);
}


export const getTeamClueTimes = ({game, teams = []}) => {
  if (!game) {
    throw new Error("Game is required to get team clue times");
  }

  const clues = game.getAllClues().sort(c => c.name);
  const teamTimes = teams.map(t => {
    return clues.map(c => {
      const secondsOnClue = t.getRawTimeSpentOnClue({game, clue: c});
      if (secondsOnClue === 0) {
        return "-";
      } else {
        let timeOnClueOutput = timeCompact(
          t.getRawTimeSpentOnClue({game, clue: c})
        );
        if (c.excludeTimes) {
          timeOnClueOutput = `(${timeOnClueOutput})`;
        }
        return timeOnClueOutput;
      }
    });
  });

  return {
    clues,
    teamTimes,
    teams,
  };
}
