import useCopyToClipboard from "./useCopyToClipboard";
import {getTeamClueTimes, getTeamClueTimesTable} from "../services/analytics/team-clue-times";
import {convertToCSV} from "../utilities/helper-functions";
import {getTeams} from "../services/firebase";
import {first} from "rxjs/operators";

export const useCopyClueTimes = ({game}) => {
  const {copyText} = useCopyToClipboard();

  return async () => {
    const teams = await getTeams(game?.id).pipe(first()).toPromise();

    const {clues, teamTimes} = getTeamClueTimes({
      game,
      teams,
    });

    return await copyText(getTeamClueTimesTable({game, teams}));
  }
}