import {useContext, useEffect, useState} from "react";
import {AppAlertsContext} from "../context/app-alerts-context";
import GameContext from "../context/game-context";
import {sendAlertToTeams, takePenalty, updateMostRecentPenaltyFromPlayer} from "../services/firestore/teams";
import UserProfileContext from "../context/user-profile-context";
import appContent from "../markdown/app-content";
import {TEAM_PENALTIES} from "../entities/penalty";
import useCurrentClueLogic from "./useCurrentClueLogic";
import {ALERTS_DISAPPEAR_AFTER_MS, DELAY_HINT_CONFIRM_FOR_SECONDS} from "../config/config-options";
import {
  compareTimestamps,
  minutesSinceTimestamp,
  newFirestoreTimeStamp,
  secondsSinceTimestamp,
  splitMinutes,
  subtractSecondsFromTimestamp
} from "../utilities/dates";
import {usePreviousValue} from "./usePreviousValue";
import firebaseApp from "../services/firebase-app";
import {triggerSendSlackMessageWithWebhook} from "../services/firestore/functions/triggerSendSlackMessageWithWebhook";

function getAvailableHints(hints = [], penalties = []) {
  let availableHints = [];

  // Traverse hints checking if the hint has been taken. If it has don't include it.
  hints.forEach(h => {
    if (!penalties.find(p => p.hintId === h.id)) {
      availableHints.push(h);
    }
  });

  return availableHints;
}

function liveHintsEnabled(game) {
  return game?.liveHints.enabled;
}

function liveHintsEnabledForTeam(game, team) {
  return game?.liveHints.urlEnabledForTeam(team) ?? false;
}

export const HINT_DISABLED_REASONS = {
  NOT_STARTED: "not started",
  PLAYER_TAKING_HINT: "player taking hint",
  NO_MORE_HINTS: "no more hints",
  HINT_LOCK_STARTING: "starting hint locking",
  HINT_LOCK_TOOK_HINT: "hint locked from taking hint",
  HINT_LOCK_TOOK_LIVE_HINT: "hint locked from taking live hint",
  LIVE_HINTS_NO_URL_FOR_TEAM: "live hints without url for team",
};

function getHintDisabledReason({
  game,
  clue,
  lastAdvancedTimestamp,
  team,
  mostRecentHintTaken,
  moreHintsRemain,
  isLiveHintButton,
}) {
  const isLiveHint = mostRecentHintTaken?.type === TEAM_PENALTIES.LIVE_HINT;
  let hintLockMinutes = isLiveHint ? game.hintLocking.fromLiveHintMinutes : game.hintLocking.fromTakenHintMinutes;

  if (!team?.getHasStarted()) {
    return HINT_DISABLED_REASONS.NOT_STARTED;
  } else if (mostRecentHintTaken?.isAnUnfinishedHint()) {
    return HINT_DISABLED_REASONS.PLAYER_TAKING_HINT;
  } else if (!isLiveHintButton && !moreHintsRemain) {
    return HINT_DISABLED_REASONS.NO_MORE_HINTS;
  } else if (game.hintLocking.enabled && minutesSinceTimestamp(lastAdvancedTimestamp) <= game.hintLocking.minutes) {
    return HINT_DISABLED_REASONS.HINT_LOCK_STARTING;
  } else if (mostRecentHintTaken?.lockHintsForMins && minutesSinceTimestamp(mostRecentHintTaken.createdAt) <= hintLockMinutes) {
    if (isLiveHint) {
      return HINT_DISABLED_REASONS.HINT_LOCK_TOOK_LIVE_HINT;
    } else {
      return HINT_DISABLED_REASONS.HINT_LOCK_TOOK_HINT;
    }
  }

  if (isLiveHintButton && liveHintsEnabled(game) && !liveHintsEnabledForTeam(game, team)) {
    return HINT_DISABLED_REASONS.LIVE_HINTS_NO_URL_FOR_TEAM;
  }
}

const useHintQuestionsFlow = () => {
  const {userProfile} = useContext(UserProfileContext);
  const {game, team} = useContext(GameContext);
  const {popError, popConfirm, clearConfirm} = useContext(AppAlertsContext);
  const {clue, lastAdvancedTimestamp, penaltiesFromHints} = useCurrentClueLogic(game, team);
  const previousClueId = usePreviousValue(clue?.id);
  const hints = game?.getHintsForClue(clue);
  const walkthrough = game?.getWalkthroughForClue(clue);
  const [showWalkthrough, setShowWalkthrough] = useState(false);
  const [hintQuestionStack, setHintQuestionStack] = useState(null);
  const [previousHint, setPreviousHint] = useState(null);
  const currentHint = showWalkthrough ? walkthrough : hintQuestionStack?.length > 0 ? hintQuestionStack[0] : null;
  const hasWalkthrough = !!walkthrough;
  const [mostRecentHintTaken] = penaltiesFromHints.slice(-1);
  const showingHintQuestion = !!currentHint;
  const completedWalkthroughs = penaltiesFromHints.filter(p => p.type === TEAM_PENALTIES.WALK_THROUGH);
  const walkthroughAvailable = walkthrough && completedWalkthroughs.length === 0;

  // Available hints filter out any hints that have been taken or ones that appear before taken hints.
  const availableHints = getAvailableHints(hints, penaltiesFromHints);
  const walkthroughWasTaken = walkthrough && !!penaltiesFromHints.find(p => p.hintId === walkthrough.id);
  const moreHintsRemain = !walkthroughWasTaken && (
    availableHints?.length > 0 || hasWalkthrough
  );
  const onLastHint = game && (!showWalkthrough && availableHints[availableHints.length - 1]?.id === currentHint?.id);
  const disabledHintButtonReason = getHintDisabledReason({
    game,
    clue,
    lastAdvancedTimestamp,
    team,
    mostRecentHintTaken,
    moreHintsRemain,
    isLiveHintButton: false,
  });
  const disabledLiveHintButtonReason = getHintDisabledReason({
    game,
    clue,
    lastAdvancedTimestamp,
    team,
    mostRecentHintTaken,
    moreHintsRemain,
    isLiveHintButton: true
  });
  const disableHintButton = Boolean(disabledHintButtonReason);

  useEffect(() => {
    const mostRecentPenaltyIsFromThisUser = (
      mostRecentHintTaken &&
      mostRecentHintTaken?.createdBy === userProfile?.id
    );

    // If player who started a hint flow hasn't finished it, make sure
    // it pops if they re-enter the game
    if (
      mostRecentPenaltyIsFromThisUser &&
      mostRecentHintTaken.isAnUnfinishedHint() &&
      !showingHintQuestion
    ) {
      startAskingHints();
    }

    // If player has finished a hint flow and it's displaying, exit the flow.
    // This is for players who might have opened multiple browsers and guards
    // against multiple hints being taken
    if (
      mostRecentPenaltyIsFromThisUser &&
      !mostRecentHintTaken.isAnUnfinishedHint() &&
      showingHintQuestion
    ) {
      resetHints();
    }

  }, [userProfile, mostRecentHintTaken]);

  useEffect(() => {
    if (
      currentHint &&
      clue.hintIds.indexOf(currentHint.id) === -1
    ) {
      resetHints();
    }
  }, [clue, currentHint]);

  // Clear confirm box if clue changes
  useEffect(() => {
    if (clue && previousClueId && clue?.id !== previousClueId) {
      clearConfirm();
    }
  }, [clue, previousClueId]);

  const getHintPenalty = () => {
    if (team.getHasStarted()) {
      const lastTakenHint = penaltiesFromHints.slice().reverse().find(penalty => {
        return !penalty.isAnUnfinishedHint();
      });
      const calculateHintPenaltyFromTimestamp = lastTakenHint?.createdAt || lastAdvancedTimestamp;

      // Calculate adjustments for paused time
      let pauseAdjustmentInSeconds = 0;
      team.pauseHistory.forEach(h => {
        const {start, end} = h;
        if (!end) {
          pauseAdjustmentInSeconds -= secondsSinceTimestamp(start);
        } else if (compareTimestamps(calculateHintPenaltyFromTimestamp, end) > 0) {
          const startClippedByAdvancement = compareTimestamps(calculateHintPenaltyFromTimestamp, start) >= 0 ? start : calculateHintPenaltyFromTimestamp;
          pauseAdjustmentInSeconds -= secondsSinceTimestamp(startClippedByAdvancement) - secondsSinceTimestamp(end);
        }
      });

      const hintPenaltyTimestampAdjustedForPause = subtractSecondsFromTimestamp(calculateHintPenaltyFromTimestamp, pauseAdjustmentInSeconds);
      return game?.getHintPenaltyInMinutes(hintPenaltyTimestampAdjustedForPause);
    } else {
      return game?.getHintPenaltyInMinutes(newFirestoreTimeStamp());
    }
  };

  const getWalkthroughPenalty = () => {
    return game?.getWalkthoughPenaltyInMinutes(lastAdvancedTimestamp);
  };

  const takeHint = async (hint) => {
    try {
      const updatePenaltyArgs = {
        team,
        hintId: hint.id,
        hintServed: true,
      };

      if (hint.isWalkthrough) {
        updatePenaltyArgs.penaltyAmountInSeconds = getWalkthroughPenalty() * 60;
        updatePenaltyArgs.penaltyType = TEAM_PENALTIES.WALK_THROUGH;
      }

      await Promise.all([
        notifyPlayersOfPenalty(hint),
        updateMostRecentPenaltyFromPlayer(updatePenaltyArgs),
      ]);
    } catch (e) {
      popError(e.message);
    }
  };

  const getHintWillUnlockTimeInMin = () => {
    let lockTime = null;
    let timestamp;
    if (disabledHintButtonReason === HINT_DISABLED_REASONS.HINT_LOCK_STARTING ||
      disabledLiveHintButtonReason === HINT_DISABLED_REASONS.HINT_LOCK_STARTING
    ) {
      lockTime = game.hintLocking.minutes;
      timestamp = lastAdvancedTimestamp;
    } else if (
      disabledHintButtonReason === HINT_DISABLED_REASONS.HINT_LOCK_TOOK_HINT ||
      disabledLiveHintButtonReason === HINT_DISABLED_REASONS.HINT_LOCK_TOOK_HINT
    ) {
      lockTime = game.hintLocking.fromTakenHintMinutes;
      timestamp = mostRecentHintTaken.createdAt;
    } else if (
      disabledHintButtonReason === HINT_DISABLED_REASONS.HINT_LOCK_TOOK_LIVE_HINT ||
      disabledLiveHintButtonReason === HINT_DISABLED_REASONS.HINT_LOCK_TOOK_LIVE_HINT
    ) {
      lockTime = game.hintLocking.fromLiveHintMinutes;
      timestamp = mostRecentHintTaken.createdAt;
    }

    if (lockTime) {
      return Math.max(lockTime - minutesSinceTimestamp(timestamp), 0);
    } else {
      return null;
    }
  }

  const askTakeHint = (options) => {
    const {isLiveHint} = options;
    const lockTimeMin = isLiveHint ? game?.hintLocking.fromLiveHintMinutes : game?.hintLocking.fromTakenHintMinutes;

    if (!isLiveHint && game?.hintLocking?.enabled && liveHintsEnabled(game)) {
      // Created for compass 2023
      popConfirm(
        appContent.inGame.hints.startHintFlowConfirmationCompass({lockTimeMin}),
        () => takePenaltyAndStartHintFlow({isLiveHint}),
        {disableForSeconds: DELAY_HINT_CONFIRM_FOR_SECONDS},
      );
    } else if (availableHints.length > 0) {
      popConfirm(
        game?.hintLocking.enabled ?
          appContent.inGame.hints.startHintFlowConfirmationHintLock({
            lockTimeMin,
            walkthroughAvailable: walkthroughAvailable,
            isLiveHint,
          }) :
          appContent.inGame.hints.startHintFlowConfirmation({
            hintPenalty: splitMinutes(getHintPenalty()),
            walkthroughPenalty: splitMinutes(getWalkthroughPenalty()),
            walkthroughAvailable: walkthroughAvailable,
            isLiveHint,
          }),
        () => takePenaltyAndStartHintFlow({isLiveHint}),
        {disableForSeconds: DELAY_HINT_CONFIRM_FOR_SECONDS},
      );
    } else {
      // Ask about walkthrough
      popConfirm(
        game?.hintLocking.enabled ?
          appContent.inGame.hints.startHintFlowConfirmationWithOnlyWalkthroughHintLock({
            lockTimeMin,
            walkthroughAvailable: walkthroughAvailable,
            isLiveHint,
          }) :
          appContent.inGame.hints.startHintFlowConfirmationWithOnlyWalkthrough(
            splitMinutes(getHintPenalty()),
            splitMinutes(getWalkthroughPenalty()),
            isLiveHint,
          ),
        () => takePenaltyAndStartHintFlow({immediatelyTakeWalkthroughAndSkipFlow: true, isLiveHint}),
        {disableForSeconds: DELAY_HINT_CONFIRM_FOR_SECONDS},
      );
    }

  };

  const takePenaltyAndStartHintFlow = async (options = {}) => {
    const {immediatelyTakeWalkthroughAndSkipFlow, isLiveHint} = options;
    try {
      if (isLiveHint) {
        // Send Slack Message
        const sentSuccessfully = await triggerSendSlackMessageWithWebhook({
          game,
          team,
          clue,
          lastAdvancedTimestamp,
        })
        if (!sentSuccessfully) {
          throw new Error(appContent.inGame.hints.failedToSendSlackMessage);
        } else {
          console.log('Sent Slack Message');
        }

        // Apply Penalty
        await takePenalty({
          team,
          penaltyAmountInSeconds: getHintPenalty() * 60,
          penaltyType: TEAM_PENALTIES.LIVE_HINT,
          clue: clue,
          hintId: null,
          hintServed: true,
          lockHintsForMins: game.hintLocking.enabled ? game.hintLocking.fromLiveHintMinutes : null,
        });

        // Notify players of penalty
        await notifyPlayersLiveHintTaken();
        return;
      }

      if (!moreHintsRemain) {
        throw new Error(appContent.inGame.hints.failedToSendLiveHintError);
      }

      if (immediatelyTakeWalkthroughAndSkipFlow) {
        await takePenalty({
          team,
          penaltyAmountInSeconds: getWalkthroughPenalty() * 60,
          penaltyType: TEAM_PENALTIES.WALK_THROUGH,
          clue: clue,
          hintId: walkthrough.id,
          hintServed: true,
          lockHintsForMins: game.hintLocking.enabled ? game.hintLocking.fromTakenHintMinutes : null,
        });
        await notifyPlayersOfPenalty(walkthrough);
      } else {
        await takePenalty({
          team,
          penaltyAmountInSeconds: getHintPenalty() * 60,
          penaltyType: TEAM_PENALTIES.HINT,
          clue: clue,
          lockHintsForMins: game.hintLocking.enabled ? game.hintLocking.fromTakenHintMinutes : null,
        });
        startAskingHints();
      }
    } catch (e) {
      popError(e.message);
    }
  };

  const startAskingHints = () => {
    setHintQuestionStack(availableHints);
    setPreviousHint(null);

    // If there are no hints but there is a walkthrough, skip to it.
    if (availableHints.length === 0 && hasWalkthrough) {
      setShowWalkthrough(true);
    }
  };
  const resetHints = () => {
    setShowWalkthrough(false);
    setHintQuestionStack(null);
    setPreviousHint(null);
  };
  const answerHint = async (answeredYes) => {
    if (answeredYes) {
      await takeHint(currentHint);
      resetHints();
    }
    // Show next question if there is one
    else if (hintQuestionStack.length > 1) {
      setPreviousHint(currentHint);
      setHintQuestionStack(hintQuestionStack.slice(1));
    } else if (hasWalkthrough) {
      setShowWalkthrough(true);
    }
  };
  const goToPreviousHint = () => {
    if (showWalkthrough) {
      setShowWalkthrough(false);
    } else if (previousHint) {
      setHintQuestionStack([
        previousHint,
        ...hintQuestionStack,
      ]);
      setPreviousHint(null);
    }
  };

  const getProgressText = () => {
    if (currentHint && !currentHint?.isWalkthrough) {
      const currentHintIndex = hints.findIndex(h => h.id === currentHint?.id) + 1;
      return `Hint ${currentHintIndex} of ${hints?.length}`;
    }
  };


  const notifyPlayersOfPenalty = (hint) => {
    return sendAlertToTeams([team],
      {
        title: hint.isWalkthrough ?
          appContent.inGame.hints.walkthroughDisplayTitle(userProfile?.name) :
          appContent.inGame.hints.hintDisplayTitle(userProfile?.name),
        message: undefined,
        additionalOptions: {
          overrideDisappearAfter: ALERTS_DISAPPEAR_AFTER_MS,
        },
      },
    );
  };

  const notifyPlayersLiveHintTaken = () => {
    return sendAlertToTeams([team],
      {
        title: appContent.inGame.hints.liveHintDisplayTitle(userProfile?.name),
        message: undefined,
        additionalOptions: {
          overrideDisappearAfter: ALERTS_DISAPPEAR_AFTER_MS,
        },
      },
    );
  };

  return {
    // askTakeHint
    //   function pops an alert asking if player wants to take a hint. If player chooses to take a hint the hint flow
    //   will be kicked off. Penalties will immediately be taken and other players on the team will be blocked from
    //   taking hints.
    askTakeHint,
    // clue
    //   an instance of the current clue entity processed by useCurrentClueLogic
    clue,
    // currentHint
    //   an instance of the current hint that the prompt is asking a question for
    currentHint,
    // showingHintQuestion
    //   a player should be in the hint flow
    showingHintQuestion,
    // hasHints
    //   true if this clue has hints
    hasHints: hints?.length > 0 || hasWalkthrough,
    // moreHintsRemain
    //   true if this team has more hints they can take.
    moreHintsRemain,
    //
    // canGoBack
    //   true if player can navigate back a hint. Currnetly it's setup so a player should only be able to go
    //   back once unless on a walkthrough.
    //
    canGoBack: !!previousHint,
    // disableNoAnswer
    //   true if skipping this hint would prevent any hints from being taken.
    disableNoAnswer: (
      (onLastHint && !hasWalkthrough) ||
      showWalkthrough
    ),
    // disableYesAnswer
    //   prevents this hint from being taken. Currently not used.
    disableYesAnswer: false,
    // answerHint
    //   function to be called when player chooses to take or skip the hint.
    answerHint,
    // goToPreviousHint
    //   function to be called when player chooses to go back to the previous hint
    goToPreviousHint,
    // getHintPenalty
    //   function to be called to get the most recent hint penalty taken. The most recent hint penalty will
    //   be the penalty for the current hint flow when showingHintQuestion.
    getHintPenalty,
    // getWalkthroughPenalty
    //   function to get the walkthrough penalty which is a higher time penalty than regular hints.
    getWalkthroughPenalty,
    // getHintWillUnlockTimeInMin
    //   function to get when hint button will unlock. Used with the GameOptions.HINT_LOCKING_MINUTES functionality.
    getHintWillUnlockTimeInMin,
    // disableHintButton
    //   true if players are not allowed to enter the hint flow
    disableHintButton,
    // disabledHintButtonReason
    //   a string indicating the reason the button is disabled
    disabledHintButtonReason,
    // playerNameTakingHint
    //   string containing the player's name taking the hint
    playerNameTakingHint: !!mostRecentHintTaken?.isAnUnfinishedHint() && team?.getTeamMemberName(mostRecentHintTaken.createdBy),
    // progressText
    //   text containing the players position in the hint flow. Ex: Hint 1 of 2
    progressText: getProgressText(),
    // takePenaltyAndStartHintFlow
    //   function to start hint flow. Penalties will immediately be taken and other players on the team will be blocked from
    //   taking hints. Generally you should be using askTakeHint instead which will triggers this functionality after
    //   a confirmation popup.
    takePenaltyAndStartHintFlow,
    // onLastHintBeforeAWalkthrough
    //   true if player is viewing the last hint question before a walkthrough. In this case the see walkthrough button
    //   should be shown.
    onLastHintBeforeAWalkthrough: onLastHint && hasWalkthrough,
    // liveHintButton
    liveHintButton: {
      featureEnabled: liveHintsEnabled(game),
      enabledForTeam: liveHintsEnabledForTeam(game, team) ?? false,
      disabledReason: disabledLiveHintButtonReason,
      disabled: Boolean(disabledLiveHintButtonReason),
      takeLivePenalty: () => alert("To be implemented"),
    },
    walkthroughAvailable,
  };
};

export default useHintQuestionsFlow;
