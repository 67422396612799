import React, {useContext, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import Clue from "../../entities/clue";
import {Button, Form} from "react-bootstrap";
import {ClueView, FormGroup, InlineAlert} from "../components";
import {AppAlertsContext} from "../../context/app-alerts-context";
import {AlertType} from "../providers/app-alerts/AppAlerts";
import GameContext from "../../context/game-context";
import HintButton from "../../app/game/in-game/hint-button/HintButton";
import ClueHintsTakenView from "../../app/game/in-game/clue-hints-taken-view/ClueHintsTakenView";
import {TeamStatus} from "../../entities/team";
import {ALERTS_DISAPPEAR_AFTER_MS, ANSWER_THROTTLE_TIME} from "../../config/config-options";
import appContent from "../../markdown/app-content";
import {answerQuestion} from "../../services/firestore/teams";
import ClueContentRevealView from "../../app/game/in-game/clue-content-reveal-view/ClueContentRevealView";
import {useShouldShowAnswerInput} from "../../hooks/useShouldShowAnswerInput";

const InGameClue = ({clue}) => {
  const [answerText, setAnswerText] = useState("");
  const [viewingClue, setViewingClue] = useState(null);
  const [contentRevealAlert, setContentRevealAlert] = useState(null);
  const [incorrectAlert, setIncorrectAlert] = useState(null);
  const {popDismissibleAlert, popError} = useContext(AppAlertsContext);
  const {game, team} = useContext(GameContext);
  const clueHasAnswer = clue && clue.answers.length > 0;
  const teamHasFinished = team?.findTeamStatus(game) === TeamStatus.FINISHED;
  const clueViewRef = useRef(null);
  const [throttlingAnswer, setThrottlingAnswer] = useState(false);
  const shouldShowAnswerInput = useShouldShowAnswerInput({clue, team});

  // Scroll to top of the page when the clue changes
  useEffect(() => {
    // Handle when clue changes
    if (viewingClue !== clue?.id) {
      setViewingClue(clue?.id);
      window.scrollTo(0, 0);
    }
  }, [clue, viewingClue]);

  const handleAnswer = async (event) => {
    event.preventDefault();

    try {
      const {
        isCorrect,
        answerHasRevealedContent,
        answerMatchesContentReveal,
      } = await answerQuestion({
        game,
        team,
        clue,
        answer: answerText,
        usedLink: false,
      });

      if (!isCorrect) {
        handleLocalAnswerNotifications({
          isCorrect,
          answerHasRevealedContent,
          answerMatchesContentReveal,
        });
      }

      if (isCorrect || answerHasRevealedContent) {
        setAnswerText(""); // clear input
      }
    } catch (e) {
      popError(e.message);
    }

    setThrottlingAnswer(true);
    setTimeout(() => {
      setThrottlingAnswer(false);
    }, ANSWER_THROTTLE_TIME);
  };

  const handleLocalAnswerNotifications = ({isCorrect, answerMatchesContentReveal, answerHasRevealedContent}) => {
    if (isCorrect) {
      return;
    }

    // Reveal content alert
    if (answerHasRevealedContent) {
      setContentRevealAlert({
        id: Date.now().toString(),
        message: appContent.alerts.contentRevealed,
        variant: AlertType.SUCCESS,
      });
      return;
    }

    // Reveal content already used alert
    if (!answerHasRevealedContent && answerMatchesContentReveal) {
      setContentRevealAlert({
        id: Date.now().toString(),
        message: appContent.alerts.contentRevealedUsed,
        variant: AlertType.WARNING,
      });
      return;
    }

    // Known wrong answer notification
    const knownWrongAnswer = clue.checkForKnownWrongAnswer(answerText);
    if (knownWrongAnswer) {
      popDismissibleAlert(knownWrongAnswer.content, AlertType.WARNING);
    }
    // Wrong answer notification
    else {
      setIncorrectAlert({
        id: Date.now().toString(),
        message: appContent.alerts.incorrectAnswer,
        variant: AlertType.ERROR,
      });
    }
  }

  return clue && <>
    <div ref={clueViewRef}>
      <ClueView className="mb-5" clue={clue}/>
    </div>
    <ClueContentRevealView clue={clue}/>
    <ClueHintsTakenView/>
    <InlineAlert className="mb-3" show={true} alert={contentRevealAlert} disappearAfter={ALERTS_DISAPPEAR_AFTER_MS}/>
    <InlineAlert className="mb-3" show={true} alert={incorrectAlert} disappearAfter={ALERTS_DISAPPEAR_AFTER_MS}/>
    {!teamHasFinished && (
      <div>
        {shouldShowAnswerInput && !team.hasAnsweredClue(clue.id) && (
          <Form
            onSubmit={handleAnswer}
          >
            <FormGroup
              label={appContent.inGame.inGame.inputs.answer.label}
              subText={appContent.inGame.inGame.inputs.answer.subText}
            >
              <Form.Control
                type="text"
                placeholder={appContent.inGame.inGame.inputs.answer.placeholder}
                onChange={e => setAnswerText(e.target.value)} value={answerText}
              />
            </FormGroup>
            <Button className="mb-3" variant="info" type="submit" block disabled={throttlingAnswer}>
              {appContent.inGame.inGame.answerSubmitText}
            </Button>
          </Form>
        )}
        <HintButton
          game={game}
          clue={clue}
        />
      </div>
    )}
  </>;
};

InGameClue.propTypes = {
  clue: PropTypes.instanceOf(Clue).isRequired,
};

export default InGameClue;
