import React from "react";
import PropTypes from "prop-types";
import {Button, Modal} from "react-bootstrap";
import {Markdown} from "../components";
import CountdownButton from "../countdown-button/CountdownButton";

const ConfirmModal = ({messageMarkdown, onConfirm, onHide, disableForSeconds, show, ...rest}) => {
  return <Modal size="sm" show={show} onHide={onHide} {...rest}>
    <Modal.Header closeButton>
      <Modal.Title>Confirm</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <Markdown>
        {messageMarkdown}
      </Markdown>
    </Modal.Body>

    <Modal.Footer className="d-flex flex-nowrap">
      <Button
        className="mt-0"
        variant="outline-info"
        block
        onClick={onHide}
      >
        No
      </Button>
      <CountdownButton
        countdown={show && disableForSeconds}
        className="mt-0"
        type="submit"
        variant="info"
        block
        onClick={onConfirm}
      >
        Yes
      </CountdownButton>
    </Modal.Footer>
  </Modal>;
};

ConfirmModal.propTypes = {
  messageMarkdown: PropTypes.string,
  onConfirm: PropTypes.func,
  onHide: PropTypes.func,
  show: PropTypes.bool,
  disableForSeconds: PropTypes.number,
};

export default ConfirmModal;
