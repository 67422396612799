import React, {useState} from "react";
import PropTypes from "prop-types";
import useInterval from "../../hooks/useInterval";
import {prettyTimeSinceTimestamp} from "../../utilities/dates";
import firebase from "firebase";

const TimeSince = ({timestamp}) => {
  const [timeSince, setTimeSince] = useState(prettyTimeSinceTimestamp(timestamp));

  useInterval(() => {
    setTimeSince(prettyTimeSinceTimestamp(timestamp));
  }, 1000);

  return <span>{timeSince}</span>
}

TimeSince.propTypes = {
  timestamp: PropTypes.instanceOf(firebase?.firestore?.Timestamp),
}

export default TimeSince;
