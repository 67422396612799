import React, {useState} from "react";
import PropTypes from "prop-types";
import Team from "../../../../entities/team";
import Game from "../../../../entities/game";
import {prettySecondsAsMinutes} from "../../../../utilities/dates";
import useInterval from "../../../../hooks/useInterval";
import appContent from "../../../../markdown/app-content";

const TeamAdjustmentsSummary = ({game, team}) => {
  const [totalTime, setTotalTimeInSeconds] = useState(team.getAdjustmentsTimeInSeconds(game));
  useInterval(() => setTotalTimeInSeconds(team.getAdjustmentsTimeInSeconds(game)), 500);

  const hintsTaken = team.getTotalHintsTaken() || 0;
  const bonusesTaken = team.coinPhrasesFound.length || 0;
  const hasAdjustments = Boolean(totalTime);
  const excludedTime = team.getExcludedTimesInSeconds(game) || 0;
  const adjustmentContent = appContent.modals.leaderboardTeam.adjustments;


  return <dl>
    <dt>{adjustmentContent.label}</dt>
    {!hasAdjustments && <dd>{adjustmentContent.noAdjustments}</dd>}
    {hasAdjustments && <>
      {hintsTaken !== 0 && <dd>{adjustmentContent.hints(`+${prettySecondsAsMinutes(team.getPenaltyTimeInSeconds(game))}`, hintsTaken)}</dd>}
      {bonusesTaken !== 0 && <dd>{adjustmentContent.bonus(`-${prettySecondsAsMinutes(team.getBonusTimeInSeconds(game))}`, bonusesTaken)}</dd>}
      {team.hasBeenPaused && (<dd>{adjustmentContent.pause(`-${prettySecondsAsMinutes(team.getPausedForSeconds())}`)}</dd>)}
      {excludedTime !== 0 && <dd>{adjustmentContent.excluded(`-${prettySecondsAsMinutes(excludedTime)}`)}</dd>}
      <dd className="pt-1">{adjustmentContent.total(prettySecondsAsMinutes(totalTime))}</dd>
    </>}
  </dl>;
};

TeamAdjustmentsSummary.propTypes = {
  game: PropTypes.instanceOf(Game),
  team: PropTypes.instanceOf(Team),
};

export default TeamAdjustmentsSummary;
