const homeActiveGameMarkdown = `
# Welcome, Challengers.
You are about to embark on a test of wits, will, intelligence, and resourcefulness. You and your team shall be led through the adventure, mystery, and intrigue of the Great city of Gotham, competing on a course that was designed and tailored specifically for you. The first team to complete the challenge and arrive at the finish line party shall claim the year's victory, winning the honor and acclaim of your peers.
`;

// const homeActiveGameMarkdown = `
// # Welcome, A.P.E. Special Contractors.
// Today’s project will test your wits, will, intelligence, and resourcefulness. You and your team will be called upon to comb the city for evidence of the Simian Path’s whereabouts, in an effort to retrieve Dr. Gibbons’ stolen M3 Serum. The first team to of contractors to follow every lead sent by the A.P.E. staff and return with the needed information will be greatly rewarded.
// `;

export default homeActiveGameMarkdown;
