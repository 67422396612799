import React from "react";
import PropTypes from "prop-types";
import Clue from "../../entities/clue";
import {Form} from "react-bootstrap";

const EditExcludeTimesSwitch = ({className, clue, onChange}) => {
  if (!clue) {
    return null;
  }

  return <Form.Group className={className}>
    <div className="form-label">
      Exclude Clue Time and Penalties From Leaderboard
    </div>
    <Form.Check
      type="switch"
      id="clue-time-exclusion"
      label={"Times Excluded"}
      checked={clue.excludeTimes}
      onChange={(event) => {
        onChange(new Clue(clue.id, {
          ...clue._rawClueData,
          excludeTimes: event.target.checked,
        }));
      }}
    />
  </Form.Group>;
};

EditExcludeTimesSwitch.propTypes = {
  className: PropTypes.string,
  clue: PropTypes.instanceOf(Clue),
  onChange: PropTypes.func.isRequired,
};

export default EditExcludeTimesSwitch;
