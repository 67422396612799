import React, {useEffect, useState} from "react";
import GameContext from "../../../context/game-context";
import useFirebaseGame from "../../../hooks/useFirebaseGame";
import useFirebaseTeam from "../../../hooks/useFirebaseTeam";
import {mostRecentGameAndTeamId} from "../../../services/firestore/player-data";
import useFirebaseGameOnHomePage from "../../../hooks/useFirebaseGameOnHomePage";

const GameProvider = ({children}) => {
  const [gameId, setGameId] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const {game, isLoading: isLoadingGame} = useFirebaseGame(gameId);
  const {team, isLoading: isLoadingTeam} = useFirebaseTeam(gameId, teamId);
  const {gameOnHomePage, isLoadingGameOnHomePage} = useFirebaseGameOnHomePage();
  const [refreshCheck, setRefreshCheck] = useState(null);

  useEffect(() => {
    const subscription = mostRecentGameAndTeamId().subscribe(({gameId, teamId}) => {
      setGameId(gameId);
      setTeamId(teamId);
    });
    return () => subscription.unsubscribe();
  }, [refreshCheck]);

  return <GameContext.Provider value={{
    refreshQuery: () => setRefreshCheck(Date.now()), // hack to force a fetch faster than subscriptions update
    gameOnHomePage,
    isLoadingGameOnHomePage,
    game,
    team,
    isLoading: isLoadingGame || isLoadingTeam,
  }}>
    {children}
  </GameContext.Provider>;
};

export default GameProvider;
