import { useEffect, useState } from "react";
import useIsLoading from "../hooks/useIsLoading";
import { getCurrentPlayerData } from "../services/firestore/player-data";

export default function useFirebasePlayerData(gameId, teamId) {
  const {isLoading, setIsLoading} = useIsLoading(true);
  const [playerData, setPlayerData] = useState();

  useEffect(() => {
    if (!gameId || !teamId) {
      return;
    }

    const subscription = getCurrentPlayerData(gameId, teamId).subscribe(playerData => {
      setPlayerData(playerData);
      setIsLoading(false);
    });
    return () => subscription.unsubscribe();
  }, [gameId, teamId, setIsLoading])

  return {
    isLoading,
    playerData,
  };
}
