class UserProfile {
  constructor(userProfileData) {
    this.id = userProfileData.id;
    this.name = userProfileData.name;
    this.email = userProfileData.email;
    this.phoneNumber = userProfileData.phoneNumber;
    this.additionalFields = userProfileData.additionalFields || {};
  }
}

export default UserProfile;
