import { useEffect, useState } from "react";
import { usePreviousValue } from "./usePreviousValue";

export default function useIsLoading(isLoadingInitially = false) {
  const [isLoading, setIsLoading] = useState(isLoadingInitially);
  const [loadingCount, setLoadingCount] = useState(isLoadingInitially ? 1 : 0);
  const previousLoadingState = usePreviousValue(isLoading);

  // Cancel loading for components who started out as loading but aren't resolved
  // after INITIALIZATION_TIMEOUT. Used for queries that only execute if certain
  // required data is met.
  const INITIALIZATION_TIMEOUT = 1500;
  useEffect(() => {
    if (isLoadingInitially) {
      const timeout = setTimeout(() => {
        setIsLoading(false);
      }, INITIALIZATION_TIMEOUT);

      return () => clearTimeout(timeout);
    }
  }, [isLoadingInitially]);

  useEffect(() => {
    if (previousLoadingState === false && isLoading) {
      setLoadingCount(loadingCount + 1)
    }
  }, [previousLoadingState, isLoading, loadingCount]);

  return {
    isLoading,
    isLoadingForTheFirstTime: isLoading && loadingCount === 1,
    setIsLoading,
  };
}
