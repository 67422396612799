import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {smsHistory} from "../../../services/firestore/sms-alerts";
import {Table} from "react-bootstrap";
import {TimeSince} from "../../../components/components";
import Game from "../../../entities/game";
import appContent from "../../../markdown/app-content";

const SmsHistory = ({game, ...rest}) => {
  const [smsHistoryData, setSmsHistoryData] = useState([]);
  const [realtimeSidStatus, setRealtimeSidStatus] = useState({});

  useEffect(() => {
    const subscriptions = [];
    subscriptions.push(smsHistory(game).subscribe(smsAlerts => {
      setSmsHistoryData(smsAlerts);
      let realtimeDbData = {};

      // Listen to delivered status
      smsAlerts.forEach(smsAlert => {
        subscriptions.push(smsAlert.getRealtimeStatus()?.subscribe(d => {
          realtimeDbData = {
            ...realtimeDbData,
            ...d,
          }
          setRealtimeSidStatus(realtimeDbData);
        }));
      });
    }));

    return () => subscriptions.forEach(s => {
      s?.unsubscribe()
    });
  }, [game]);

  const deliveryText = (sids = []) => {
    let sidsDelivered = 0;
    sids.forEach(sid => {
      if (
        realtimeSidStatus[sid]?.status === "delivered" ||
        realtimeSidStatus[sid]?.status === "sent"
      ) {
        sidsDelivered++;
      }
    })

    return `${sidsDelivered} / ${sids.length}`
  }

  if (smsHistoryData.length === 0) {
    return null;
  } else {
    return <div {...rest}>
      <h2>{appContent.cluemaster.game.alertTab.smsHistoryTitle}</h2>
      <Table variant="dark">
        <thead>
        <tr>
          <th>{appContent.cluemaster.game.alertTab.smsHistoryMessageLabel}</th>
          <th className="text-center">{appContent.cluemaster.game.alertTab.smsHistoryDeliveredLabel}</th>
          <th className="text-center">{appContent.cluemaster.game.alertTab.smsHistorySentToLabel}</th>
          <th className="text-center">{appContent.cluemaster.game.alertTab.smsHistorySentLabel}</th>
        </tr>
        </thead>
        <tbody>
        {smsHistoryData.map(d => <tr key={d.id}>
          <td>
            {d.title && <div><b>{d.title}</b></div>}
            {d.message}
          </td>
          <td className="text-no-wrap text-center">
            {!d.sentInAppOnly ? <span>{deliveryText(d.sids)}</span> : <span>{appContent.cluemaster.game.alertTab.smsLabelForInAppAlert}</span>}
          </td>
          <td className="text-center">{d.blastOption}</td>
          <td className="text-no-wrap text-center"><TimeSince timestamp={d.createdAt} /></td>
        </tr>)}
        </tbody>
      </Table>
    </div>
  }
};

SmsHistory.protoTypes = {
  game: PropTypes.instanceOf(Game),
}

export default SmsHistory;
