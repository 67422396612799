import PropTypes from "prop-types";
import Game from "../../../entities/game";
import React, {useContext, useState} from "react";
import {AppAlertsContext} from "../../../context/app-alerts-context";
import {setGameOption} from "../../../services/firebase";
import {InputModal, Link} from "../../../components/components";
import {Form} from "react-bootstrap";

export const EditableGameOption = ({game, options, optionLabel, className, subText, secondaryFooterSlot, children}) => {
  const {popError} = useContext(AppAlertsContext);
  const [showEdit, setShowEdit] = useState(false);

  const handleSetOption = (data) => {
    try {
      options.forEach((option) => {
        const parseValue = option.mutateValue ? option.mutateValue : (v) => v;
        setGameOption(game, option.gameOption, parseValue(data[option.label])).catch(e => popError(e.message));
      });
      setShowEdit(false);
    } catch (e) {
      popError(e.message);
    }
  };

  return <>
    <div className={className}>
      <span className="mr-2">{optionLabel}:</span>
      <Link onClick={() => {
        setShowEdit(true);
      }}>
        {children}
      </Link>
      {subText && <Form.Text className="mt-0 text-muted">
        {subText}
      </Form.Text>}
    </div>
    <InputModal
      show={showEdit}
      onHide={() => setShowEdit(false)}
      onFinish={handleSetOption}
      title={`Edit ${optionLabel}`}
      buttonText="Save"
      inputs={options.map(o => (
        {
          label: o.label,
          formControlProps: o.formControlProps,
          defaultValue: game?.getOption(o.gameOption),
          subText: o.subText,
        }
      ))}
      secondaryFooterSlot={secondaryFooterSlot}
    />
  </>
}

EditableGameOption.prototype = {
  game: PropTypes.instanceOf(Game),
  className: PropTypes.string,
  optionLabel: PropTypes.string.isRequired,
  subText: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    gameOption: PropTypes.string.isRequired,
    formControlProps: PropTypes.object.isRequired,
    subText: PropTypes.string,
    mutateValue: PropTypes.func,
  })).isRequired,
  secondaryFooterSlot: PropTypes.func, // passes inputValues prop
  children: PropTypes.node.isRequired,
};