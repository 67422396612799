import {useEffect, useState} from "react";
import {getLeaderboardData} from "../../services/leaderboard/leaderboard";

export default function useLeaderboardTableData(game, teams = []) {
  const [leaderboardData, setLeaderboardData] = useState([]);

  useEffect(() => {
    setLeaderboardData(
      getLeaderboardData(game, teams),
    );
  }, [game, teams]);

  return leaderboardData;
}
