import React from "react";
import PropTypes from "prop-types";
import Team from "../../../../entities/team";
import classNames from "classnames";
import "./team-player-list.scss";

const TeamPlayerList = ({team, label, className, ...rest}) => {
  const c = classNames([className, "team-player-list"])

  return <dl className={c} {...rest}>
    {label && <dt>{label}</dt>}
    {team?.orderedUsers.map(user => (
      <dd key={user.id} className="team-player-list__item">
        {user.name}
      </dd>
    ))}
  </dl>
};

TeamPlayerList.propTypes = {
  team: PropTypes.instanceOf(Team),
  label: PropTypes.string,
};

export default TeamPlayerList;
