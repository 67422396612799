import KnownWrongAnswer from "./known-wrong-answer";
import {allLowerCaseAndNoWhitespace} from "../utilities/helper-functions";
import {GameOptions} from "./game";

class Clue {
  constructor(clueId, clueData) {
    this._rawClueData = clueData;
    this.id = clueId;
    this.excludeTimes = clueData.excludeTimes || false;
    this.name = clueData.name + (this.excludeTimes ? " ⊖" : "");
    this.content = clueData.content;
    this.contentFormatting = clueData.contentFormatting || [];
    this.answers = clueData.answer || [];
    this.knownWrongAnswers = (clueData.knownWrongAnswers || []).map(pipedAnswerContent => new KnownWrongAnswer(pipedAnswerContent));
    this.disabled = clueData.disabled || false;
    this.hintIds = clueData.masterCluesHints || [];
    this.contentRevealIds = clueData.masterCluesContentReveal || [];
    this.challenge = clueData.challenge || [];
    this.rawTimeLimitMinutes = clueData.timeLimitMinutes || null;
  }

  hasAnswers() {
    return this.answers.length !== 0;
  }

  checkAnswer(answer) {
    return !!this.answers.find(
      a => Clue.compareAnswers(a, answer),
    );
  }

  checkForKnownWrongAnswer(answer) {
    return this.knownWrongAnswers.find(wrongAnswer => {
      return wrongAnswer.checkAnswer(answer);
    }) || null;
  }

  getTimeLimitMinutes(game) {
    if (!game) {
      throw new Error("No game provided to check getTimeLimitMinutes");
    }

    if (!game.getOption(GameOptions.TIME_LIMIT_ENABLED)) {
      return null;
    }

    if (this.rawTimeLimitMinutes && this.rawTimeLimitMinutes !== "0") {
      return Number(this.rawTimeLimitMinutes);
    } else {
      return null;
    }
  }

}

Clue.compareAnswers = (answerA, answerB) => {
  return allLowerCaseAndNoWhitespace(answerA) === allLowerCaseAndNoWhitespace(answerB);
};

export default Clue;
