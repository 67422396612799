import React from "react";
import PropTypes from "prop-types";

const LinkNewTab = ({to, children, ...rest}) => {
  return <a href={to} target="_blank" {...rest}>{children}</a>
}

LinkNewTab.prototype = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default LinkNewTab;