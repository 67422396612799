import {collection, docData} from "rxfire/firestore";
import {authState} from "rxfire/auth";
import {map, switchMap, first} from "rxjs/operators";
import firebase from "firebase/app";
import firebaseApp from "./firebase-app";
import Game, {GameImageOverrides, GameOptions} from "../entities/game";
import UserProfile from "../entities/user-profile";
import Team from "../entities/team";
import {newFirestoreTimeStamp} from "../utilities/dates";
import {compareValues, isEmptyObject} from "../utilities/helper-functions";
import Clue from "../entities/clue";
import Hint from "../entities/hint";
import isEqual from "lodash/isEqual";

export const getCurrentUserID = () => firebaseApp.auth().currentUser?.uid;
export const gameCollection = () => firebaseApp.firestore().collection("games");
export const teamCollection = (gameId) => gameCollection().doc(gameId).collection("teams");

export const clueMasterLogin = (email, password) => {
  return firebaseApp.auth().signInWithEmailAndPassword(email, password);
};

export const getAuth = () => {
  return authState(firebaseApp.auth());
};

export const authLogout = () => {
  return firebaseApp.auth().signOut();
};

export const getAllMasterClues = () => {
  return firebaseApp.functions().httpsCallable("getClues")({}).then(r => {
    const {clues, challengeNames} = r.data || {};
    return {
      clues: clues.map(clueData => new Clue(clueData.id, clueData)),
      challengeNames,
    };
  });
};

export const getMasterHints = (ids = []) => {
  return firebaseApp.functions().httpsCallable("getHintsByIds")({
    hintIds: ids,
  }).then(r => {
    const hints = r.data || [];
    return hints.map(hintData => new Hint(hintData)).sort((a, b) => compareValues(b.sequence, a.sequence));
  });
};

export const getAllChallenges = () => {
  return firebaseApp.functions().httpsCallable("getChallenges")({}).then(r => r.data);
};

export const createGame = (name, challenge, additionOptions) => {
  const data = {
    name,
    challengeId: challenge.id,
    trackIDs: challenge.tracks || [],
    isHostedByClueMaster: additionOptions[GameOptions.IS_HOSTED],
    shouldCollectPhoneNumbers: additionOptions[GameOptions.COLLECT_PHONES],
  };

  return firebaseApp.functions().httpsCallable("createGameWithTracks")(data).then(r => r.data);
};

export const GET_ALL_GAMES_QUERIES = {
  PRIMARY_GAME: "get-primary-game",
  ALL_ACTIVE_GAMES: "get-all-active-games",
  ONE_ACTIVE_GAME: "get-active-games",
  NON_ARCHIVED: "get-non-archived-games",
  NOT_CLOSED: "get-not-closed-games",
  CLOSED_NOT_ARCHIVED: "get-closed-games",
};

export const getAllGames = (queries = []) => {
  let collectionRef = gameCollection();

  queries.forEach(query => {
    if (query === GET_ALL_GAMES_QUERIES.PRIMARY_GAME)
      collectionRef = collectionRef.where(`options.${GameOptions.IS_PRIMARY}`, "==", true);
    else if (query === GET_ALL_GAMES_QUERIES.ALL_ACTIVE_GAMES)
      collectionRef = collectionRef.where(`options.${GameOptions.IS_ACTIVE}`, "==", true);
    else if (query === GET_ALL_GAMES_QUERIES.ONE_ACTIVE_GAME)
      collectionRef = collectionRef.where(`options.${GameOptions.IS_ACTIVE}`, "==", true).limit(1);
    else if (query === GET_ALL_GAMES_QUERIES.NON_ARCHIVED)
      collectionRef = collectionRef.where(`options.${GameOptions.IS_ARCHIVED}`, "==", false);
    else if (query === GET_ALL_GAMES_QUERIES.NOT_CLOSED)
      collectionRef = collectionRef.where("hasClosed", "==", false);
    else if (query === GET_ALL_GAMES_QUERIES.CLOSED_NOT_ARCHIVED)
      collectionRef = collectionRef
        .where("hasClosed", "==", true)
        .where(`options.${GameOptions.IS_ARCHIVED}`, "==", false);
    else
      throw new Error("Query not valid");
  });

  return collection(collectionRef).pipe(
    map(docs => docs.map(d => {
        return new Game({id: d.id, ...d.data()});
      }).sort((a, b) => compareValues(b.name, a.name)),
    ),
  );
};

export const getGame = (gameId) => {
  return docData(
    gameCollection().doc(gameId),
  ).pipe(
    map(docData => {
      if (isEmptyObject(docData)) {
        return null;
      }

      return new Game({
        id: gameId,
        ...docData,
      });
    }),
  );
};

export const getTeamOnce = (gameId, teamId) => {
  return teamCollection(gameId).doc(teamId).get().then(doc => {
    return new Team(gameId, {
      id: doc.id,
      ...doc.data(),
    });
  });
};

const getTeamsCache = {};
export const getTeams = (gameId) => {
  return collection(teamCollection(gameId).orderBy("name"))
    .pipe(
      map(docs => {
        return docs.map(doc => {
          //
          // Check if team in cache has changed. If it hasn't, reuse it so React won't rerender
          //
          if (
            getTeamsCache[doc.id] &&
            isEqual(
              {
                id: doc.id,
                ...doc.data(),
              },
              getTeamsCache[doc.id]._rawTeamData,
            )
          ) {
            return getTeamsCache[doc.id];
          }

          getTeamsCache[doc.id] = new Team(gameId, {
            id: doc.id,
            ...doc.data(),
          });
          return getTeamsCache[doc.id];
        });
      }),
    );
};

export const validUserIdSwitchMap = (observable) => {
  return authState(firebaseApp.auth()).pipe(
    switchMap(u => observable(u?.uid)),
  );
};

export const getTeam = (gameId, teamId) => {
  return docData(
    teamCollection(gameId).doc(teamId),
  ).pipe(
    map(doc => {
      return new Team(gameId, {
        id: teamId,
        ...doc,
      });
    }),
  );
};

export const updateGameInterstitial = async (game, content) => {
  if (!game instanceof Game) {
    return Promise.reject(new Error("Game entity expected"));
  }

  return gameCollection().doc(game.id).update({
    "interstitialContent": content,
  });
};

export const setGameOption = async (game, optionName, optionValue) => {
  const batch = firebaseApp.firestore().batch();

  if (!game instanceof Game) {
    return Promise.reject(new Error("Game entity expected"));
  }

  if (optionName === GameOptions.IS_PRIMARY && optionValue === true) {
    const primaryGames = await getAllGames([GET_ALL_GAMES_QUERIES.PRIMARY_GAME]).pipe(first()).toPromise();
    primaryGames.forEach(game => {
      batch.update(gameCollection().doc(game.id), {
        [`options.${optionName}`]: false,
      });
    });
  }

  if (optionName === GameOptions.IS_ACTIVE && optionValue === true) {
    batch.update(gameCollection().doc(game.id), {
      lastActivatedAt: newFirestoreTimeStamp(),
    });
  }

  batch.update(gameCollection().doc(game.id), {
    [`options.${optionName}`]: optionValue,
  });

  return batch.commit();
};

export const firestoreTimestamp = (timestamp) => {
  return new firebase.firestore.Timestamp(timestamp.seconds, timestamp.nanoseconds);
};

export const anonymousSignIn = ({name, email, phoneNumber, additionalFields = {}}) => {
  return firebaseApp.auth()
    .signInAnonymously()
    .then(() => {
      return new UserProfile({
        id: getCurrentUserID(),
        name,
        email,
        phoneNumber,
        additionalFields,
      });
    });
};

export const updateProfileOnTeam = (game, team, userProfile, additionalFields = {}) => {
  if (!userProfile instanceof UserProfile) {
    return Promise.reject(new Error("UserProfile entity expected"));
  }

  return firebaseApp.functions().httpsCallable("updateUserProfileForTeamMember")({
    gameId: game.id,
    teamId: team.id,
    userId: userProfile.id,
    userDisplayName: userProfile.name,
    userEmail: userProfile.email,
    userPhoneNumber: userProfile.phoneNumber,
    userAdditionalFields: additionalFields,
  });
};

export const assignTeamATrack = (gameId, teamId) => {
  // This records an assignedTeamTrack time
  return firebaseApp.functions().httpsCallable("assignTeamATrack")({
    gameId,
    teamId,
  }).then(r => r.data);
};

export const answeredQuestionOptions = {
  ANSWERED_WITH_LINK: "used-link",
};

export const finishedInterstitial = (team) => {
  return teamCollection(team.gameId).doc(team.id).update({
    finishedInterstitialAt: firebase.firestore.FieldValue.serverTimestamp(),
    finishedInterstitialBy: getCurrentUserID(),
  });
};

export const manuallyStartTeams = (teams = []) => {
  const batch = firebaseApp.firestore().batch();

  teams.forEach(team => {
    batch.update(teamCollection(team.gameId).doc(team.id), {
      manuallyStartedAt: firebase.firestore.FieldValue.serverTimestamp(),
      manuallyStartedBy: getCurrentUserID(),
    });
  });

  return batch.commit();
};

export const manuallySetTeamStartTime = (team, timestamp) => {
  return teamCollection(team.gameId).doc(team.id).update({
    manuallyStartedAt: timestamp,
    manuallyStartedBy: getCurrentUserID(),
  });
};

export const finishTeam = (team) => {
  if (team.isFinished) {
    console.warn("team is already finished");
  }

  // Guard against writing finished time more than once.
  return getTeamOnce(team.gameId, team.id).then(team => {
    if (!team._rawTeamData.finishedAt) {
      return teamCollection(team.gameId).doc(team.id).update({
        finishedAt: newFirestoreTimeStamp(),
        finishedBy: getCurrentUserID(),
      });
    }
  });
};

export const undoFinishTeam = (team) => {
  if (!team.isFinished) {
    console.warn("team is not finished");
  }

  return teamCollection(team.gameId).doc(team.id).update({
    finishedAt: firebase.firestore.FieldValue.delete(),
    finishedBy: firebase.firestore.FieldValue.delete(),
  });
};

export const updateGameHints = (game, hints = []) => {
  const batch = firebaseApp.firestore().batch();

  hints.forEach(hint => {
    batch.update(gameCollection().doc(game.id), {
      [`hints.${hint.id}`]: hint._rawHintData,
    });
  });
  batch.commit();
};

export const updateGameTheme = (game, themeContent) => {
  if (!game instanceof Game) {
    return Promise.reject(new Error("Game entity expected"));
  }

  gameCollection().doc(game.id).update({
    ["gameTheme.customHead"]: {
      content: themeContent,
      updatedAt: newFirestoreTimeStamp(),
      updatedBy: getCurrentUserID(),
    },
  });
};

export const updateGameThemeImages = (game, imageIdToSrcMap = {}) => {
  if (!game instanceof Game) {
    return Promise.reject(new Error("Game entity expected"));
  }

  const dataToWrite = {};
  Object.keys(imageIdToSrcMap).forEach(imageId => {
    const imageSrc = imageIdToSrcMap[imageId] || null;

    if (!Object.values(GameImageOverrides).includes(imageId)) {
      throw new Error(`Image id ${imageId} is not a known GameImageOverride.`);
    }

    dataToWrite[`gameTheme.customImages.${imageId}`] = {
      src: imageSrc,
      updatedBy: getCurrentUserID(),
      updatedAt: newFirestoreTimeStamp(),
    };
  });

  return gameCollection().doc(game.id).update(dataToWrite);
};

export const updateGameData = (game, data = {}) => {
  if (!game instanceof Game) {
    return Promise.reject(new Error("Game entity expected"));
  }
  return gameCollection().doc(game.id).update(data);
};

export const updateGameContentReveals = (game, contentRevealEntities = []) => {
  if (!game instanceof Game) {
    return Promise.reject(new Error("Game entity expected"));
  }

  const contentRevealData = {...(game._rawGameData.contentReveal || {})}; // clone
  contentRevealEntities.map(entity => {
    contentRevealData[entity.id] = entity._raw;
  });

  return gameCollection().doc(game.id).update({
    contentReveal: contentRevealData,
  });
};
