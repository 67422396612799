import React from "react";
import PropTypes from "prop-types"
import {Modal} from "react-bootstrap";
import {Markdown} from "../components";
import CountdownButton from "../countdown-button/CountdownButton";

const FooterActionsModal = ({
  title,
  titleRightSlot,
  content,
  hideCloseButton,
  buttons,
  additionalFooter,
  ...rest
}) => {
  return <Modal {...rest}>
    <Modal.Header closeButton={!hideCloseButton}>
      <div className="d-flex w-100 justify-content-between align-items-center">
        <Modal.Title>
          <span>{title}</span>
        </Modal.Title>
        {titleRightSlot && <div>{titleRightSlot}</div>}
      </div>
    </Modal.Header>

    <Modal.Body>
      <Markdown>
        {content}
      </Markdown>
    </Modal.Body>

    <Modal.Footer className="d-flex flex-nowrap align-items-end">
      {buttons.map((button, index) => {
        if (button.removeIfDisabled && button.disabled) {
          return null;
        }

        const isLast = index === buttons.length - 1;

        return <CountdownButton
          className={button.className}
          key={button.id}
          variant={button.variant || (isLast ? "info" : "outline-info")}
          onClick={button.onClick}
          disabled={button.disabled}
          block
          countdown={button.countdownSeconds || 0}
          >{button.text}</CountdownButton>
      })}
    </Modal.Footer>

    { additionalFooter && <Modal.Footer>
      {additionalFooter}
    </Modal.Footer> }
  </Modal>
}

FooterActionsModal.propTypes = {
  title: PropTypes.string,
  titleRightSlot: PropTypes.node,
  content: PropTypes.string,
  onHide: PropTypes.func,
  show: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
    countdownSeconds: PropTypes.bool,
    removeIfDisabled: PropTypes.bool,
    className: PropTypes.string,
  })).isRequired,
  additionalFooter: PropTypes.node,
}

export default FooterActionsModal;
