import React, { useContext, useState } from "react";
import propTypes from "prop-types";
import { Button } from "react-bootstrap";
import DisableClueModal, { DisableClueType } from "../../../../components/modals/DisableClueModal";
import Clue from "../../../../entities/clue";
import Game from "../../../../entities/game";
import { updateClue } from "../../../../services/firestore/clues";
import { ConfirmationModal } from "../../../../components/components";
import appContent from "../../../../markdown/app-content";
import { AppAlertsContext } from "../../../../context/app-alerts-context";
import { AlertType } from "../../../../components/providers/app-alerts/AppAlerts";
import Team from "../../../../entities/team";
import {
  keepTeamsOnDisabledClue,
  resetTeamsIgnoringADisabledClue,
} from "../../../../services/firestore/teams";
import Proptypes from "prop-types";

const DisableOrReEnableClueButton = ({clue, game, teamsOnClue = [], disabled}) => {
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [showReEnableConfirm, setShowReEnableConfirm] = useState(false);
  const [showAdvanceTeamsConfirm, setShowAdvanceTeamsConfirm] = useState(false);
  const {popAlert, popError} = useContext(AppAlertsContext);

  const handleEnableClue = async () => {
    try {
      await updateClue(game, new Clue(clue.id, {
        ...game.getClue(clue.id)._rawClueData,
        disabled: false,
      }));
      setShowReEnableConfirm(false);
      popAlert(`${clue.name} enabled successfully!`, AlertType.SUCCESS);
      resetTeamsIgnoringADisabledClue(game, clue.id);
    } catch (e) {
      popError(e.message);
    }

  }

  const handleAdvanceTeamsOnDisabledClue = async () => {
    try {
      await resetTeamsIgnoringADisabledClue(game, clue.id);
      popAlert(`Teams have been advanced successfully!`, AlertType.SUCCESS);
    } catch (e) {
      popError(e.message);
    }
  }

  const handleDisableClueType = async (disableType) => {
    if (Object.values(DisableClueType).indexOf(disableType) === -1) {
      throw new Error("handleDisableClueType requires a valid DisableClueType");
    }

    try {
      if (disableType === DisableClueType.TEAMS_NOT_ON_CLUE) {
        await keepTeamsOnDisabledClue(clue.id, teamsOnClue)
      }

      await updateClue(game, new Clue(clue.id, {
        ...game.getClue(clue.id)._rawClueData,
        disabled: true,
      }));
      popAlert(`${clue.name} disabled successfully!`, AlertType.SUCCESS);
    } catch (e) {
      popError(e.message);
    }
  }

  if (!clue) {
    return null;
  } else if (clue.disabled) {
    return <>
      {/* Enable Button */}
      <Button
        className="text-no-wrap mr-2 mb-1"
        size="sm"
        onClick={() => setShowReEnableConfirm(true)}
        disabled={disabled}
      >
        {appContent.cluemaster.game.clueTab.reEnableButtonText}
      </Button>
      <ConfirmationModal
        show={showReEnableConfirm}
        messageMarkdown={appContent.cluemaster.game.clueTab.reEnableConfirmMessage}
        onConfirm={handleEnableClue}
        onHide={() => setShowReEnableConfirm(false)}
      />
      {/* Advance Remaining Teams Button */}
      <Button
        size="sm"
        className="text-no-wrap mb-1"
        onClick={() => setShowAdvanceTeamsConfirm(true)}
        disabled={teamsOnClue < 1 || disabled}
      >
        {appContent.cluemaster.game.clueTab.pushTeamsOnDisabledClueText}
      </Button>
      <ConfirmationModal
        show={showAdvanceTeamsConfirm}
        messageMarkdown={appContent.cluemaster.game.clueTab.pushTeamsOnDisabledClueConfirmMessage}
        onConfirm={() => {
          handleAdvanceTeamsOnDisabledClue();
          setShowAdvanceTeamsConfirm(false)
        }}
        onHide={() => setShowAdvanceTeamsConfirm(false)}
      />
    </>
  } else {
    return <>
      {/* Disable Button */}
      <Button
        className="text-no-wrap"
        onClick={() => setShowDisableModal(true)}
        disabled={disabled}
      >
        {appContent.cluemaster.game.clueTab.disableButtonText}
      </Button>
      <DisableClueModal
        clue={clue}
        show={showDisableModal}
        onHide={() => setShowDisableModal(false)}
        onFinish={handleDisableClueType}
      />
    </>
  }
}

DisableOrReEnableClueButton.propTypes = {
  clue: propTypes.instanceOf(Clue),
  game: propTypes.instanceOf(Game),
  teamsOnClue: propTypes.arrayOf(propTypes.instanceOf(Team)),
  disabled: Proptypes.bool,
}

export default DisableOrReEnableClueButton;
