const termsAndConditionsMarkdown = `
This Release and Waiver of Liability Agreement (the “Release”) by and between YOU (the “Participant”) and Gotham Immersive Laboratories, hosting "The Great Gotham Challenge" (“GGC”) regarding Participant’s participation in the Great Gotham Challenge (the “Challenge”).

For good and valuable consideration, the receipt and sufficiency of which is hereby acknowledged, the parties agree as follows:

1. The Participant represents that he or she is at least eighteen years of age or has obtained the permission of his or her parent or legal guardian to participate in the Challenge, as acknowledged by such parent’s signature below.
2. The Participant understands that participation in the Challenge may include activities that may be hazardous, and the Participant expressly assumes the risk of injury or harm from these activities and hereby releases GGC from all liability for injury, illness, death, or property damage resulting from the Participant’s participation in the Challenge or occurring with the Participant is participating in the Challenge. The Participant understands that he or she is responsible for his or her own insurance coverage in the event of personal injury or illness as a result of participation in the Challenge.
3. The Participant understands that GGC does not assume any responsibility or obligation to provide the Participant with financial or other assistance, including, but not limited to, medical, health, or disability benefits or insurance of any nature in the event of injury, illness, death, or damage to my property. The Participant expressly waives any such claim for compensation or liability on the party of GGC in the event of such injury or medical expenses incurred by the Participant.
4. The Participant releases and forever discharges and holds harmless GGC and its directors, officers, employees, representatives, agents, successors, and assigns (collectively, the “GGC Releasees”) from any and all liability, claims, and demands of whatever kind of nature, either in law or in equity, which arise or may hereafter arise from the Participant’s participation in the Challenge. The Participant understands and acknowledges that this Release discharges the GGC Releases from any liability or claim that the Participant may have against the GGC Releasees with respect to bodily injury, personal injury, illness, death, property damage, cost, or expenses that may result from participation in the Challenge.
5. The Participant agrees to obey all traffic laws, state, federal, and local laws, and regulations. The Participant understands and agrees that if any team or member of a team that violates state, federal, or local laws, including, without limitation, traffic laws, is subject to immediate disqualification.
6. The Participant agrees to indemnify and hold the GGC Releases harmless from and against any and all damages, liabilities, costs, losses, and expenses (including legal costs and attorneys’ fees) arising out of or connected with any claim, demand, or action which is related to or the result of (i) any act, error, or omission by Participant or (ii) any breach of Participant’s representations, warranties, or covenants made by Artist in this Release.
7. The Participant understands that photographs, images, video, audio, and other recordings (the “Recordings”) may be taken of the Participant during the Challenge and immediately prior to and after the Challenge. The Participant hereby grants to GGC the right to use the Participant’s name, voice, and likeness in connection GGC’s challenges and other services, including, without limitation, for promotion, marketing, and advertising purposes. As between the Participant and GGC, all right, title, and interest in any and all Recordings shall be owned solely and exclusively by GGC, and the Participant shall not be entitled to any rights therein.
8. The Challenge clues, content, and other materials and information that the Participant may receive in connection with the Challenge (the “Challenge Materials”) shall at all times remain the sole and exclusive property of GGC, and GGC shall own all right, title, and interest therein. Except as expressly authorized by GGC as part of the Challenge, the Participant shall not copy, reproduce, reuse, sell, distribute, creative derivative works of, perform, or otherwise use the Challenge Materials.
9. This Release shall be governed by and construed under the laws of the State of New York, without regard to any conflict of laws principles. The federal and state courts in the State of New York, County of New York shall have exclusive jurisdiction over all disputes between the parties hereto pertaining to this Release. This Release shall be deemed to be jointly drafted, and to the extent this Release is determined to contain any ambiguities, the ambiguities shall not be construed against either party hereto. If any portion of this Release is held by any court or other tribunal of competent jurisdiction to be illegal, invalid, void or unenforceable in such jurisdiction, the remainder of the Release will not be affected and will be given full effect, without regard to such illegal, invalid, void or unenforceable portion. This Release may be executed in counterparts with signatures via fax, email, or other electronic means or copy, each of which shall be considered an original, but all of which shall together constitute the same instrument.
`;

export default termsAndConditionsMarkdown;
