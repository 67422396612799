import React, {useContext} from "react";
import PropTypes from "prop-types";
import {Link, TimeSince} from "../../../components/components";
import classNames from "classnames";
import "./leaderboard.scss";
import Team from "../../../entities/team";
import PauseContext from "../../../context/pause-context";
import TeamAdjustmentField from "./info/TeamAdjustmentField";
import Game from "../../../entities/game";

const LeaderboardRow = ({game, leaderboardDataForTeam, isIdle, onNameClicked, rank}) => {
  const {pauseFlashOn} = useContext(PauseContext)

  const {
    team,
    name,
    clueUI,
    lastAdvancedTimestamp,
    awaitingFinish
  } = leaderboardDataForTeam;

  const c = classNames({
    "text-muted": isIdle && !awaitingFinish,
    "text-danger": awaitingFinish,
    "leaderboard--is-paused": pauseFlashOn && team.isPaused,
    "o-67": isIdle && awaitingFinish, // is idle and awaiting finish
  });

  return <tr key={team.id} className={c}>
    <td className="leaderboard__rank" scope="col">{rank}.</td>
    <td className="leaderboard__name" scope="col"><Link onClick={() => onNameClicked(team)}>{name}</Link></td>
    <td className="leaderboard__clue" scope="col">{clueUI}</td>
    <td className="leaderboard__penalty" scope="col">
      <TeamAdjustmentField game={game} team={team} />
    </td>
    <td className="leaderboard__advanced" scope="col"><TimeSince timestamp={lastAdvancedTimestamp}/></td>
  </tr>;
};

LeaderboardRow.propTypes = {
  game: PropTypes.instanceOf(Game),
  leaderboardDataForTeam: PropTypes.objectOf(PropTypes.any),
  isIdle: PropTypes.bool,
  isPaused: PropTypes.bool,
  onNameClicked: PropTypes.func.isRequired,
  rank: PropTypes.number,
  team: PropTypes.instanceOf(Team),
};

export default LeaderboardRow;
