import PropTypes from "prop-types";
import React from "react";
import { Link } from "../components";

const TermsAndConditionsLabel = ({content = {}, onViewTermsAndConditions}) => {

  const handleLink = () => {
    if (onViewTermsAndConditions) {
      onViewTermsAndConditions();
    }
  }

  return <div>
    {content.prefix}
    <Link onClick={handleLink}>{content.linkText}</Link>
    {content.suffix}
  </div>
};

TermsAndConditionsLabel.propTypes = {
  content: PropTypes.shape({
    prefix: PropTypes.string,
    linkText: PropTypes.string,
    suffix: PropTypes.string,
  }),
  onViewTermsAndConditions: PropTypes.func.isRequired,
};

export default TermsAndConditionsLabel;