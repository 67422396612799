import React, {useState} from "react";
import propTypes from "prop-types";
import appContent from "../../../../markdown/app-content";
import {Button} from "react-bootstrap";
import Clue from "../../../../entities/clue";
import {InputModal} from "../../../../components/components";

const RenameClueButton = ({children, clue, game, onRename, ...rest}) => {
  const [showRename, setShowRename] = useState(false);

  const handleClueRename = async (inputs) => {
    const newName = inputs[appContent.cluemaster.game.clueTab.renamePopup.label];

    onRename(new Clue(clue.id, {
      ...clue._rawClueData,
      name: newName,
    }));

    await setShowRename(false);
  };

  if (!clue) {
    return null;
  } else {
    return <>
      <Button onClick={() => setShowRename(true)} {...rest}>
        {children}
      </Button>
      <InputModal
        show={showRename}
        onHide={() => setShowRename(false)}
        onFinish={handleClueRename}
        title={appContent.cluemaster.game.clueTab.renamePopup.title}
        inputs={[
          {
            label: appContent.cluemaster.game.clueTab.renamePopup.label,
            formControlProps: {
              placeholder: appContent.cluemaster.game.clueTab.renamePopup.placeholder,
              type: "text",
              required: true,
            },
            defaultValue: clue?.name,
          },
        ]}
        buttonText={appContent.cluemaster.game.clueTab.renamePopup.buttonText}
      />
    </>;
  }
};

RenameClueButton.propTypes = {
  clue: propTypes.instanceOf(Clue),
  onRename: propTypes.func.isRequired,
};

export default RenameClueButton;
