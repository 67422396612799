import {useEffect, useState} from "react";
import {getRecentAnswers} from "../../../services/firestore/all-answers";

const useRecentAnswers = ({filters, game}) => {
  const {clueId, teamId} = filters;
  const [recentAnswers, setRecentAnswers] = useState([]);

  useEffect(() => {
    const subscription = getRecentAnswers(game.id, {clueId, teamId}).subscribe((data) => {
      setRecentAnswers(data)
    });

    return () => subscription.unsubscribe();
  }, [clueId, teamId]);

  return recentAnswers;
};

export default useRecentAnswers;
