import { allLowerCaseAndNoWhitespace } from "../utilities/helper-functions";

class KnownWrongAnswer {
  constructor(pipedAnswerContentString = "") {
    const [answer, content] = pipedAnswerContentString.split("|");
    this.answer = answer;
    this.content = content;
  }

  checkAnswer(answer) {
    return KnownWrongAnswer.compareAnswers(answer, this.answer);
  }

  toString() {
    return KnownWrongAnswer.pipedKnownWrongAnswer(this.answer, this.content);
  }
}

KnownWrongAnswer.compareAnswers = (answerA, answerB) => {
  return allLowerCaseAndNoWhitespace(answerA) === allLowerCaseAndNoWhitespace(answerB)
}

KnownWrongAnswer.pipedKnownWrongAnswer = (answer, content) => {
  return `${answer}|${content}`
}

export default KnownWrongAnswer;
