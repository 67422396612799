import React from "react";
import PropTypes from "prop-types";
import {PageLayout, LoadingSpinner, Logo, Markdown} from "../../components";
import useGameLogo from "../../../hooks/useGameLogo";

const MarkdownPageLayout = ({markdown, logo, usePrimaryGame, footer, isLoading = false, children, hideChildrenWhenLoading = false}) => {
  const {bigLogo, smallLogo} = useGameLogo({useGameOnHomePage: usePrimaryGame});
  return <PageLayout footer={footer}>
    <LoadingSpinner isLoading={isLoading} hideChildrenWhenLoading={hideChildrenWhenLoading}>
      {logo === "sm" && <Logo className="mb-5" src={smallLogo} style={{width: "100%"}}/>}
      {logo === "lg" && <Logo className="mb-5" src={bigLogo} style={{width: "100%"}}/>}
      <Markdown className="mb-4">
        {markdown}
      </Markdown>
      {children}
    </LoadingSpinner>
  </PageLayout>;
};

MarkdownPageLayout.propTypes = {
  markdown: PropTypes.string.isRequired,
  logo: PropTypes.oneOf(["sm", "lg", "none"]).isRequired,
  usePrimaryGame: PropTypes.bool,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  hideChildrenWhenLoading: PropTypes.bool,
  footer: PropTypes.node,
};

export default MarkdownPageLayout;
